import React, {Fragment} from 'react';
import LazyLoad from "react-lazy-load";
import ImageLoader from "../../components/ImageLoader/ImageLoader";
import logo from "../../assets/img/logo-inicio-blanco.png";
import {Accordion, Card, Button} from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import "./Faq.css"

const Faq = () => {
    return (
        <Fragment>
            <div className="container">
                <div className="space-20"></div>
                <div className="row">
                    <div className="col-12">
                        <div className="custom-card custom-card-blue">
                            <div className="row">
                                <div className="col-6 col-md-8 text-left">
                                    <p>VIVE FELIZ CON TU CARRO LIMPIO</p>
                                    <p>NO ESPERAS</p>
                                    <p>NO TRANCONES</p>
                                    <p>+ TIEMPO PARA TI</p>
                                </div>
                                <div className="col-6 col-md-4 text-right">
                                    <LazyLoad
                                        offsetVertical={300}
                                        debounce={false}
                                    >
                                        <ImageLoader src={logo} className="full-height-image logo-home" alt="logo" />
                                    </LazyLoad>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3 mb-3">
                    <div className="col-12 text-center">
                        <h2>Preguntas Frecuentes</h2>
                    </div>
                    <div className="col-12">
                        <h4>¿Cómo solicito mi servicio?</h4>
                        <p>
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                            Nuevos Usuarios
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            1. Ingresa a: <a href="https://lavelopues.com/servicios">www.lavelopues.com/servicios</a><br/>
                                            1.1 Seleccionar Ubicación o Autorizar ubicación por Geolocalizacion Automatica <br/>
                                            1.2 Seleccionar tipo de vehiculo <br/>
                                            1.3 Seleccionar Lavado <br/>
                                            1.4 Ingresar Direccion <br/>
                                            1.5 Seleccionar Fecha y hora disponible <br/>
                                            1.6 Seleccionar forma de pago <br/>
                                            1.7 Ingresar numero Telefonico <br/>
                                            1.8 Revisar y aceptar Terminos y condiciones <br/>
                                            1.9 Registrar Codigo enviado por Mensaje de texto <br/>
                                            1.10 Ingresar Datos personales <br/>
                                            1.11 Reserva <br/>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                            Usuarios nuevos en app moviles
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body>
                                            2. Descargar Aplicación App Store o Google Play <br/>
                                            2.1 Ingresar correo de registro <br/>
                                            2.2 Revisar y aceptar Terminos y condiciones <br/>
                                            2.3 Ingresar ubicación o autorizar Geolocalizacion Automatica <br/>
                                            2.4 Seleccionar tipo de vehiculo <br/>
                                            2.5 Seleccionar Lavado <br/>
                                            2.6 Ingresar Direccion <br/>
                                            2.7 Seleccionar Fecha y hora disponible <br/>
                                            2.8 Seleccionar forma de pago <br/>
                                            2.9 Ingresar numero Telefonico <br/>
                                            2.10Ingresar Datos personales <br/>
                                            2.11 Reserva <br/>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                            Ingreso para usuarios registrados en la web
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            1. <a href="https://www.lavelopues.com/servicios">www.lavelopues.com/servicios</a> <br/>
                                            1.1 Seleccionar la opcion Ya soy usuario! <br/>
                                            1.2 Ingresar numero Teléfonico <br/>
                                            1.3 Registrar Codigo enviado por Mensaje de texto <br/>
                                            1.4 Confirmar datos formulario <br/>
                                            1.5 Seleccionar tipo de Servicio <br/>
                                            1.6 Seleccionar Fecha y hora disponible <br/>
                                            1.7 Seleccionar forma de pago <br/>
                                            1.8 Reservar <br/>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                            Ingreso para usuarios registrados en app movil
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body>
                                            2. Apertura de Aplicación iOS o Android <br/>
                                            2.1 Ingreso de Correo y clave registrada <br/>
                                            2.2 Confirmar datos formulario <br/>
                                            2.3 Seleccionar tipo de Servicio <br/>
                                            2.4 Seleccionar forma de pago <br/>
                                            2.5 Reservar <br/>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </p>
                        <br/>
                        <h4>¿Con cuanto tiempo debo de pedir mi servicio?</h4>
                        <p>Nuestra disponibilidad la puedes encontrar en tiempo real en la sección de fecha y hora. Nuestra
                            sugerencia es que lo puedas solicitar con un día de anticipación.</p>
                        <br/>
                        <h4>¿Cuales son los medios de pago?</h4>
                        <p>Tenemos una amplia variedad de formas de pago:<br/>
                            Efectivo , Tarjeta debito y crédito y bonos sodexo</p>
                        <br/>
                        <h4>¿Cómo puedo cancelar mi servicio?</h4>
                        <p>Una vez cuentas con un servicio activo puedes ingresar en la sección de Lavados Disponibles, allí
                            podrás encontrar información sobre tu servicio activo. En la inferior de esta información
                            encontraras la opción “Cancelar Servicio”</p>
                        <br/>
                        <h4>¿Tengo algún tipo de recargo si cancelo mi servicio sobre el tiempo?</h4>
                        <p>Si. Dado el caso que tu cancelación del servicio sea inferior a 1 hora y nuestro washer se encuentre
                            en camino se realizara un cobro de 5.000 COP por el desplazamiento de la persona.</p>
                        <br/>
                        <h4>¿Cómo puedo registrar una queja o reclamo?</h4>
                        <p>Dentro de nuestra plataforma una vez se haya finalizado tu servicio, se abrirá una opción para
                            calificar el servicio prestado. Si tu calificación no es positiva uno de nuestros asesores
                            especializados se comunicara contigo para recopilar la información necesaria. Nuestra Objetivo es
                            que quedes satisfecho!</p>
                        <br/>
                        <h4>¿Compré un plan y no encuentro como redimir los servicios siguientes?</h4>
                        <p>En nuestras plataformas en el menú principal encontraras la opción de “Lavados Disponibles”,
                            esta opción te permite usar los lavados que tengas pre pagados. Si no te cargan tus lavados
                            disponibles, en nuestra pagina web <a href="https://www.lavelopues.com/servicios">www.lavelopues.com/servicios</a>{' '}
                            podrás encontrar un chat enlínea donde te pediremos información de lo sucedido y uno de nuestros
                            asesores te ayudara.</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </Fragment>
    )
}

export default Faq
