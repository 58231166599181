import React, {Fragment, useState} from "react";
import Modal from "react-bootstrap/Modal";
import oneImg from "../../assets/img/one.svg";
import twoImg from "../../assets/img/two.svg";
import threeImg from "../../assets/img/three.svg";
import fourImg from "../../assets/img/four.svg";
import "./ModalAmountServices.css";

const ModalAmountServices = ({show,handleClose,max}) => {
    const [amount, setAmount] = useState(1);
    return(
        <Modal show={show} onHide={() => handleClose(amount)}>
            <Modal.Header closeButton>
                <Modal.Title>Selecciona una cantidad</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        ¿Cuántos servicios deseas solicitar en tu primer reserva? <br/>
                        Selecciona una cantidad (1 - {max})
                    </div>
                </div>
                <div className="row">
                    <div className={"col-3 " + (max === 2  ? "offset-2" : "")}>
                        <img
                            src={oneImg}
                            alt="img"
                            className={(amount === 1 ? 'selected':'no-selected') + ' img-amount-services'}
                            onClick={() => setAmount(1)}
                        />
                    </div>
                    <div className={"col-3 " + (max === 2  ? "offset-2" : "")}>
                        <img
                            src={twoImg}
                            alt="img"
                            className={(amount === 2 ? 'selected':'no-selected') + ' img-amount-services'}
                            onClick={() => setAmount(2)}
                        />
                    </div>
                    {
                        max === 4 ?
                            <Fragment>
                                <div className="col-3">
                                    <img
                                        src={threeImg}
                                        alt="img"
                                        className={(amount === 3 ? 'selected':'no-selected') + ' img-amount-services'}
                                        onClick={() => setAmount(3)}
                                    />
                                </div>
                                <div className="col-3">
                                    <img
                                        src={fourImg}
                                        alt="img"
                                        className={(amount === 4 ? 'selected':'no-selected') + ' img-amount-services'}
                                        onClick={() => setAmount(4)}
                                    />
                                </div>
                            </Fragment>
                            : null
                    }

                </div>
                <div className="row mt-2">
                    <div className="col-12 text-center">
                        <button className="btn btn-primary" onClick={() => handleClose(amount)}>Aceptar</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalAmountServices;
