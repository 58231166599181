import React, {useState, useEffect} from 'react';
import MenuApp from '../../components/MenuApp/MenuApp'
import FormLogin from '../../components/FormLogin/FormLogin';
import { useSelector,useDispatch } from 'react-redux';
import { setUserActions } from '../../actions/userActions';
import { setReservationActions } from '../../actions/reservationActions';
import clientAxios from '../../config/axios';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Swal from 'sweetalert2';
import SelectCity from "../../components/SelectCity/SelectCity";
import MenuAppMobile from "../../components/MenuAppMobile/MenuAppMobile";
import Footer from "../../components/Footer/Footer";
import {useHistory} from "react-router-dom";
import ModalChangeMethodPayment from "../../components/ModalChangeMethodPayment/ModalChangeMethodPayment";

function ActiveReservations() {

    let history = useHistory();
    const [isLogged, setIsLogged] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showModalChangeMethodPayment, setShowModalChangeMethodPayment] = useState(false);
    const [currentReservation, setCurrentReservation] = useState(null);
    const [dataReservation, setDataReservation] = useState([]);
    const [mostrarReintentar,setMostrarReintentar ] =  useState(false);
    const userLogin = useSelector( state => state.user);
    const reservation = useSelector( state => state.reservation.reservation);
    const dispatch = useDispatch();
    const setUser = user => dispatch( setUserActions(user));
    const setReservation = res => dispatch( setReservationActions(res));

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 2,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        },
    };

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-primary ml-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })
    const alertCancelReservation  = reservation => {
        swalWithBootstrapButtons.fire({
            title: 'Cancelar Servicio',
            text: '¿Deseas cancelar el servicio?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Volver',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                const send = {
                    idUsuario: userLogin.idUser,
                    token: userLogin.token,
                    idservicio: reservation.idservicio,
                    idestadoservicio: 4
                }
                clientAxios.post('app/CambiarEstadoServicio',send).then(({data}) => {
                    if (data.response === 1) {
                        let text= '';
                        if (reservation.idformapago == 2 && reservation.pagado == 1) {
                            text= 'Acabas de cancelar un servicio que ya tienes pago. Comunícate a nuestra línea <a href="tel:+573113103519">3113103519</a> para mayor info';
                        } else {
                            text = 'Se ha cancelado correctamente el servicio';
                        }
                        swalWithBootstrapButtons.fire(
                            'Operación Exitosa',
                            text,
                            'success'
                        )
                        loadActiveReservations();
                    }
                    
                }).catch(() => {
                    swalWithBootstrapButtons.fire(
                        'Se ha generado un error',
                        'Se ha generado un error al intentar cancelar el servicio',
                        'error'
                    )
                });
                
            }
        })
    }
    const handleCloseMethodPayment = res => {
        setCurrentReservation(null);
        setShowModalChangeMethodPayment(false);
        loadActiveReservations();
    }
    const goHome = () => {
        setReservation({
            ...reservation,
            openMenuMobile: true,
        })
        history.push('/servicios')
    }
    const handleShow = () =>{
        setShowLogin(true);
    }

    const handleClose = value => {
        setIsLogged(value);
        setShowLogin(false);
    }

    const changePayment = reservation => {
        setCurrentReservation(reservation);
        setShowModalChangeMethodPayment(true);
        loadActiveReservations();
    }

    const loadActiveReservations = () => {
        if (userLogin.phone) {
            const send = {
                ...userLogin,
                idUsuario: userLogin.idUser,
                record: false,
                qualification: false
            }
            clientAxios.post('app/TodosMisServicios',send).then(({data})=> {
                if (data.response != 2) {
                    setDataReservation(data);
               } else {
                    setDataReservation([]);
               }
            }).catch(err => console.log('Se ha generado un error al cargar las reservas activas'));
        }
    }

    const paymentStatus = ( id, text ) => {
        let textReturn = 'indefinido'
        if ( id == 1) {
          textReturn = 'Pendiente de pago'
        } else if ( id == 2 || id == 3 ) {
          switch (text) {
            case 'APPROVED':
              textReturn = 'Aprobado'
                break;
            case 'DECLINED':
              textReturn = 'Rechazado'
                break;
            case 'NO_PAYED':
              textReturn = 'No pagado'
                break;
            case 'PENDING':
              textReturn = 'Pendiente'
                break;
            default: 
                textReturn= '';
                break;
          }
        } else if ( id == 4 ) {
          textReturn = 'Pagado'
        }
        return textReturn
    }

    useEffect(() => {
        const flg = reservation.country != 3;
        setMostrarReintentar(flg);
        console.log({country :reservation.country != 3,countryid:reservation.country});
        if (userLogin.idUser) {
            if (!userLogin.phone) {
                clientAxios.post('app/validarUsuarioToken',userLogin).then(res => {
                    if (res.data.response) {
                        setUser(res.data);
                        setIsLogged(true);
                        loadActiveReservations();
                    } else {
                        setUser(null);
                    }
                }).catch(err => console.log('error -> ',err));
            } else {
                setIsLogged(true);
                loadActiveReservations();
            }
        }        
    },[isLogged]);
    return (
        <div>
            <MenuAppMobile/>
            <div className="space-20"></div>
            <div className="container col-12">
                {/* <div className="row">
                    <div className="col-12">
                        <SelectCity/>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-md-3">
                        <div className="menuSticky" >
                            <MenuApp />
                        </div>
                    </div>
                    <div className="col-md-9 col-sm-12">
                        <div className="row">
                            { isLogged ? 
                            <div className="col-12">
                                <div className="full-width text-center">
                                    <h2>Reservas Activas</h2>
                                    {dataReservation.length === 0 ?
                                        <h4 className="mt-3">En el momento no tienes servicios activos, ve y <a>Reserva un Servicio</a></h4>
                                    : null} 
                                </div>
                                <Carousel responsive={responsive}>
                                    {dataReservation.map( (reservation, index) => (
                                        <div className="card border-secondary margin-10" key={index}>
                                            <div className="card-body">
                                                <div className="full-width text-center">
                                                    <h4>{reservation.servicio.nombre}</h4>
                                                </div>
                                                <p>{reservation.formapago + ' - ' +reservation.precio}</p>
                                                <p>Fecha: {reservation.fecha}</p>
                                                <p>Encargado: {reservation.conductor}</p>
                                                <p>Telefono encargado : <a href={'tel:' + reservation.telefonoconductor}>{reservation.telefonoconductor}</a></p>
                                                <p>Dirección: {reservation.ciudad + ', '+reservation.direccion}</p>
                                                <p>Estado del servicio:
                                                    <span className={reservation.idestadoservicio == 1 ?'text-green': 'text-yellow'}>
                                                        {' '+reservation.estadoservicio}
                                                    </span> 
                                                </p>
                                                <p>Estado del pago: {paymentStatus(reservation.idformapago, reservation.estado_transaccion)}</p>
                                    
                                                <div className="row">
                                                    {!(reservation.idformapago == 3 && (reservation.pagado == 1 || reservation.estado_transaccion == 'PENDING')) ?
                                                        <div className="col-6 text-center">
                                                            <button className="btn btn-outline-danger" onClick={() => alertCancelReservation(reservation)}>Cancelar</button>
                                                        </div> : ''
                                                    }
                                                    {
                                                    mostrarReintentar?
                                                        (reservation.idformapago == 3 && reservation.pagado == 0 && reservation.estado_transaccion != 'PENDING') || reservation.idformapago == 1 ?
                                                            <div className="col-6 text-center">
                                                                <button className="btn btn-outline-info" onClick={() => changePayment(reservation)}>Reintentar Pago</button>
                                                            </div> : ''
                                                    :''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Carousel>
                            </div>
                            : 
                            <div className="col-12">
                                <div className="space-20"></div>
                                <div className="card text-white bg-info mt-3 c-pointer" onClick={handleShow}>
                                    <div className="card-body text-center">
                                        <h4>Si deseas ver tus reservas activas debes iniciar sesión dando clic Aquí
                                        </h4>
                                    </div>
                                </div>
                                <FormLogin  show={showLogin} handleClose={handleClose}/>
                            </div>
                            }
                        </div>
                        
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center mb-3 mt-2">
                        <button className="btn btn-primary invisible-on-desktop" onClick={() => goHome()}>Ir al Inicio</button>
                    </div>
                </div>
            </div>
            {
                showModalChangeMethodPayment && currentReservation ?
                    <ModalChangeMethodPayment
                        show={showModalChangeMethodPayment}
                        handleClose={handleCloseMethodPayment}
                        reservationInput={{...currentReservation}}
                        productInput={currentReservation.product}
                        userLogin={userLogin}
                    /> : null
            }
            <Footer />
        </div>
    );
}

export default ActiveReservations;
