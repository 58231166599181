import React from "react";
import './FullLoading.css';
import imgLoading from '../../assets/img/loading.gif';

const FullLoading = () => {
    return(
        <div className="overlay">
            <div>
                <div className="div-content-loading">
                    <img src={imgLoading} alt="loaging" />
                    <h3>CARGANDO...</h3>
                </div>
            </div>
        </div>
    );
};

export default FullLoading;
