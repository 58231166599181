import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {formatMinutesToHours} from '../../Helpers/HoursHelper';
import {formatPrice} from '../../Helpers/PriceHelper';

const DetailProduct = ({data, baseUrlImg,selectProduct,show,handleClose}) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Detalle Lavado</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <div className="row m-1">
                        <div className="col-12">
                            <img className="img-product" src={baseUrlImg + data.imagen} alt={data.nombre} />
                        </div>
                        <div className="col-12 text-center mt-2">
                            <h4 className="color-primary">{data.nombre}</h4>
                        </div>
                        <div className="col-12">
                            <p>{data.descripcion}</p>
                        </div>
                        <div className="col-6">
                            Duración: {formatMinutesToHours(data.duracion) } 
                        </div>
                        <div className="col-6 text-right">
                            Precio: {formatPrice(data.precio)}
                        </div>
                        <div className="col-12 mt-3">
                            <button className="btn btn-primary full-width" onClick={() => selectProduct(data)}>Seleccionar</button>
                        </div>
                    </div>
        
                </Modal.Body>
        </Modal>
    );
}

export default DetailProduct;