export const isEmpty = (value, args) => {
    let empty = false;
    if (args) {
        for(let i = 0; i < args.length; i++) {
            if (value[args[i]] === '') {
                empty = true;
                break;
            }
        }
    } else if (value === '') {
        empty = true;
    }
    return empty;
}

export const isCorrectEmail = email => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)

export const isCorrectAlphabetical = string => /^[A-Za-záéíóúüÁÉÍÓÚÜñÑ ]+$/.test(string)

export const inCorrectNumber = n =>  /^[0-9]+$/.test(n)

export const isCorrectNumberWithSpace = n => /^[0-9 ]+$/.test(n)

export const isCorrectID = id => /^[0-9]+$/.test(id)

export const isNoSpacesInObj = object => {
   
    let flg = true;
    for (const property in object) {
      const cadena = object[property];
      flg = isNotEmpty(cadena);
      if (!flg) {
          break;
      }
    }
   return flg;
}

export const isNoSpaces = cadena => {
   
    let flg = true; 
    if (cadena) {
        cadena = cadena+"".trim();
        if (cadena.length > 0) {
            for(let i=0;i<cadena.length;i++){
                if(cadena[i] == " "){
                    flg = false;
                    break;
                }
            } 
        }else{
            flg= false;
        }
    }else{
        flg = false;
    }
   return flg;
}

export const isNotEmpty = cadena => {
    let flg = true; 
    if (cadena) {
        cadena = cadena+"".trim();
        if(cadena  == " "){
            flg = false;
        }
    }else{
        flg = false;
    }
   return flg;
}