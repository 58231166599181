import React, {useState, useEffect} from 'react';
import MenuApp from '../../components/MenuApp/MenuApp'
import ItemHome from '../../components/ItemHome/ItemHome';
import {useHistory } from 'react-router-dom';
import Swal from 'sweetalert2/src/sweetalert2.js';
import ModalDate from '../../components/ModalDate/ModalDate';
import ModalPlaUser from '../../components/ModalPlanUser/ModalPlanUser';
import { useDispatch, useSelector } from 'react-redux';
import { setReservationActions } from '../../actions/reservationActions';
import clientAxios from "../../config/axios";
import {setUserActions} from "../../actions/userActions";
import SelectCity from "../../components/SelectCity/SelectCity";
import MenuAppMobile from "../../components/MenuAppMobile/MenuAppMobile";
import { setAddressAction } from '../../actions/addressActions';

function WashesAvailable() {
    let history = useHistory();
    let products = [];
    const [showPlans,setShowPlans]  = useState('1');
    const [plan,setPlan] = useState('1');
    const [plans,setPlans] = useState([]);
    const [isLogged, setIsLogged] = useState(false);
    const [count, setCount] = useState(0);
    const reservation = useSelector( state => state.reservation.reservation );
    const setReservation = res => dispatch( setReservationActions(res));
    //const [reservation, setReservation] = useState(null);
    const [showModalDate, setShowModalDate] = useState(false);
    const dispatch = useDispatch();
    const userLogin = useSelector( state => state.user);
    const reservationStore = useSelector( state => state.reservation.reservation );
    const setUser = user => dispatch( setUserActions(user));
    const setReservationStore = res => dispatch( setReservationActions(res));
    const addressStore = useSelector( state => state.address)
    const setAddressStore = res => dispatch( setAddressAction(res));
    const handleCloseDate = data => {
        setShowModalDate(false)
    }
    const selectHour  = (date, dateTemp) => {
        setReservation({
            ...reservation,
            date,
            dateTemp
        })
        setShowModalDate(false);
    }
    const goVehicles = () => {
        history.push('/vehiculos');
        setReservationStore({...reservationStore,goWashesAvailable: true});
    }
    const goAddresses = () => {
        history.push('/direcciones');
        setReservationStore({...reservationStore,goWashesAvailable: true});
    }
    const goHome = () => {
        setReservationStore({
            ...reservationStore,
            openMenuMobile: true,
        })
        history.push('/servicios')
    }
    const showDataVehicle = () => {
        let body = '';
        let name = '';
        if (reservation) {
            if (reservation.typeVehicle &&
                reservation.plaque !== 'V1' &&
                reservation.plaque !== 'V2') {
                if (reservation.country == 1) {
                    name = reservation.typeVehicle ==='1' ? 'Automóvil (carro)':'Camioneta';
                } else {
                    name = reservation.typeVehicle ==='1' ? 'Pequeño':'Grande';
                }
                body = reservation.plaque ? ' : ' + reservation.plaque : '';
            } else {
                if (reservation.country && reservation.country != 1) {
                    if ( reservation.country == 3) {
                        name = reservation.plaque ==='V1' ? 'Automóvil (carro)':'JEEPETA';
                    }else{
                        name = reservation.plaque ==='V1' ? 'Pequeño':'Grande';
                    }
                } else {
                    name = reservation.plaque ==='V1' ? 'Automóvil (carro)':'Camioneta';
                }
            }
        }

        return name + ' ' + body;
    }
    const showDataAddress = () => {
        let text = 'Selecciona una dirección';
        if (userLogin.phone && addressStore.idAddress !== 0) {
            text = `(${addressStore.nameAddress}) ${addressStore.address}`;
        } else if (addressStore.addressTemp) {
            let temp = addressStore.addressTemp;
            text = `(${temp.name}) ${temp.address1} ${temp.address2} ${(reservation.country == 2 ? ' y ' : ' # ')} 
                ${temp.address3} ${(reservation.country == 2 ? '' : ' - ')} ${temp.address4}`;
        }
        return text;
    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-primary ml-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })
    const clicDate = () => {
        if (!reservation.product.id) {
            swalWithBootstrapButtons.fire(
                'Advertencia',
                'Debes seleccionar un producto antes de seleccionar la fecha.',
                'warning'
            )
            return
        }
        setShowModalDate(true);
    }
    const traerPlanes = ()=>{
        const send = {
           idusuario:userLogin.idUser
        }
        clientAxios.post('shared/TodosPlanesUsuario', send).then(({data}) => {
            setPlans(data);
            console.log({data});
        })
    }
    const loadInitial = dataInput => {
        setShowPlans(false);
        traerPlanes();
        if (!dataInput) {
            dataInput = reservation;
        }
        const send = {
            typeProduct: 1,
            vehicle: dataInput.typeVehicle,
            city: dataInput.city
        }
        const consulta  = reservation.city == 2? 'app/TodosProductoCopia':'app/TodosProducto';
        clientAxios.post(consulta, send).then(({data}) => {
            products = data.products;
            selectVehicle(dataInput);
        }).catch(err => console.log('error al cargar producto -> ',err));
    }
    const selectVehicle = (dataInput) => {
        if (isLogged) {
            let data;
            if (!reservation) {
                data = dataInput;
            } else {
                data = {
                    ...reservation,
                    city: dataInput.city
                };
            }
            const send = {
                idUser: userLogin.idUser,
                token: userLogin.token,
                idvehiculo: data.typeVehicle,
                city: data.city
            }
            clientAxios.post('shared/TraerLavadosDisponibles', send).then(({data}) => {
                if (data.response === '2') {
                    console.log('Lavados disponibles: 0');
                    setCount(0);
                } else {
                    setCount(data.contador);
                    const product = products.find(item => {
                        return item.idproducto === data.idproducto
                    })
                    setReservation({
                        ...reservation,
                        product: {
                            id: product.idproducto,
                            duration: product.duracion,
                            name: product.nombre,
                            points: product.puntos,
                            price: product.precio,
                            idWashAvailable: data.idservicio_disponible
                        }
                    });
                }
            }).catch(err => console.log('Error al traear lavados disponibles', err));
        }
    }
    const confirmReservation = () => {
        if (!reservation.plaque) {
            swalWithBootstrapButtons.fire('Advertencia', 'No se ha seleccionado ningun vehiculo', 'warning')
        } else if (count === 0) {
            swalWithBootstrapButtons.fire('Advertencia', 'No cuenta con lavados disponibles, para el vehiculo seleccionado','warning')
        } else if (!reservation.date) {
            swalWithBootstrapButtons.fire('Advertencia', 'No se ha seleccionado una fecha','warning')
        } else if (!reservation.address) {
            swalWithBootstrapButtons.fire('Advertencia', 'No se ha seleccionado una dirección','warning')
        } else {
            swalWithBootstrapButtons.fire({
                title: '¿Desea confirmar la reserva?',
                text: '¿Su reserva esta lista desea confirmarla?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Confirmar!',
                cancelButtonText: 'No, Cancelar!',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    const send = {
                        ...userLogin,
                        ...reservation,
                        idservicio_disponible: reservation.product.idWashAvailable,
                        web: true
                    }
                    clientAxios.post('shared/CrearPedido',send).then(({data}) => {
                        if (data.response == 1) {
                            const message = '<p>Se confirmó el servicio, la persona que le prestara el servicio es :<b>'
                                + data.conductor + '</b></p>' + '<p>Teléfono : <b>' + data.telefonos + '</b></p>'
                            swalWithBootstrapButtons.fire('Confirmación del servicio', message, 'success').then((result) => {
                                history.push('/reservasActivas');
                            })
                        } else if (data.response == 2) {
                            swalWithBootstrapButtons.fire('Ha ocurrido un error', 'No fue posible agendar el servicio, conéctese a internet o intente mas tarde', 'warning');
                        } else if (data.response == 3) {
                            swalWithBootstrapButtons.fire('Advertencia','En este momento no tenemos personal disponible para la fecha y hora seleccionada, por favor vuelva a seleccionar, Gracias!', 'warning');
                        } else {
                            swalWithBootstrapButtons.fire('Ha ocurrido un error', 'No fue posible agendar el servicio, conéctese a internet o intente mas tarde', 'warning');
                        }
                    }).catch(err => console.log('error al crear la reserva',err));
                }
            })

        }
    }
    const cancelarPlan = ()=>{
        setShowPlans(true);
    }
    const cancelarMenbresia =()=>{
        const lavadosDisponibles = {
            idUser: userLogin.idUser,
            token: userLogin.token,
            idvehiculo: reservation.typeVehicle,
            city: reservation.city
        }
        clientAxios.post('shared/TraerLavadosDisponibles', lavadosDisponibles).then(({data}) => {
            const cancelar = {
                ...userLogin,
                idplan:plan
            };
            if (data.response === '2') {
                console.log('Lavados disponibles: 0');
                setShowPlans(false);
            } else {
               const  {idservicio_disponible} = data;
               
               cancelar.idservicio_disponible = idservicio_disponible;
               
            }
            clientAxios.post('shared/EpaycoCancelarSuscripcion', cancelar).then((data)=>{
                console.log({cancelar:data});
                setShowPlans(false);
            });
        }).catch(err => console.log('Error al traear lavados disponibles', err));
    }
    useEffect(() => {

        if (userLogin.idUser) {
            if (!userLogin.phone) {
                clientAxios.post('app/validarUsuarioToken',userLogin).then(res => {
                    if (res.data.response) {
                        setUser(res.data);
                        setIsLogged(true);
                    } else {
                        setUser(null);
                    }
                }).catch(err => console.log('error -> ',err));
            } else {
                setIsLogged(true);
            }
        }
        const dataTemp = {
            city: reservationStore.city,
            cityName: reservationStore.cityName,
            ccTLD: 'CO',
            country: 1,
            typeVehicle: reservationStore.typeVehicle,
            plaque: reservationStore.plaque,
            nameAddress: reservationStore.nameAddress,
            address: reservationStore.address,
            idAddress: reservationStore.idAddress,
            methodPayment: 5,
            date: null,
            dateTemp: 0,
            currency: null,
            nameMethodPayment: 'Efectivo',
            product: {
                id: null,
                duration: null,
                durationTemp: null,
                name: null,
                subtotalPrice: null,
                discount: 0,
                price: null,
                points: null,
                amount: 1
            }
        }
        if (!reservation) {
            setReservation({...dataTemp});
        } else {
            setReservation({
                ...reservation,
                city: reservationStore.city
            });
        }

        setReservationStore({...reservationStore,goWashesAvailable: false});
        loadInitial(dataTemp);
        console.log({reservation});
    },[isLogged,reservationStore.city]);
    return (
        <div>
            <MenuAppMobile/>
            <div className="space-20"></div>
            <div className="container col-12">
                {/* <div className="row">
                    <div className="col-12">
                        <SelectCity/>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-md-3">
                        <div className="menuSticky" >
                            <MenuApp />
                        </div>
                    </div>
                    <div className="col-md-9 col-sm-12">
                        <div className="mb-3 mt-3 text-center">
                            <h2>Lavados Disponibles</h2>
                        </div>
                        <div className="mb-3 mt-3">
                            <h4>Cantidad de lavados disponibles: {count}</h4>
                        </div>
                        <div className="mb-3">
                            <ItemHome
                                title="Vehículo"
                                content={showDataVehicle()}
                                onClick={() => goVehicles()}
                            />
                        </div>
                        <div className="mb-3">
                            <ItemHome
                                title="Dirección"
                                content={showDataAddress()}
                                onClick={() => goAddresses()}
                            />
                        </div>
                        {
                            count>0?
                        <div className="mb-3">
                            <ItemHome
                                title="Fecha y Hora"
                                content={reservation && reservation.date ?
                                    reservation.date : 'Selecciona una Fecha' }
                                onClick={() => clicDate()}
                            />
                        </div>:null
                        }
                        <div className="row mt-3 mb-3">
                            <div className="col-12">
                                <div className="space-20"></div>
                                <button className="btn btn-primary full-width" onClick={() => confirmReservation()}>
                                    CONFIRMAR RESERVA
                                </button>
                            </div>
                            {
                            plans.length >0?
                            <div className="col-12">
                                <div className="space-20"></div>
                                <button className="btn btn-primary full-width" onClick={() => cancelarPlan()}>
                                    Cancelar menbresia
                                </button>
                            </div>:null
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center mb-3 mt-2">
                        <button className="btn btn-primary invisible-on-desktop" onClick={() => goHome()}>Ir al Inicio</button>
                    </div>
                </div>
                <div className="mucho"></div> 
            </div> 
            {
                showPlans?
                <ModalPlaUser
                    plans = {plans}
                    handleClose= {cancelarMenbresia}
                />:
                null
            }
            {
                showModalDate ? 
                <ModalDate
                    showModalDate={showModalDate}
                    handleCloseDate={handleCloseDate}
                    data={reservation}
                    selectHour={selectHour}
                />
                : null 
            }
        </div>
    );
}

export default WashesAvailable;
