export const setProductAcions = product => {
    return {
        type: 'SET_PRODUCT',
        payload: product
    };
}

export const deleteProductAction = () => {
    return {
        type: 'DELETE_PRODUCT'
    };
}
