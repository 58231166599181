export const getUrlUbication = data => {
    const apiKey = 'AIzaSyDlRxUkgnOyDKT8a6A55_T0ff0-mDXGei0';
    const url = ('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + data.lat + ',' + data.long +
      '&language=es&key=' + apiKey).replace(' ', '')
    return url;
}

export const getUrlCoordinates = data => {

    // apikey de Google
    const apiKey = 'AIzaSyDlRxUkgnOyDKT8a6A55_T0ff0-mDXGei0'
    const url = ('https://maps.googleapis.com/maps/api/geocode/json?address=' + data.address1 + '+' +
      data.address2 + '+' + data.address3 + '-' + data.address4 + ',+' + data.cityName + ',+'+data.ccTLD+'&key=' + apiKey).replace(' ', '')
    return url;
}