import React, {Fragment, useState} from  'react';
import {useSelector, useDispatch} from 'react-redux';
import { deleteUserAction } from '../../actions/userActions';
import { deleteReservationAction } from '../../actions/reservationActions';
import { deleteAddressAction } from '../../actions/addressActions';
import { deleteProductAction } from '../../actions/productsActions';
import FormLogin from '../../components/FormLogin/FormLogin';
import { withRouter,Link,useLocation,useHistory } from 'react-router-dom';
import Swal from 'sweetalert2/src/sweetalert2.js'
import './MenuApp.css';


const MenuApp = withRouter(({mobile}) => {

  const [showLogin, setShowLogin] = useState(false);
  const userLogin = useSelector(state => state.user);
  const dispatch = useDispatch();
  const deleteUser = () => dispatch( deleteUserAction());
  const deleteReservation = () => dispatch(deleteReservationAction());
  const deleteProduct = () => dispatch(deleteProductAction());
  const deleteAddress = () => dispatch(deleteAddressAction());
  let location = useLocation();
  let history = useHistory();
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary ml-2',
        cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  const actuate = () => {
    if (userLogin.phone) {
      logout();
    } else {
      setShowLogin(true);
    }
  }
  const logout = () => {
    swalWithBootstrapButtons.fire({
      title: 'Cerrar Sesión',
      text: '¿Deseas cerrar tu sesión actual?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, Cerrar Sesión!',
      cancelButtonText: 'No, Cancelar!',
      reverseButtons: true
    }).then(result => {
      if (result.value) {
        deleteUser();
        deleteReservation();
        deleteProduct();
        deleteAddress();
        history.push('/servicios');
      }
    });
  }
  const handleClose = () => {
    setShowLogin(false);
  }
   const menuOpts = [
      {
        name: 'Reservas',
        icon: 'fas fa-shopping-cart',
        dataFunction: '/servicios',
      },
      {
        name: 'Tu Membresia',
        icon: 'fas fa-tint',
        dataFunction: '/lavadosDisponibles',
      },
      {
        name: 'Reservas Activas',
        icon: 'far fa-clock',
        dataFunction: '/reservasActivas',
      },
      {
        name: 'Perfil',
        icon: 'far fa-user-circle',
        dataFunction: '/perfil',
      },
      {
        name: 'Vehículos',
        icon: 'fas fa-car-alt',
        dataFunction: '/vehiculos',
      },
      {
        name: 'Direcciones',
        icon: 'fas fa-directions',
        dataFunction: '/direcciones',
      },
  ];
  
  const isActiveClass = (route) =>  {
    let tempStyle = 'item-menu-main';
    if (location.pathname === route || location.pathname === route + '/') {
      tempStyle += ' item-menu-main-active';
    };
    return tempStyle
  }
  const isIconActive = route => {
    let tempStyle = '';
    if (location.pathname === route || location.pathname === route + '/') {
      tempStyle += 'color-white';
    };
    return tempStyle
  }
  
  return  (
    <div className={(mobile ? 'menu-app-visible-mobile' : 'menu-app' )}>
      {
      userLogin.phone ?
        menuOpts.map( (x,index) => (
          <Link
              to={x.dataFunction} className="text-menu"
              key={index}
          >
              <div className={isActiveClass(x.dataFunction)}>
                  
                  <i className= { x.icon + ' icon-menu '+ isIconActive(x.dataFunction)}></i><span className="title-menu">{x.name}</span>

              </div>
          </Link>
          ))
        :
        <Fragment>
          <Link
                to='/servicios' className="text-menu"
            >
                <div className={isActiveClass('/servicios')}>
                    
                    <i className= {'fas fa-shopping-cart icon-menu '+ isIconActive('/servicios')}></i><span className="title-menu">Reservas</span>

                </div>
            </Link>
          
        </Fragment>
      }
      <div className="item-menu-main" onClick={() => actuate()}>
        <i className={(userLogin.phone ? 'fa-sign-out-alt' : 'fa-sign-in-alt') + " fas icon-menu"}></i>
        <span className="title-menu">
          {userLogin.phone ? 'Cerrar Sesión' : 'Iniciar Sesión'}
        </span>
      </div>
      <FormLogin  show={showLogin} handleClose={handleClose}/>
    </div>
  );
  
    
})

export default MenuApp;
