import Carousel from "react-bootstrap/Carousel";
import React from "react";
import { useSelector } from 'react-redux';
const CarouselClients = () => {
    const reservation = useSelector( state => state.reservation.reservation );
    const clients = [
        [
            {
                className: 'col-4',
                logo: require("../../assets/img/clients/RAPPI.png"),
            },
            {
                className: 'col-4',
                logo: require("../../assets/img/clients/Cabify.png"),
            },
            {
                className: 'col-4',
                logo: require("../../assets/img/clients/novaventa.png"),
            },
        ],
        [
            {
                className: 'col-4',
                logo: require("../../assets/img/clients/didi.png"),
            },
            {
                className: 'col-4',
                logo: require("../../assets/img/clients/Toyota.png"),
            },
            {
                className: 'col-4',
                logo: require("../../assets/img/clients/Renault.png"),
            },
        ],
        [
            {
                className: 'col-4',
                logo: require("../../assets/img/clients/Ramo.png"),
            },
            {
                className: 'col-4',
                logo: require("../../assets/img/clients/ara.png"),
            },
            {
                className: 'col-4',
                logo: require("../../assets/img/clients/casatoro.png"),
            },
        ]

    ];
    return(
        <div className="text-center">
           {reservation.country != 3?
            <div>
                <h4><b>Quienes confían en nosotros</b></h4>
                <Carousel>
                    {
                        clients.map((x,index1) => (
                            <Carousel.Item key={index1}>
                                <div className="row">
                                    {
                                        clients[index1].map((y,index2) => (
                                            <div className={y.className} key={index2}>
                                                <img src={y.logo} className="full-width-image" alt="cliente-lavelopues"/>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="space-20"></div>
                                <div className="space-20"></div>
                            </Carousel.Item>
                        ))
                    }
                </Carousel> 
            </div>:null
            }
        </div>
    )
}

export default CarouselClients
