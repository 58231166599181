import axios from 'axios';

const clientAxios = axios.create({
    //Produccion
    baseURL: 'https://lavelopues.co/lavelo-pues-backend-nuevo2',
    //Pruebas
    // baseURL: 'https://lavelopues.co/pruebas/backend',
    //Local
    //baseURL: 'http://localhost/lavelopues-backend',
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",

    }
});

export default clientAxios;
