export const formatPrice = (value, currency) => {
    if (value === null || value === 'null' || typeof value === 'undefined') {
        return `0`
    }
    value = String(value)
    let body = value;
    if (value.length > 3 && String(value).search(/\./) === -1) {
        body = value.substr(0, value.length - 3) + '.' + value.substr(-3);
    }
    return currency === 'RD$' ? ('RD$ '+body):currency === 'USD' ? ('USD '+body) : ('$ '+body);
}
