import React from 'react';

const Address = ({data,idSelected, action, selectAddress, country}) => {
    const check = 
        <label className="container-check">
            <input type="checkbox" checked={data.iddireccion === idSelected} onChange={() => selectAddress(data)}/>
            <span className="checkmark"></span>
        </label>
    return (
        <div className="card border-secondary">
            <div className="card-header text-center">
                {check}
                {data.nombre}
            </div>
            <div className="card-body">
                <div className="text-left container">
                    { data.direccion + ' ' + data.calle + ( country == 2 ? ' y ' : ' # ') + data.carrera1 + (country == 2 ? '' : ' - ') + data.carrera2 }<br/>
                    { data.observacion }
                </div>
            </div>
            <div className="card-footer">
                <div className="row">
                    <div className="col-6 text-center">
                        <button type="button" className="btn btn-outline-info" onClick={() => action(data,'edit')}>
                            <i className="fas fa-pen mr-1"></i>Editar
                        </button>
                    </div>
                    <div className="col-6 text-center">
                        <button type="button" className="btn btn-outline-danger ml-1" onClick={() => action(data, 'delete')}>
                            <i className="fas fa-trash-alt mr-1"></i>Eliminar
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Address;
