import React, {Fragment, useEffect, useState} from "react";
import "./Testimony.css";

const Testimony = ({mode,img,heightImg,text,user,isMobile}) => {
    return (
        mode === 'H'
            ?
            <div className="row">
                <div className="col-4">
                    <img src={img} height={heightImg} alt="testimonio" className="img-testimony"/>
                </div>
                <div className="col-8 text-left">
                    {
                        isMobile ?
                            <Fragment>
                                <h5><b>{user}</b></h5>
                                <p className="text-testimony-mobile">{text}</p>
                            </Fragment>
                            :
                            <Fragment>
                                <h4><b>{user}</b></h4>
                                <p>{text}</p>
                            </Fragment>

                    }
                </div>
            </div>
            :
            <div>
                <div className="col-12 text-center">
                    <img src={img} height={heightImg} alt="testimonio" className="img-testimony"/>
                </div>
                <div className="col-12 text-center">
                    {
                        isMobile ?
                            <Fragment>
                                <h5><b>{user}</b></h5>
                                <p className="text-testimony-mobile">{text}</p>
                            </Fragment>
                            :
                            <Fragment>
                                <h4><b>{user}</b></h4>
                                <p className="text-testimony-mobile">{text}</p>
                            </Fragment>

                    }
                </div>
            </div>
    );
};

export default Testimony;
