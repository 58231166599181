export const setReservationActions = reservation => {
    return {
        type: 'SET_RESERVATION',
        payload: reservation
    };
};

export const deleteReservationAction = () => {
    return {
        type: 'DELETE_RESERVATION'
    };
};
