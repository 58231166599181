const initialState = {
    nameAddress: '',
    address: '',
    idAddress: 0,
    addressTemp: null, // Almacena la direccion cuando el usuario no ha iniciado sesion
}

export default function(state = initialState, action) {
    switch (action.type) {
        case 'SET_ADDRESS':
            return {
                ...state,
                ...action.payload,
            };
        case 'DELETE_ADDRESS':
            return  {
                ...state,
                nameAddress: '',
                address: '',
                idAddress: 0,
                addressTemp: null,
            };
        default:
            return state;
    }
}
