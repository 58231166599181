import React, { Fragment, useState, useEffect } from 'react';
import clientAxios from '../../config/axios';
import './ModalMethodPayment.css';
import {formatMinutesToHours} from '../../Helpers/HoursHelper';
import {eventFB, eventG} from '../../Helpers/Events'
import {formatPrice} from '../../Helpers/PriceHelper';
import {alert} from "../../Helpers/Alert";
import {isEmpty} from "../../Helpers/ValidationsHelper";
import { useSelector } from 'react-redux';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import OutlinedInput from "@material-ui/core/OutlinedInput";

//SweetAlert2
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// react bootstrap
import Modal from 'react-bootstrap/Modal'

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const ModalMethodPayment = ({
    show,handleClose,reservationInput,userLogin,
    selectMethodPaymentOutput,productInput}) => {
    const docTypes = [
        {
            id: 'CC',
            value: 'CÉDULA DE CIUDADANÍA',
        }, 
        {
            id: 'CE',
            value: 'CÉDULA DE EXTRANJERÍA',
        },{
            id: 'CI',
            value: 'Cedula de identidad',
        }
        ,{
            id: 'RNS',
            value: 'RNS',
        },
        {
            id: 'PPN',
            value: 'PASAPORTE',
        },
        {
            id: 'SSN',
            value: 'NÚMERO DE SEGURIDAD SOCIAL',
        },
        {
            id: 'LIC',
            value: 'LICENCIA DE CONDUCCIÓN',
        },
        {
            id: 'NIT',
            value: 'NÚMERO DE INDENTIFICACIÓN TRIBUTARIA',
        },
        {
            id: 'DNI',
            value: 'DOCUMENTO NACIONAL DE IDENTIFICACIÓN',
        }
    ]
    const initalStatePSE = {
        bank: '',
        invoice: '',
        description: '',
        value: '',
        typePerson: '',
        docType: '',
        docNumber: '',
        name: '',
        lastName: '',
        email: '',
        phone: '',
    }
    const initialStateCard = {
        docType: '',
        docNumber: '',
        name: '',
        lastName: '',
        dues: '',
        number: '',
        expYear: '',
        expMonth: '',
        cvc: '',
    }
    var reservationG = useSelector( state => state.reservation.reservation );  
    const [methodPayment, setMethodPayment] = useState(1);
    const [nameMethodPayment,setNameMethodPayment] = useState('');
    const [couponTemp, setCouponTemp] = useState('');
    const [reservation,setReservation] = useState(null);
    const [product, setProduct] = useState(null)
    const [visibleOtherPayments,setVisibleOtherPayments] = useState(false);
    const [formPSE, setFormPSE] = useState(initalStatePSE);
    const [formCard, setFormCard] = useState(initialStateCard);
    const [banks, setBanks] = useState([]);
    const classes = useStyles();
    const productStore = useSelector( state => state.product);
    const handleChangeCoupon = e => {
        setCouponTemp(e.target.value);
    }
    const handleChangeFormPSE = e => {
        setFormPSE({
           ...formPSE,
           [e.target.name] : e.target.value,
        });
    }
    const handleChangeFormCard = e => {
        setFormCard({
            ...formCard,
            [e.target.name] : e.target.value,
        });
    }
    const hideOtherPayments = () => {
        setVisibleOtherPayments(false)
    }
    const showOtherPayments = () => {
        setVisibleOtherPayments(true);
        setMethodPayment(2);
    }
    const cleanCoupon = () => {
        setReservation({
            ...reservation,
            coupon: ''
        });
        setProduct({
            ...product,
            price: product.subtotalPrice,
            discount: 0
        })
    }
    const showAlertPriceCoupon = () => {
        alert.fire(
            'Advertencia',
            'El codigo promocional que acabas de ingresar reduce el total a pagar a una cifra inferior a $10.000,' + 
                ' por tal motivo debes cambiar de forma de pago para poder aplicarlo'
        );
    }
    const applyCoupon = () => {
        if (couponTemp === '') {
            alert.fire(
                'Ingresa un código',
                'Para aplicar un código promocional debes ingresarlo en el campo de texto',
                'warning'
            );
            return 
        }
        let dataTemp = { ...reservation }
        let productTemp = {...product}
        if (!userLogin.email) {
            alert.fire(
                'Advertencia',
                'Para aplicar códigos promocionales debes iniciar sesión.'
            );
        }
        const send = {
            idUsuario: userLogin.idUser,
            token: userLogin.token,
            coupon: couponTemp,
            product: productTemp.id,
            typeVehicle: dataTemp.typeVehicle,
        }
        const price = reservationG.country == 3?167:10000;
        clientAxios.post('shared/validarCodigoPromocional', send).then(({data}) => {
            if ( data.response === 0) {
                alert.fire('Código invalido', 'El código que ingresaste no es válido', 'warning');
                cleanCoupon()
            } else if ( data.response === 1 ) {
                // Se valida que el bono ingresado es un código promocional del 100%
                if (methodPayment === 4 && data.porcentaje_descuento != 100) {
                    alert.fire('Advertencia', 'El código promocional ingresado no es un bono', 'warning')
                    cleanCoupon();
                    return
                }
                let typeDiscount = 'valor'
                let message = '0';
                if ( data.valor_descuento ) {
                    productTemp = {
                        ...productTemp,
                        discount: data.valor_descuento
                    };
                    if ((methodPayment === 2 || methodPayment === 3) && ((productTemp.subtotalPrice - productTemp.discount) < price)) {
                        showAlertPriceCoupon()
                        return false
                    }
                    productTemp.price = productTemp.subtotalPrice - productTemp.discount
                    message = 'de ' + data.valor_descuento
          
                } else if ( data.porcentaje_descuento ) {
                    typeDiscount = 'procentaje'
                    productTemp = {
                        ...productTemp,
                        discount: productTemp.subtotalPrice * ( data.porcentaje_descuento / 100 )
                    };
                    if ((methodPayment === 2 || methodPayment === 3) && ((productTemp.subtotalPrice - productTemp.discount) < price)) {
                      showAlertPriceCoupon()
                      return false
                    }
                    productTemp = {
                        ...productTemp,
                        price: productTemp.subtotalPrice - productTemp.discount
                    };
                    message = 'del ' + data.porcentaje_descuento + '%';
                    
                }
                dataTemp = {
                    ...dataTemp,
                    coupon: couponTemp
                }
                setReservation({...dataTemp});
                setProduct({...productTemp})
                alert.fire('Código correcto', 'El ' + typeDiscount + ' de descuento aplicado es ' + message, 'success');
            }
        }).catch(err => console.log('error cupon -> ',err));
    }
    const showDataAddress = () => {
        let text = 'Selecciona una dirección';
        if (userLogin.email && reservation.idAddress !== 0) {
            text = `(${reservation.nameAddress}) ${reservation.address}`;
        } else if (reservation.addressTemp) {
            let temp = reservation.addressTemp;
            text = `(${temp.name}) ${temp.address1} ${temp.address2} ${(reservation.country == 2 ? ' y ' : ' # ')} 
                ${temp.address3} ${(reservation.country == 2 ? '' : ' - ')} ${temp.address4}`;
        }
        return text;
    }

    const continueReservation = () => {
        if (methodPayment === 2) {
            const fecha=formCard.fecha;
            console.log(fecha);
            if (fecha) { 
                formCard.expMonth = fecha.split("-")[1];
                formCard.expYear = fecha.split("-")[0];
                console.log(formCard.expMonth);
                console.log(formCard.expYear);
                const schema =  reservationG.country != 3 ?['docType','docNumber','name','lastName','dues','number','expYear','expMonth','cvc']:['docType','docNumber','name','lastName','number','expYear','expMonth','cvc'];
                if(isEmpty(formCard,schema)) {
                    console.log("error -> campos mont,year???");
                    alert.fire(
                        'Advertencia',
                        'Debes ingresar todos los campos obligatorios del formalario de Tarjeta de Crédito',
                        'warning'
                    );
                    return;
                }
            }else{
                console.log("error -> fecha???");
                alert.fire(
                    'Advertencia',
                    'Debe ingresar la fecha de expedicion de la tarjeta',
                    'warning'
                );
                return;
            }   
        } else if (methodPayment === 3) {
            if (isEmpty(formPSE,['bank','typePerson','docType','docNumber','name','lastName','phone'])) {
                alert.fire(
                    'Advertencia',
                    'Debes ingresar todos los campos obligatorios del formalario de PSE',
                    'warning'
                );
                return;
            }
        } else if (methodPayment === 4 && product.subtotalPrice != product.discount) {
            alert.fire(
                'Advertencia',
                'Debes aplicar un código promocional del 100% de descuento para seleccionar la forma de pago BONO',
                'warning'
            );
            return;
        }
        eventFB('AddPaymentInfo')
        console.log({formCard})
        selectMethodPaymentOutput({
            ...reservation,
            methodPayment,
            nameMethodPayment,
            pse: formPSE,
            card: formCard,
        },{...product});
    }
    const selectMethodPayment = method => {
        if (method != 2 && method != 3) {
            hideOtherPayments();
        }
        setMethodPayment(method);
        switch (method) {
            case 1:
                setNameMethodPayment('Efectivo');
                break;
            case 2:
                setNameMethodPayment('Tarjeta');
                break;
            case 3:
                setNameMethodPayment('Pago en línea');
                break;
            case 4:
                setNameMethodPayment('Bono');
                break;
        }
        cleanCoupon();
    }
    const loadBanks = () => {
        clientAxios.get('app/EpaycoBanks').then(({data}) => {
            console.log( typeof data);
            if (data.success) {
                setBanks(data.data);
            }
        }).catch(err => console.log('se ha generado un error al cargar la lista de bancos'))
    }
    useEffect(() => {
        if (!reservation) {
            setReservation(reservationInput);
            setProduct(productInput);
            setMethodPayment(reservationInput.methodPayment);
            setNameMethodPayment(reservationInput.nameMethodPayment);
        }
        loadBanks();
    },[]);

    return (
        reservation ? 
        <Fragment>
            <Modal show={show} onHide={() => handleClose(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalle de Pago</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <p>Medio de Pago</p>
                        </div>
                    </div>
                    <div className="row">
                        {
                          productStore.plan?
                          <div className="col-4">

                          </div>
                          :null  
                        }
                        
                         <div className={reservationG.country != 3?"col-4":"offset-2 col-4"}>
                            <div
                                className={(methodPayment === 2 || methodPayment === 3) ? 'paymentTypeActive' :'paymentType'}
                                onClick={() => showOtherPayments()}
                            >
                                <img src={require("../../assets/img/mobile-payment.png")} alt="en linea" className="full-width" />
                                En linea
                            </div>
                        </div> 
                       
                        {
                        reservationG.country != 3?
                            !productStore.plan?
                            <div className="col-4">
                                <div className={methodPayment === 1 ? 'paymentTypeActive' :'paymentType'} onClick={() => selectMethodPayment(1)}>
                                    <img src={require("../../assets/img/wallet.png")} alt="en linea" className="full-width" />
                                    Efectivo
                                </div>
                            </div>
                            :null
                        :null
                        }
                        {
                         !productStore.plan?
                        <div className="col-4">
                            <div className={methodPayment === 4 ? 'paymentTypeActive' :'paymentType'} onClick={() => selectMethodPayment(4)}>
                                <img src={require("../../assets/img/coupon.png")} alt="en linea" className="full-width" />
                                Bono
                            </div>
                        </div> :null
                        }
                    </div>
                    {
                    !productStore.plan?
                    <div className="row mt-3">
                        <div className="col-8">
                            <TextField 
                                value={couponTemp} 
                                name="codigo-promocional" 
                                onChange={handleChangeCoupon} 
                                label="Código promocional" 
                                variant="outlined" 
                                fullWidth={true}
                            />
                        </div>
                        <div className="col-4">
                            <button 
                                className="btn btn-primary full-width full-height"
                                onClick={() => applyCoupon()}
                            >Aplicar</button>
                        </div>
                    </div>:null
                    }
                    {
                        reservation.coupon ?
                        <div className="row" >
                            <div className="col-12">
                                <p>El código promocional aplicado es: {reservation.coupon}</p>
                            </div>
                        </div> : null
                    }
                    {
                        (methodPayment === 2 || methodPayment === 3) ?
                            <div className="row mt-3">
                                {
                                productStore.plan?<div className="col-2">
                                    
                                </div>:null
                                }
                                <div className="offset-2 col-4">
                                    <div className={methodPayment === 2 ? 'paymentTypeActive' :'paymentType'} onClick={() => selectMethodPayment(2)}>
                                        <img src={require("../../assets/img/credit-card.png")} alt="en linea" className="full-width" />
                                        Tarjeta
                                    </div>
                                </div>
                               {
                                reservationG.country != 3?
                                    !productStore.plan?
                                    <div className="col-4">
                                        <div className={methodPayment === 3 ? 'paymentTypeActive' :'paymentType'} onClick={() => selectMethodPayment(3)}>
                                            <img src={require("../../assets/img/pse.png")} alt="en linea" className="full-width" />
                                            PSE
                                        </div>
                                    </div>
                                    :null
                                :null
                               }
                            </div> : null
                    }
                    {
                        methodPayment === 2 ?
                            <div className="row">
                                <div className="col-12">
                                    <div className="custom-card mt-3">
                                        <h5>Tarjeta de Crédito</h5>

                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                                    <InputLabel htmlFor="docType2">Tipo de Documento</InputLabel>
                                                    <Select
                                                        native
                                                        value={formCard.docType}
                                                        onChange={handleChangeFormCard}
                                                        label="Tipo de Documento"
                                                        inputProps={{
                                                            name: 'docType',
                                                            id: 'docType2',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        {
                                                            docTypes.map(x => (
                                                                <option key={x.id} value={x.id} >{x.value }</option>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <TextField
                                                    value={formCard.docNumber}
                                                    name="docNumber"
                                                    onChange={handleChangeFormCard}
                                                    label="Documento" variant="outlined" fullWidth={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <TextField
                                                    value={formCard.name}
                                                    name="name"
                                                    onChange={handleChangeFormCard}
                                                    label="Nombre" variant="outlined" fullWidth={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <TextField
                                                    value={formCard.lastName}
                                                    name="lastName"
                                                    onChange={handleChangeFormCard}
                                                    label="Apellido" variant="outlined" fullWidth={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <TextField
                                                    value={formCard.number}
                                                    name="number"
                                                    onChange={handleChangeFormCard}
                                                    label="Número en la tarjeta" variant="outlined" fullWidth={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <TextField
                                                    value={formCard.cvc}
                                                    name="cvc"
                                                    onChange={handleChangeFormCard}
                                                    label="cvc" variant="outlined" fullWidth={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2">  
                                            <div className="col-12">
                                                <TextField
                                                    type="month"
                                                    value={formCard.fecha}
                                                    name="fecha"
                                                    onChange={handleChangeFormCard}
                                                    variant="outlined" fullWidth={true}
                                                /> 
                                            </div>
                                        </div>
                                        {/* <div className="row mt-2">
                                            <div className="col-6">
                                                <TextField
                                                    value={formCard.expYear}
                                                    name="expYear"
                                                    onChange={handleChangeFormCard}
                                                    label="Año" variant="outlined" fullWidth={true}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <TextField
                                                    value={formCard.expMonth}
                                                    name="expMonth"
                                                    onChange={handleChangeFormCard}
                                                    label="Mes" variant="outlined" fullWidth={true}
                                                />
                                            </div>
                                        </div> */}
                                        {
                                        reservationG.country != 3?
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <TextField
                                                    value={formCard.dues}
                                                    name="dues"
                                                    onChange={handleChangeFormCard}
                                                    label="Número de cuotas" variant="outlined" fullWidth={true}
                                                />
                                            </div>
                                        </div>
                                        : null
                                        }
                                    </div>
                                </div>
                            </div>
                            :null
                    }
                    {
                        methodPayment === 3 ?
                            <div className="row">
                                <div className="col-12">
                                    <div className="custom-card mt-3">
                                        <h5>Pago PSE</h5>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                                    <InputLabel htmlFor="bank">Banco</InputLabel>
                                                    <Select
                                                        native
                                                        value={formPSE.bank}
                                                        onChange={handleChangeFormPSE}
                                                        label="Banco"
                                                        inputProps={{
                                                            name: 'bank',
                                                            id: 'bank',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        {
                                                            banks.map(x => (
                                                                <option key={x.bankCode} value={x.bankCode} >{x.bankName}</option>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                                    <InputLabel htmlFor="typePerson">Tipo de Persona</InputLabel>
                                                    <Select
                                                        native
                                                        value={formPSE.typePerson}
                                                        onChange={handleChangeFormPSE}
                                                        label="Tipo de Persona"
                                                        inputProps={{
                                                            name: 'typePerson',
                                                            id: 'typePerson',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        <option value="0" >Natural</option>
                                                        <option value="1">Jurídica</option>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                                    <InputLabel htmlFor="docType">Tipo de Documento</InputLabel>
                                                    <Select
                                                        native
                                                        value={formPSE.docType}
                                                        onChange={handleChangeFormPSE}
                                                        label="Tipo de Documento"
                                                        inputProps={{
                                                            name: 'docType',
                                                            id: 'docType',
                                                        }}
                                                    >
                                                        <option aria-label="None" value="" />
                                                        {
                                                            docTypes.map(x => (
                                                                <option key={x.id} value={x.id} >{x.value}</option>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <TextField
                                                    value={formPSE.docNumber}
                                                    name="docNumber"
                                                    onChange={handleChangeFormPSE}
                                                    label="Documento" variant="outlined" fullWidth={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <TextField
                                                    value={formPSE.name}
                                                    name="name"
                                                    onChange={handleChangeFormPSE}
                                                    label="Nombre" variant="outlined" fullWidth={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <TextField
                                                    value={formPSE.lastName}
                                                    name="lastName"
                                                    onChange={handleChangeFormPSE}
                                                    label="Apellido" variant="outlined" fullWidth={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <TextField
                                                    value={formPSE.phone}
                                                    name="phone"
                                                    onChange={handleChangeFormPSE}
                                                    label="Teléfono" variant="outlined" fullWidth={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :null
                    }


                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="border-gray padding-20">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        Resumen Reserva
                                        <div className="hr"></div>
                                    </div>
                                    <div className="col-6 mb-1">
                                        Servicio
                                    </div>
                                    <div className="col-6 text-right">
                                        {product.name}
                                    </div>

                                    <div className="col-6 mb-1">
                                        Dirección
                                    </div>
                                    <div className="col-6 text-right">
                                        {showDataAddress()}
                                    </div>

                                    <div className="col-6 mb-1">
                                        Duración
                                    </div>
                                    <div className="col-6 text-right">
                                        {formatMinutesToHours(product.duration)}
                                    </div>

                                    <div className="col-6 mb-1">
                                        Valor Total
                                    </div>
                                    <div className="col-6 text-right">
                                        {formatPrice(product.subtotalPrice)}
                                    </div>

                                    <div className="col-6 mb-1">
                                        Descuento
                                    </div>
                                    <div className="col-6 text-right">
                                        {formatPrice(product.discount)}
                                    </div>

                                    <div className="col-7 mb-1 text-20">
                                        VALOR A PAGAR
                                    </div>
                                    <div className="col-5 text-right text-20">
                                        {formatPrice(product.price)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-2">
                        <div className="col-12">
                            <button 
                                className="btn btn-primary full-width" 
                                onClick={() => continueReservation()}
                            >
                                Continuar
                            </button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </Fragment> : null
    );
}

export default ModalMethodPayment;
