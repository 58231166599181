export const setUserActions = user => {
    return {
        type: 'SET_USER',
        payload: user
    };
}

export const deleteUserAction = () => {
    return {
        type: 'DELETE_USER'
    };
}