import React, { useEffect,useState, Fragment } from 'react';
import MenuApp from '../../components/MenuApp/MenuApp';
import clientAxios from '../../config/axios';
import { useSelector,useDispatch } from 'react-redux';
import { setUserActions } from '../../actions/userActions';
import { setReservationActions } from '../../actions/reservationActions';
import { setAddressAction } from '../../actions/addressActions';
import FormLogin from  '../../components/FormLogin/FormLogin';
import Address from '../../components/Address/Address';
import FormAddress from '../../components/FormAddress/FormAddress'
import Fab from '@material-ui/core/Fab';
import Swal from 'sweetalert2/src/sweetalert2.js'
import {useHistory } from 'react-router-dom';
import SelectCity from "../../components/SelectCity/SelectCity";
import MenuAppMobile from "../../components/MenuAppMobile/MenuAppMobile";

function Addresses() {

    const [isLogged, setIsLogged] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showModalAddress, setShowModalAddress] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [address, setAddress] = useState(null);

    const userLogin = useSelector( state => state.user);
    const reservationStore = useSelector( state => state.reservation.reservation );
    const addressStore = useSelector( state => state.address)
    const dispatch = useDispatch();
    const setUser = user => dispatch( setUserActions(user));
    const setReservation = reser => dispatch( setReservationActions(reser) );
    const setAddressStore = res => dispatch( setAddressAction(res));
    let history = useHistory();
    const handleClose = value => {
        setIsLogged(value);
        setShowLogin(false);
    }
    const handleCloseModalAddress = value => {
        setShowModalAddress(false);
        if (value) {
            loadAddresses({
                ...userLogin,
                city: reservationStore.city
            });
        }
    }
    const handleShow = () => setShowLogin(true);

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-primary ml-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    const action = (address, type) => {
        if ( type === 'edit' ) {
            setShowModalAddress(true);
            setAddress(address);
        } else {
            
            swalWithBootstrapButtons.fire({
                title: 'Eliminar Dirección?',
                text: 'Estás seguro que deseas eliminar la dirección?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Eliminar!',
                cancelButtonText: 'No, Cancelar!',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    
                    clientAxios.post('app/EliminarDireccion',{...userLogin,...address}).then(({data}) => {
                        if (data.response === 1) {
                            swalWithBootstrapButtons.fire(
                                'Operación Exitosa',
                                'La dirección ha sido eliminado con exito.',
                                'success'
                            )
                            loadAddresses({
                                ...userLogin,
                                city: reservationStore.city
                            });
                        } else if (data.response === 2) {
                            swalWithBootstrapButtons.fire(
                                'Advertencia',
                                'Para eliminar una dirección debes tener dos o más direcciones registradas.',
                                'warning'
                            )
                        }
                        
                    }).catch(err => console.err);
                    
                }
            })

        }

    }

    const selectAddress = a => {
        setAddressStore({
            ...addressStore,
            nameAddress: a.nombre,
            address: a.direccion + ' ' + a.calle + (reservationStore.country == 2 ? ' y ' : ' # ') + a.carrera1 + (reservationStore.country == 2 ? '' : ' - ') + a.carrera2,
            idAddress: a.iddireccion
        })
        if (reservationStore.goWashesAvailable) {
            history.push('/lavadosDisponibles');
        } else {
            history.push('/servicios');
        }
    }   

    const loadAddresses = user => {
        setAddresses([])
        clientAxios.post('app/todosDirecciones', user).then(({data})=> {
            if (data.response == 1) {
                setAddresses(data.data);
            }
        }).catch(err => {
            console.log('error al cargar direcciones');
        });
    }
    const goHome = () => {
        setReservation({
            ...reservationStore,
            openMenuMobile: true,
        })
        history.push('/servicios')
    }
    const createAddress = () => {
        setAddress(null);
        setShowModalAddress(true);
    }

    useEffect(() => {
        if (userLogin.idUser) {
            if (!userLogin.phone) {
                clientAxios.post('app/validarUsuarioToken',userLogin).then(res => {
                    if (res.data.response) {
                        setUser(res.data);
                        setIsLogged(true);
                        loadAddresses({
                            ...res.data,
                            city: reservationStore.city
                        });
                    } else {
                        setUser(null);
                    }
                }).catch(err => console.log('error -> ',err));
            } else {
                setIsLogged(true);
                loadAddresses({
                    ...userLogin,
                    city: reservationStore.city
                });
            }
        }        
    },[isLogged,reservationStore.city]);

    return (
        <div>
            <MenuAppMobile/>
            <div className="space-20"></div>
            <div className="container col-12">
                {/* <div className="row">
                    <div className="col-12">
                        <SelectCity/>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-md-3">
                        <div className="menuSticky" >
                            <MenuApp />
                        </div>
                    </div>
                    <div className="col-md-9 col-sm-12">

                        {!isLogged ? 
                            <Fragment>
                                <div className="space-10"></div>
                                <div className="row">
                                    <div className="col-12 container">
                                        <div className="divider"></div>
                                        <div className="card text-white bg-info mt-3 c-pointer" onClick={handleShow}>
                                            <div className="card-body text-center">
                                                <h4>Si ya eres cliente, Carga tu información aquí!
                                                </h4>
                                            </div>
                                        </div>
                                        <FormLogin  show={showLogin} handleClose={handleClose}/>
                                    </div>
                                    
                                </div>
                            </Fragment>
                            :
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h2>Direcciones</h2>
                                    <div className="col-12 text-center mb-3 mt-2">
                        <Fab color="primary" aria-label="add" onClick={createAddress}>
                            <i className="fas fa-plus color-white"></i>
                        </Fab>
                    </div>
                                    {addresses.length === 0 ?
                                        <h4 className="mt-3">No tienes direcciones registradas para la ciudad seleccionada</h4>
                                        : null}
                                </div>
                            </div>
                        }
                        <div className="row">
                            {addresses.map( (x, index) => (
                                <div className="col-12 col-md-6 mb-3" key={index}>
                                    <Address
                                        data={x}
                                        id= {index}
                                        idSelected = {addressStore.idAddress}
                                        selectAddress = {selectAddress}
                                        action = {action}
                                    />
                                </div>
                            ))}
                        </div>
                        
                    </div>
                </div>
                <div className="row">                   
                    <div className="col-12 text-center mb-3 mt-2">
                        <button className="btn btn-primary invisible-on-desktop" onClick={() => goHome()}>Ir al Inicio</button>
                    </div>
                </div>
                <div className="mucho"></div> 
            </div>
            {showModalAddress ?
            <FormAddress 
                show={showModalAddress} 
                handleClose={handleCloseModalAddress}
                swalWithBootstrapButtons={swalWithBootstrapButtons}
                reservationStore={reservationStore}
                userLogin={userLogin}
                addressStore={addressStore}
                setAddressStore={setAddressStore}
                address={address}
            />
            : ''}
            
        </div>
    );
}

export default Addresses;
