import React, { Fragment, useState, useEffect } from 'react';
import clientAxios from '../../config/axios';
import {alert} from "../../Helpers/Alert";
import "./ModalQualification.css";
// react bootstrap
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import TextField from "@material-ui/core/TextField";
import BeautyStars from "beauty-stars";

const ModalQualification = ({ show, handleClose,data, user }) => {

    const stateInitialForm = {
        rate: 0,
        observation: '',
    };
    const [dataForm, setDataForm] = useState(stateInitialForm);

    const handleChange = e => {
        e.preventDefault()
        setDataForm({
            ...dataForm,
            [e.target.name]: e.target.value
        })
    }
    const rateService = () => {
        const send = {
            ...dataForm,
            idService: data.idservicio,
            idUsuario: user.idUser,
            token: user.token
        }
        clientAxios.post('app/CalificarServicio', send).then(({data}) => {
            if (data.response == 1) {
                let text = ''
                if (dataForm.rate == 1 || dataForm.rate == 2) {
                    text = 'Gracias por tu respuesta. Un miembro de nuestro equipo se comunicara contigo.'
                } else if (dataForm.rate == 3) {
                    text = 'Nos gustaría escucharte para mejorar nuestro servicio. Un miembro de nuestro equipo se comunicara contigo.'
                } else if (dataForm.rate == 4) {
                    text = 'Nos alegra que te guste nuestro servicio, el proximo lograremos un  5!'
                } else if (dataForm.rate == 5) {
                    text = 'Nos encanta que estes feliz con nuestro servicio! Te esperamos pronto'
                }
                alert.fire(
                    'Calificación Enviada',
                    text,
                    'success'
                );
            } else if (data.response == 2) {
                alert.fire('Se ha generado un error',  'No se ha enviado la calificación','error')
            }
            handleClose(true);
        }).catch(err => console.log('error al enviar calificación'));
    }
    return (
        <Fragment>
            <Modal show={show} onHide={() => handleClose(true)}>
                <Modal.Header closeButton>
                    <Modal.Title>Califica el Servicio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-1">
                        <div className="col-12">
                            Hace poco reservaste un servicio y nos encataría saber qué tal te ha parecido, ¿Cómo lo calificarías?
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>Servicio: {data.servicio.nombre}</p>
                            <p>Fecha: {data.fecha}</p>
                            <p>Encargado : {data.conductor}</p>
                            <p>ID Servicio : {data.idservicio}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>Observaciones: </p>
                            <TextField value={dataForm.observation} name="observation" onChange={handleChange} label="Observaciones" variant="outlined" fullWidth={true} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>Calificación: </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="stars-center">
                                <BeautyStars
                                    value={dataForm.rate}
                                    inactiveColor ="CCCCCC"
                                    onChange={value => setDataForm({ ...dataForm,rate: value })}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        dataForm.rate !== 0 ?
                            <div className="row mt-2">
                                <div className="col-12 text-center">
                                    <button className="btn btn-primary" onClick={() => rateService()}>Calificar</button>
                                </div>
                            </div>
                        : null
                    }

                </Modal.Body>
            </Modal>
        </Fragment>
    );
}

export default ModalQualification;
