const initialState = {
    reservation: {
        city: 1,
        oldCity: 1, // Se usa para saber si debe o no cargar la lista de productos cuando se ingresa a /servicios
        cityName: 'Bogotá',
        firstCitySelect: true, // Para obligar la selección de ciudad
        errorCity: true,//para detectar el error de la geolocalizacion
        ccTLD: 'CO',
        country: 1,
        typeVehicle: '1',
        plaque: 'V1',
        coupon: null,
        date: null,
        dateTemp: 0,
        currency: null,
        methodPayment: 0,
        nameMethodPayment: 'Selecciona un metodo de pago',
        card : null,
        pse: null,
        goWashesAvailable: false,
        changeInCity: true,
        openMenuMobile: false, 
    }
}

export default function(state = initialState, action) {
    switch (action.type) {
        case 'SET_RESERVATION':
            return {
                ...state,
                reservation: {...action.payload}
            };
        case 'DELETE_RESERVATION':
            return  {
                reservation: {
                    city: 1,
                    oldCity: 1,
                    cityName: 'Bogotá',
                    firstCitySelect: true,
                    ccTLD: 'CO',
                    country: 1,
                    typeVehicle: '1',
                    plaque: 'V1',
                    coupon: null,
                    date: null,
                    dateTemp: 0,
                    currency: null,
                    methodPayment: 1,
                    nameMethodPayment: 'Efectivo',
                    card : null,
                    pse: null,
                    goWashesAvailable: false,
                    changeInCity: true,
                }
            };
        default:
            return state;
    }
}
