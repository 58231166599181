import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import './Location.css'
import mapImg from "../../assets/img/earth.svg"; 

const Location = ({onClick}) => {
   
    const reservation = useSelector( state => state.reservation.reservation );
    return (
        <div className="row">
                 <div className="col-12 text-center">
                     <button className="custom-map-button" onClick = {onClick}>
                         <img src={mapImg} alt="map-img"/>
                         <span>Estas en: {reservation.cityName}</span>
                     </button>
                 </div>
             </div>
    );
}

export default Location;