import React, {Fragment, useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {useHistory } from 'react-router-dom';
import ModalDate from '../../components/ModalDate/ModalDate';
import {eventFB, eventG} from '../../Helpers/Events'

import { setUserActions } from '../../actions/userActions';
import { setReservationActions } from '../../actions/reservationActions';
import { setProductAcions } from '../../actions/productsActions';
import { setAddressAction } from '../../actions/addressActions';
import clientAxios from '../../config/axios';
import FormAddress from '../../components/FormAddress/FormAddress';
import ModalMethodPayment from '../../components/ModalMethodPayment/ModalMethodPayment'
import ModalVitalData from '../../components/ModalVitalData/ModalVitalData'
import {formatMinutesToHours} from '../../Helpers/HoursHelper';
import {formatPrice} from '../../Helpers/PriceHelper';
import SelectCity from "../../components/SelectCity/SelectCity";
import MenuAppMobile from "../../components/MenuAppMobile/MenuAppMobile";
import ItemHome from '../../components/ItemHome/ItemHome';
import MenuApp from '../../components/MenuApp/MenuApp';
import {alert} from "../../Helpers/Alert";
import './Services.css';
import FormLogin from "../../components/FormLogin/FormLogin";
import ModalQualification from "../../components/ModalQualification/ModalQualification";
import ModalAmountServices from "../../components/ModalAmountServices/ModalAmountServices";
import LazyLoad from "react-lazy-load";
import ImageLoader from "../../components/ImageLoader/ImageLoader";
import logo from "../../assets/img/logo-inicio-blanco.png";
import Carousel from "react-bootstrap/Carousel";
import Footer from "../../components/Footer/Footer";
import {scrollToID} from "../../Helpers/Scroll";
import FullLoading from "../../components/FullLoading/FullLoading";
import CarouselClients from "../../components/CarouselClients/CarouselClients";
import {isNotEmpty, isNoSpacesInObj} from '../../Helpers/ValidationsHelper';
import ModalPSE from "../../components/ModalPSE/ModalPSE";
const Services = () => {

    let history = useHistory();
    let qualifications = []
    const userLogin = useSelector( state => state.user);
    var reservation = useSelector( state => state.reservation.reservation );
    const addressStore = useSelector( state => state.address);
    var productStore = useSelector( state => state.product);
    const [plan,setPlan ]=  useState('1');
    const dispatch = useDispatch();
    const setUser = user => dispatch( setUserActions(user));
    const setReservation = res => dispatch( setReservationActions(res));
    const setAddressStore = res => dispatch( setAddressAction(res));
    const setProductStore = res => dispatch( setProductAcions(res));
    const [scrollable, setscrollable] = useState(true);
    const [isLogged, setIsLogged] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showModalCity, setShowModalCity] = useState(false);
    const [showFormAddress, setShowFormAddress ] = useState(false);
    const [showModalVitalData,setShowModalVitalData] = useState(false)
    const [showModalMethodPayment,setShowModalMethodPayment] = useState(false);
    const [showModalDate, setShowModalDate] = useState(false);
    const [showModalAmountServices, setShowModalAmountServices] = useState(false);
    const [showModalQualification,setShowModalQualification] = useState(false)
    const [showFullLoading, setShowFullLoading] = useState(false);
    const [registerUser, setRegisterUser] = useState(false);
    const [automaticReservation, setAutomaticReservation] = useState({login: false, vitalData: false});
    const [qualification, setQualification] = useState(null)
    const [buttonActive, setButtonActive] = useState(true);
    const [maxAmount, setMaxAmount] = useState(2);
    const [emitEventGoogle, setEmitEventGoogle] = useState(true);

    const [urlPSE, setUrlPSE] = useState("hola");
    const [statusSendPSE, setStatusSendPSE] = useState();
    const [showModalPSE, setShowModalPSE] = useState(false);

    const handleClosePSE = () => {
        setShowModalMethodPayment(false);
    }
    const irPSE = () => { 
        setUrlPSE("limpia");
        if(urlPSE!=="limpia"){
            window.open(urlPSE);
            setShowModalPSE(false);
            setShowFullLoading(true);
        }
    }
    const handleClose = value => {
        setIsLogged(value);
        if (value && registerUser) {
            setAutomaticReservation({
                ...automaticReservation,
                login:true
            })
        }
        setShowLogin(false);
    }
    const handleCloseDate = data => {
        setShowModalDate(false);
    }
    const handleCloseQualification = data => {
        if (data) {
            loadQualifications()
        }
    }
    const handleCloseFormAddress = data => {
        setShowFormAddress(false);
    }
    const handleCloseMethodPayment = data => {
        setShowModalMethodPayment(false);
    }
    const handleCloseVitalData = data => {
        if (data) {
            setAutomaticReservation({
                ...automaticReservation,
                vitalData: true
            })
        }
        setShowModalVitalData(false);
    }
    const handleCloseAmountServices = value => {
        setReservation({
            ...reservation,
            date: null
        })
        setProductStore({
            ...productStore,
            amount: value,
            duration: productStore.durationTemp * value,
        })
        setShowModalAmountServices(false);
    }
    const handleShow = () => setShowLogin(true);

    const go = route => {
        if(reservation.errorCity){
            setShowModalCity(true);
        }else{
            history.push(route);
        }
    }
    const showDataVehicle = () => {
        let body = '';
        let name = '';
        if (reservation.typeVehicle && 
            reservation.plaque !== 'V1' && 
            reservation.plaque !== 'V2' &&  reservation.plaque !== 'V3') {
            if (reservation.country == 1) {
                name = reservation.typeVehicle ==='1' ? 'Automóvil (carro)':'Camioneta';
            } else {
                name = reservation.typeVehicle ==='1' ? 'Pequeño':'Grande';
            }
            body = reservation.plaque ? ' : ' + reservation.plaque : '';
        } else {
            if (reservation.country && reservation.country != 1) {
                if ( reservation.country == 3) {
                    name = reservation.plaque ==='V1' ? 'Automóvil (carro)':'JEEPETA';
                }else{
                    name = reservation.plaque ==='V1' ? 'Pequeño':'Grande';
                }
            } else {
                name = reservation.plaque ==='V1' ? 'Automóvil (carro)':'Camioneta';
            }
        }
        return name + ' ' + body;
    }

    const showDataProduct = () => {
        return productStore.name ? productStore.name : 'Selecciona un lavado';
    }

    const showDataAddress = () => {
        let text = 'Selecciona una dirección';
        if (userLogin.phone && addressStore.idAddress !== 0) {
            text = `(${addressStore.nameAddress}) ${addressStore.address}`;
        } else if (addressStore.addressTemp) {
            let temp = addressStore.addressTemp;
            text = `(${temp.name}) ${temp.address1} ${temp.address2} ${(reservation.country == 2 ? ' y ' : ' # ')} 
                ${temp.address3} ${(reservation.country == 2 ? '' : ' - ')} ${temp.address4}`;
        }
        return text;
    }

    const showDataMethodPayment = () => {
        return reservation.nameMethodPayment;
    }

    const clicDate = () => {
        if(reservation.errorCity){
            setShowModalCity(true);
            return
        }
        if (!productStore.id) {
            alert.fire(
                'Advertencia',
                'Debes seleccionar un producto antes de seleccionar la fecha.',
                'warning'
            )
            history.push("/listadoServicios");
            return
        }
        setShowModalDate(true);
    }

    const onClickAddress = () => {
        if(reservation.errorCity){
            setShowModalCity(true);
            return
        }
        if (userLogin.phone) {
            go('/direcciones');
        } else {
            setShowFormAddress(true);
        }
    }

    const onClickMethodPayment = () => {
        if(reservation.errorCity){
            setShowModalCity(true);
            return
        }
        setShowModalMethodPayment(true);
    }
    
    const dateFormatPlusOneHour = (date)=>{
        const dateHour = date.split(" ");// 2020-20-01 03:00:00 = ["2020-20-01", "03:00:00"]
        const time = dateHour[1].split(":");//03:00:00 = ["03", "00":"00"]
        for (let index = 0; index < time.length; index++) {
            time[index] =parseInt(time[index]) ;
        }
        time[2] = time[2]>9?time[2] :"0"+time[2];
        time[1] = time[1]>9?time[1] :"0"+time[1];
        time[0] = time[0]+1;
        time[0] = time[0]>9?time[0] :"0"+time[0];
        return dateHour[0] + " "+time[0]+":"+time[1]+":"+ time[2] ;
    }

    const selectHour  = (date, dateTemp) => {
      // date = dateFormatPlusOneHour(date);
        setReservation({
            ...reservation,
            date,
            dateTemp 
        })
        setShowModalDate(false);
        eventFB('Schedule')
        eventG({
            'send_to': 'AW-993947064/tCp2CJL9wLsBELjb-dkD',
            'event_callback': null
        })
    }

    const selectMethodPayment =async (data,product) =>  {
        setReservation({
            ...data
        });
        setProductStore({
            ...product
        })
        await createReservation(data,product);
        handleCloseMethodPayment();
    }

    const createReservation = async (data,product) => {
        if (data&&product) {
            reservation = data;
            productStore = product;
        }
        if(reservation.errorCity){
            setShowModalCity(true);
            return
        }
        if (!productStore.id) {
            alert.fire(
                'Advertencia',
                'Debes seleccionar un producto antes de seleccionar la fecha.',
                'warning'
            )
            history.push("/listadoServicios")
            return
        }
        if (reservation.methodPayment == 0) {
            alert.fire(
                'Advertencia',
                'Debes seleccionar un metodo de pago valido',
                'warning'
            );
            return;
        }
        
        if (reservation.country == 3) {
            if (reservation.methodPayment !== 2 && reservation.methodPayment !== 4) {
                alert.fire(
                    'Advertencia',
                    'Debes seleccionar el metodo de pago "tarjeta de credito" para este producto',
                    'warning'
                );
                return;
            }
        }
        if ( !reservation.date) {
            alert.fire(
                'Advertencia',
                'Debes seleccionar la fecha y la hora para proceder con la reserva',
                'warning'
            );
            return;
        }
        if ( addressStore.idAddress === 0 && !addressStore.addressTemp) {
            alert.fire(
                'Advertencia',
                'Debes seleccionar la dirección para proceder con la reserva',
                'warning'
            );
            return;
        }
        if (emitEventGoogle) {
            eventG({'send_to': 'AW-993947064/1BogCJfjsNQBELjb-dkD'})
            setEmitEventGoogle(false)
        }
        if (isLogged) {
          const datauser  = verifyData();
           if (!datauser) {
                console.log({msg:"validar", userLogin});
                setShowModalVitalData(true);
               return;
           } 
            // validar datos vitales
        }else if (!isLogged) {
            // pedir login
            setRegisterUser(true);
            setShowLogin(true);
            return;
        }  
        setShowFullLoading(true);
        if (productStore.plan) {

            if (reservation.methodPayment !== 2) {
                alert.fire(
                    'Advertencia',
                    'Debes seleccionar el metodo de pago "tarjeta de credito" para este producto',
                    'warning'
                );
                return;
            }else{
                const responseIp = await fetch("https://api.ipify.org?format=json");
                const {ip} = await responseIp.json();
                console.log({ip});
                //return;
                const res = {
                    ...userLogin,
                    ...reservation,
                    ...addressStore,
                    ip,
                    product: {
                        ...productStore,
                        typeVehicle:reservation.typeVehicle
                    },
                    web: true,
                }
                var dataReservation = await clientAxios.post('shared/CrearPedido', res);
                dataReservation = dataReservation.data;
                if (dataReservation.response  == 1) {
                res.idservicio = dataReservation.idservicio;
                const suscripcion = await clientAxios.post('shared/EpaycoCrearSuscripcion', res);
                console.log({suscripcion});
                data = suscripcion.data;
                    console.log({epayco:data});
                    const {token} = data ;
                    const {chargeSubscription} = data;
                    const {plan} = data;
                    if(token ){
                        if (!token.status) {
                            cancelarSuscripcion(dataReservation.idservicio_disponible, plan.id);
                            alert.fire(
                                'Advertencia',
                                'No se a podido generar el token, verifique la informacion de su tarjeta de credito',
                                'warning'
                            );
                        } 
                        if (chargeSubscription) {
                            if (!chargeSubscription.success || chargeSubscription.subscription.status != "active") {
                                console.log({chargeSubscription});
                                //cancelarSuscripcion(dataReservation.idservicio_disponible,plan.id);
                                alert.fire(
                                    'Advertencia',
                                    'se esta procesadon tu pago, en breve se te notificara a tu correo',
                                    'warning'
                                );
                            }else{
                                alert.fire(
                                                'Confirmación del servicio',
                                                'Se a generado el pago de su plan mensual',
                                                'success'
                                            );
                           
                            }
                        }else{
                            alert.fire(
                                'Advertencia',
                                'Este plan ya esta activo',
                                'warning'
                            );
                            cancelarPlan(dataReservation.idservicio);
                        }
                    }else{
                        cancelarSuscripcion(dataReservation.idservicio_disponible,plan.id);
                    }
                }
               else if (dataReservation.response == 3 ) {
                 alert.fire(
                     'Advertenica',
                     'En este momento no tenemos personal disponible para la fecha y hora seleccionada,' +
                     ' por favor vuelva a seleccionar, Gracias!',
                     'success'
                     );
                     setButtonActive(true);
                 } else {
                     alert.fire(
                         'Se ha generado un error',
                         'No fue posible agendar el servicio, conéctese a internet o intente mas tarde',
                         'error'
                         );
                         setButtonActive(true);
                     }            
            }
        }else{
          const send = {
              ...userLogin,
              ...reservation,
              ...addressStore,
              product: {
                  ...productStore
            },
            web: true,
        }
        setButtonActive(false);
        clientAxios.post('shared/CrearPedido', send).then(async ({data}) => {
            setEmitEventGoogle(true)
            const dataReservation = data;
            if (dataReservation.response  == 1) {
                eventFB('Purchase', {
                    value: productStore.price,
                    currency: reservation.currency
                });
                eventG({
                    'send_to': 'AW-993947064/RBTVCMbmwLsBELjb-dkD',
                    'value': parseInt(productStore.price),
                    'currency': 'COP',
                    'transaction_id': '',
                    'event_callback': null
                });
                if (reservation.methodPayment == 2)  {
                    // pago con tarjeta
                    // TODO hacer el respectivo proceso para la forma de pago seleccionada
                    const FlgAzul = reservation.country==3?true:false;
                    let send;
                    if (FlgAzul) {
                        send = {
                            ...reservation.card,
                        } 
                    } else {
                        const responseIp = await fetch("https://api.ipify.org?format=json");
                        const {ip} = await responseIp.json();
                        send = {
                            ...reservation.card,
                            ip
                        } 
                    }
                    send = {
                        ...send,
                        bill: String(dataReservation.idservicio),
                        description: productStore.name,
                        value: productStore.subtotalPrice - productStore.discount,
                        email: userLogin.email,
                        methodPayment: reservation.methodPayment
                    }

                    clientAxios.post(FlgAzul?'app/AzulPago':'app/EpaycoTC', send).then(({data}) => {
                        if (data.status) {
                            showAlertConfirmService(dataReservation);
                        } else {
                            setShowFullLoading(false);
                            alert.fire(
                                'Pago con tarjeta de crédito incorrecto',
                                data.message,
                                'error'
                                );
                        }
                        setButtonActive(true);
                    }).catch(err => {
                        setShowFullLoading(false);
                        console.log('error TC');
                        showAlertConfirmService(dataReservation);
                        setButtonActive(true);
                    })

                } else if (reservation.methodPayment == 3) {
                    //Pago PSE
                    // TODO hacer el respectivo proceso para la forma de pago seleccionada

                    const responseIp = await fetch("https://api.ipify.org?format=json");
                    const {ip} = await responseIp.json();
                    let send = {
                       ...reservation.pse,
                       ip,
                    }
                    send = {
                        ...send,
                        invoice: String(dataReservation.idservicio),
                        description: productStore.name,
                        value: productStore.subtotalPrice - productStore.discount,
                        email: userLogin.email
                    }
                  
                    clientAxios.post('app/EpaycoPSE', send).then(({data}) => {
                        if (data.success) {
                            setUrlPSE(data.urlbanco);
                            setShowModalPSE(true);
                            setTimeout(() => {
                                const send = {
                                    ...userLogin,
                                    idService:dataReservation.idservicio,
                                }
                                clientAxios.post('app/statusPSE',send).then(({data})=> {
                                    showAlertConfirmService(
                                        dataReservation,
                                        true,
                                        '<p>'+data.message+'</p>'
                                    );
                                }).catch(err => {
                                    showAlertConfirmService(
                                        dataReservation,
                                        true,
                                        '<p>La reserva se ha realizado pero por algún motivo no sabemos la respuesta de tu pago</p>'
                                    );
                                })
    
                                setButtonActive(true);
                            },60000);
                        }
                        // setShowFullLoading(true);
                    }).catch(err => {
                        setButtonActive(true);
                        showAlertConfirmService(dataReservation);
                        console.log('error PSE');
                    })
                } else {
                    showAlertConfirmService(dataReservation);
                    setButtonActive(true);
                }
            } else if (dataReservation.response == 3 ) {
                alert.fire(
                    'Advertenica',
                    'En este momento no tenemos personal disponible para la fecha y hora seleccionada,' +
                    ' por favor vuelva a seleccionar, Gracias!',
                    'success'
                    );
                setButtonActive(true);
            } else {
                alert.fire(
                    'Se ha generado un error',
                    'No fue posible agendar el servicio, conéctese a internet o intente mas tarde',
                    'error'
                    );
                    setButtonActive(true);
                }
            }).catch(err =>  {
            console.log('se ha generado un error al crear reserva', err);
            setButtonActive(true);
        });
        
        setAutomaticReservation({
            login: false,
            vitalData: false,
        })
        }
    };
    const cancelarSuscripcion = (idservicio_disponible)=>{
        const idplan = plan;
        const cancelar = {
            ...userLogin,
            idservicio_disponible,
            idplan
        };
        clientAxios.post('shared/EpaycoCancelarSuscripcion', cancelar).then((data)=>{

        });
    }  
    const cancelarPlan = (idservicio)=>{
        const cancelar = {
            idservicio
        }
        clientAxios.post('shared/CancelarPedido', cancelar).then((data)=>{

        });
    };
    
    const showAlertConfirmService = (data,pse,extra) => {
        setShowFullLoading(false);
        let message = '<p>La persona que le prestará el servicio es: <b>'
            + data.conductor + '</b></p>' + '<p>Teléfono : <b>' + data.telefonos + '</b></p>';
        if (pse) {
            message += '<p><b>Importante:</b><br/>Los pagos con PSE pueden tardar un poco en ser aprobados</p>';
            message += extra;
        }
        alert.fire(
            'Confirmación del servicio',
            message,
            'success'
        ).then(() => {
            go('/reservasActivas');
            cleanReservation()
        })
    };
    const verifyData = () => {
     
       const flag = isNoSpacesInObj({
            email: userLogin.email,
            firstName: userLogin.firstName,
            lastName: userLogin.lastName,
            identifier: userLogin.identifier,
            phone: userLogin.phone
        });
       
        return flag;
    }
    const cleanReservation = () => {
        setReservation({
            ...reservation,
            nameAddress: '',
            address: '',
            idAddress: 0,
            addressTemp: null,
            coupon: null,
            date: null,
            dateTemp: 0,
            currency: null,
            methodPayment: 1,
            nameMethodPayment: 'Efectivo',
            card : null,
            pse: null,
            product: {
                id: null,
                duration: null,
                durationTemp: null,
                name: null,
                subtotalPrice: null,
                discount: 0,
                price: null,
                points: null,
                amount: 1
            }
        })
        setAddressStore({
            nameAddress: '',
            address: '',
            idAddress: 0,
            addressTemp: null,
        })
        setProductStore({
            id: null,
            duration: null,
            durationTemp: null,
            name: null,
            subtotalPrice: null,
            discount: 0,
            price: null,
            points: null,
            amount: 1
        })
    }
    const loadProduct = () => {
        if (productStore.id === null || reservation.city != reservation.oldCity) {
            const send = {
                vehicle: reservation.typeVehicle,
                type: 1,
                city: reservation.city,
            }
            const consulta  = reservation.city == 2? 'app/TodosProductoCopia':'app/TodosProducto';
            clientAxios.post(consulta, send).then(({data}) => {
                if (data.products.length > 0) {
                    const product = data.products[0];
                    setReservation({
                        ...reservation,
                        oldCity: reservation.city,
                        date: null,
                        dateTemp: 1,
                        currency: data.currency,
                    });
                    // setProductStore({
                    //     id: product.idproducto,
                    //     duration: product.duracion,
                    //     durationTemp: product.duracion,
                    //     name: product.nombre,
                    //     points: product.puntos,
                    //     price: product.precio,
                    //     subtotalPrice: product.precio,
                    //     discount: 0,
                    //     amount: 1
                    // })
                }
                
            }).catch( err => console.log('Se ha generado un error al cargar los productos'));
        } else {
            const str = String(productStore.name);
            let amount = 1;
            let flag = false;
            let max;
            if(str.search('1 Lavado')  !== -1)
            { 
                max = 1;
            }else if (str.search('X2') !== -1 || str.search('2 Lavados') !== -1) {
                flag = true;
                max = 2;
            } else if (str.search('X4') !== -1 || str.search('4 Lavados') !== -1) {
                flag = true;
                max = 4;
            } else {
                setShowModalAmountServices(false)
                setProductStore({
                    ...productStore,
                    amount: 1,
                    duration: productStore.durationTemp
                })
            }
            console.log({str,max,flg: str.search('1 Lavado')});
            if (flag) {
                setReservation({
                    ...reservation,
                    date: null,
                });
                setMaxAmount(max);
                setShowModalAmountServices(true)
            }
        }
    }

    const loadQualifications = () => {

        if (qualifications.length === 0 && isLogged) {
            const send = {
                idUser : userLogin.idUser,
                token: userLogin.token,
                qualification: true
            }
            clientAxios.post('app/TodosMisServicios', send).then(({data}) => {
                if (data.response != 2) {
                    qualifications = data
                    setQualification(qualifications[0]);
                    setShowModalQualification(true)
                    qualifications.splice(0,1)
                } else {
                    setQualification(null);
                    setShowModalQualification(false)
                }
            }).catch(err => console.log('error al cargar calificaciones'))

        } else {
            if (qualifications.length > 0) {
                setQualification(qualifications[0]);
                setShowModalQualification(true)
                qualifications.splice(0,1)
            } else {
                setQualification(null);
                setShowModalQualification(false)
            }
        }

    }
    const loadAddress = user => {
        if (addressStore.idAddress === 0) {
            clientAxios.post('app/todosDirecciones', user).then(({data})=> {
                if (data.response == 1) {
                    const address = data.data[0]
                    setAddressStore({
                        ...addressStore,
                        nameAddress: address.nombre,
                        address: address.direccion + ' ' + address.calle + (reservation.country == 2 ? ' y ' : ' # ') + address.carrera1 + (reservation.country == 2 ? '' : ' - ') + address.carrera2,
                        idAddress: address.iddireccion
                    });
                }
            }).catch(err => {
                console.log('error al cargar direccion');
            });
        }
    }

    const openWebwhatsapp = ()=>{
        const wappCo = "https://api.whatsapp.com/send?phone=573113103519&text=Hola%21%20me%20interesa%20lavar,%20publicar%20y%20vender%20mi%20carro,%20%C2%BFme%20pude%20dar%20mas%20informaci%C3%B3n?%20%C2%A1Gracias%21";
        window.open(wappCo);
    }
    
    const validateLocation = (page)=>{

    }
    const modelCityClose = () => {
        setShowModalCity(false);
    }

    const openModalCity = () => {
        setShowModalCity(true);
    }
    useEffect(() => {
    
        if (userLogin.idUser) {
            if (!userLogin.phone) {
                clientAxios.post('app/validarUsuarioToken',userLogin).then(res => {
                    if (res.data.response) {
                        setUser(res.data);
                        setIsLogged(true);
                        loadAddress({
                            ...res.data,
                            city: reservation.city
                        });
                        if (scrollable) {
                            scrollToID('scroll-city');
                            setscrollable(false)
                        }
                    } else {
                        setUser(null);
                    }
                }).catch(err => console.log('error -> ',err));
            } else {
                setIsLogged(true);
                loadAddress({
                    ...userLogin,
                    city: reservation.city
                });
                if (scrollable) {
                    scrollToID('scroll-city');
                    setscrollable(false)
                }
            }
        }        
        loadProduct();
        loadQualifications();
        setRegisterUser(false);

        if (automaticReservation.login || automaticReservation.vitalData) {
            createReservation()
        } 
        
    },[reservation.city, automaticReservation, isLogged]);

    return (
        <div>
            <MenuAppMobile/>
            <div className="space-20"></div>
            <section>
                <div className="container">
                    <div className="row mb-2">
                        <div className="col-12">
                            <CarouselClients />
                        </div>
                    </div>
                    {/* { 
                    reservation.country == 1?
                        <Fragment>
                            
                            <div className="row mt-2">
                                <div className="col mt-1 mb-1 ml-1  pt-2 text-center">
                                    <img src={require("../../assets/img/clients/MERCADOLIBRE.png")} className="img-fluid" alt="Responsive image"/>
                                </div>
                              
                                <div className="col mt-1 mb-1 mr-1 pt-2 text-center border-left border-dark">
                                    <button type="button" className="btn buttonMercadoLibre" onClick={openWebwhatsapp}>
                                        LAVA, PUBLICA Y VENDE TU CARRO <br/>!AQUI¡
                                    </button>
                                </div>
                            </div>
                            <br/>
                        </Fragment>:null
                    
                    } */}
                    {
                        // !isLogged ?
                        //     <div className="row mt-2">
                        //         <div className="col-12 mt-2 mb-2 text-center">
                        //             <h2>Testimonios</h2>
                        //         </div>
                        //         <div className="col-12">
                        //             <Carousel>
                        //                 <Carousel.Item>
                        //                     <div className="slides-testimonies ">
                        //                         <p>Muchas gracias por el servicio de Julian muy bueno. El carro apesar que es blanco y estaba manchado, lo dejo lindisimo!</p>
                        //                     </div>
                        //                 </Carousel.Item>
                        //                 <Carousel.Item>
                        //                     <div className="slides-testimonies ">
                        //                         <p>Supremamente recomendado el servicio de Lavelo Pues, llegan a mi casa y la dejan como nueva. Sin ni una sola gota</p>
                        //                     </div>
                        //                 </Carousel.Item>
                        //                 <Carousel.Item>
                        //                     <div className="slides-testimonies ">
                        //                         <p>Muchas gracias, todo super. Los he recomendado con todo mi grupo de salidas</p>
                        //                     </div>
                        //                 </Carousel.Item>
                        //             </Carousel>
                        //         </div>
                        //     </div>
                        //     :
                        //     null
                    }

                </div>
            </section>
            <div className="container col-12">
                <div className="row">
                    {
                        !isLogged ?
                            <div className="col-12 text-center link" onClick={() => setShowLogin(true)}>
                                Ya soy usuario !
                            </div>
                            :
                            null
                    }

                    <div className="col-12">
                        <div id="scroll-city"></div>
                        <SelectCity
                            showModal = {showModalCity}
                            openModal = {openModalCity}
                            handleClose = {modelCityClose}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="menuSticky" >
                            <MenuApp />
                        </div>
                    </div>
                    <div className="col-md-9 col-sm-12">
                        <div className="mb-3">
                            <ItemHome
                                title="Vehículo"
                                content={showDataVehicle()}
                                onClick={() => go('/vehiculos')}
                            />
                        </div>
                        <div className="mb-3">
                            <ItemHome
                                title="Lavado"
                                content={showDataProduct()}
                                onClick={() => go('/listadoServicios')}
                            />
                        </div>
                        <div className="mb-3">
                            <ItemHome
                                title="Dirección"
                                content={showDataAddress()}
                                onClick={() => onClickAddress()}
                            />
                        </div>
                        <div className="mb-3">
                            <ItemHome
                                title="Fecha y Hora"
                                content={reservation.date ? 
                                    reservation.date : 'Selecciona una fecha' }
                                onClick={() => clicDate()}
                            />
                        </div>
                        <div className="mb-3">
                            <ItemHome
                                title="Forma de Pago"
                                content={showDataMethodPayment()}
                                onClick={() => onClickMethodPayment()}
                            />
                        </div>
                        
                        <div className="row mt-3">
                            <div className="col-12">
                                <div className="border-gray padding-20">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            Resumen Reserva
                                            <div className="hr"></div>
                                        </div>
                                        <div className="col-6 mb-1">
                                            Servicio
                                        </div>
                                        <div className="col-6 text-right">
                                            <b>{productStore.name}</b>
                                        </div>

                                        <div className="col-3 mb-1">
                                            Dirección
                                        </div>
                                        <div className="col-9 text-right">
                                            <b>{showDataAddress()}</b>
                                        </div>

                                        <div className="col-6 mb-1">
                                            Duración
                                        </div>
                                        <div className="col-6 text-right">
                                            <b>{formatMinutesToHours(productStore.duration)}</b>
                                        </div>

                                        <div className="col-6 mb-1">
                                            Valor Total
                                        </div>
                                        <div className="col-6 text-right">
                                            <b>{formatPrice(productStore.subtotalPrice, reservation.currency)}</b>
                                        </div>

                                        <div className="col-6 mb-1">
                                            Descuento
                                        </div>
                                        <div className="col-6 text-right">
                                           <b>{formatPrice(productStore.discount, reservation.currency)}</b>
                                        </div>

                                        <div className="col-7 mb-1 text-20">
                                            <b>VALOR A PAGAR</b>
                                        </div>
                                        <div className="col-5 text-right text-20">
                                            <b>{formatPrice(productStore.price, reservation.currency)}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className="row mt-3 mb-3">
                            <div className="col-12">
                                <button disabled={!buttonActive} className="btn btn-primary full-width" onClick={() => createReservation()}>
                                    RESERVAR
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <section className="section-2-home">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mt-3 text-center">
                            <h2>Formas de Pago</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 col-sm-2 offset-sm-1">
                            <LazyLoad
                                offsetVertical={300}
                                debounce={false}
                            >
                                <ImageLoader src={require("../../assets/img/payment/visa.png")} alt="logo-TC" className="full-width-image"/>
                            </LazyLoad>
                        </div>
                        <div className="col-4 col-sm-2">
                            <LazyLoad
                                offsetVertical={300}
                                debounce={false}
                            >
                                <ImageLoader src={require("../../assets/img/payment/mastercard.png")} alt="logo-TC" className="full-width-image"/>
                            </LazyLoad>
                        </div>
                        <div className="col-4 col-sm-2">
                            <LazyLoad
                                offsetVertical={300}
                                debounce={false}
                            >
                                <ImageLoader src={require("../../assets/img/payment/dinners.png")} alt="logo-TC" className="full-width-image"/>
                            </LazyLoad>
                        </div>
                        <div className="col-4 col-sm-2">
                            <LazyLoad
                                offsetVertical={300}
                                debounce={false}
                            >
                                <ImageLoader src={require("../../assets/img/payment/american.png")} alt="logo-TC" className="full-width-image"/>
                            </LazyLoad>
                        </div>
                        <div className="col-4 col-sm-2">
                            <LazyLoad
                                offsetVertical={300}
                                debounce={false}
                            >
                                <ImageLoader src={require("../../assets/img/payment/occidente.png")} alt="logo-TC" className="full-width-image"/>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
                <div className="space-20"></div>
            </section>
            <Footer/>
            {
                showModalDate ? 
                <ModalDate
                    showModalDate={showModalDate}
                    handleCloseDate={handleCloseDate}
                    reservation={{...reservation,product: {...productStore}}}
                    selectHour={selectHour}
                />
                : null 
            }
            {
                showFormAddress ?
                <FormAddress 
                    show={showFormAddress} 
                    handleClose={handleCloseFormAddress}
                    reservationStore={reservation}
                    userLogin={userLogin}
                    setReservation={setReservation}
                    addressStore={addressStore}
                    setAddressStore={setAddressStore}
                /> :null
            }
            {
                showModalMethodPayment ?
                <ModalMethodPayment 
                    show={showModalMethodPayment}
                    handleClose={handleCloseMethodPayment}
                    reservationInput={{...reservation,...addressStore}}
                    productInput={productStore}
                    userLogin={userLogin}
                    selectMethodPaymentOutput={selectMethodPayment}
                /> : null
            }
            {
                showModalVitalData ?
                    <ModalVitalData
                        show={showModalVitalData}
                        handleClose={handleCloseVitalData}
                        user={userLogin}
                    /> : null
            }
            {
                showLogin ?
                    <FormLogin
                        show={showLogin}
                        handleClose={handleClose}
                        register={registerUser}
                    />
                    : null
            }
            {
                showModalQualification && qualification ?
                    <ModalQualification
                        show={showModalQualification}
                        handleClose={handleCloseQualification}
                        data={qualification}
                        user={userLogin}
                    />
                    : null
            }
            {
                showModalAmountServices ?
                    <ModalAmountServices
                        show={showModalAmountServices}
                        handleClose={handleCloseAmountServices}
                        max={maxAmount}

                    />
                    : null
            }
            {
                showFullLoading ?
                    <FullLoading />
                    :
                    null
            }
            {
                showModalPSE ?
                    <ModalPSE
                        showModalPSE={showModalPSE}
                        handleCloseDate={handleClosePSE}
                        irPSE={irPSE}
                    />
                    : null
            }
        </div>
    );
};

export default Services;

