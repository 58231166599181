import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'

const ModalPSE = ({ showModalPSE, handleCloseModalPSE, irPSE }) => {

    const [showModal, setShowModalPSE] = useState(showModalPSE);

    const handleClosePSE = () => {
        setShowModalPSE(false);
    }



    return (
        <Modal show={showModal} onHide={handleClosePSE}>
            <Modal.Header closeButton>
                <Modal.Title> ACCEDER A PSE </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 text-center">
                        <button className="custom-map-button text-center p-0" onClick={() => irPSE()}>
                            <span >!CLICK AQUI¡ PARA IR A: </span>
                            <img src={require("../../assets/img/pse.png")} alt="en linea" />
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )

}

export default ModalPSE;