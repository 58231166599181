import { createStore } from 'redux';
//import thunk from 'redux-thunk';
import reducer from './reducers'
import { getStateStorage, setStateStorage } from './localstorage';

// Definir state inicial
//const initialState = [];

// get storage
const storageState = getStateStorage('user');

const store = createStore(
    reducer,
    storageState,
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(() => {
    // setStorage
    setStateStorage({
        user: {
            idUser: store.getState().user.idUser,
            token: store.getState().user.token
        }
    }, 'user');
})

export default store;
