const initialState = {
    vehicles: []
}
// Actualizar vehículos cuando no existan o cuando se modifiquen
export default function(state = initialState, action) {
    switch (action.type) {
        case 'SET_VEHICLES':
            return {
                ...state,
                vehicles: [...action.payload]
            };
        case 'DELETE_VEHICLES':
            return  {
                ...state,
                vehicles: []
            };
        default:
            return state;
    }
}