import React, { useState, useEffect } from 'react';
import MenuApp from '../../components/MenuApp/MenuApp'
import FormLogin from '../../components/FormLogin/FormLogin';
import clientAxios from '../../config/axios';
import {isCorrectAlphabetical, isCorrectEmail, isCorrectID, isEmpty,isNoSpaces} from '../../Helpers/ValidationsHelper'
import {alert} from "../../Helpers/Alert";

//material ui
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import OutlinedInput from "@material-ui/core/OutlinedInput";

// react bootstrap
import Button from 'react-bootstrap/Button'
import { useSelector,useDispatch } from 'react-redux';
import { setUserActions } from '../../actions/userActions';
import Swal from 'sweetalert2';
import SelectCity from "../../components/SelectCity/SelectCity";
import MenuAppMobile from "../../components/MenuAppMobile/MenuAppMobile";

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        width:'100%'
    },
}));
function Profile() {

    const stateInitial = {
        firstName: '',
        lastName: '',
        phone: '',
        identifier: '',
        country: '',
        city: '',
        email: '',
        ccTLD: '',
        franchise: ''
    }
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [isLogged, setIsLogged] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [form, setForm] = useState(stateInitial);
    const classes = useStyles();

    const handleSubmitForm = e => {
        e.preventDefault();
        // TODO hacer validaciones
        const {firstName,email,lastName,identifier}=form;
        if (!isCorrectEmail(email.trim())) {
            alert.fire(
                'Advertencia',
                'Debes ingresar un Email válido',
                'warning'
            );
            return
        }
        if (!isCorrectAlphabetical(firstName.trim())) {
            alert.fire(
                'Advertencia',
                'Debes ingresar un nombre válido',
                'warning'
            );
            return
        }
        if (!isCorrectAlphabetical(lastName.trim())) {
            alert.fire(
                'Advertencia',
                'Debes ingresar un apellido válido',
                'warning'
            );
            return
        }
        if (!isCorrectID(identifier.trim())) {
            alert.fire(
                'Advertencia',
                'Debes ingresar una cédula/Nit válida',
                'warning'
            );
            return
        }
        clientAxios.post('app/editarUsuario', {...userLogin,...form}).then( ({data }) => {
            if (data.response == 1) {
                setUser({
                    ...userLogin,
                    ...form
                    });
                alert.fire({
                    title: 'Operación Exitosa',
                    text: 'Datos actualizados con exito!',
                    icon: 'success'
                });
            } else if (data.response == 2) {
                alert.fire({
                    title: 'Avertencia',
                    text: 'No se han actualizado los datos del usuario.',
                    icon: 'warning'
                });
            }else if (data.response == 3) {
                alert.fire({
                    title: 'Avertencia',
                    text: 'La cédula que deseas ingresar ya se encuentra registrada.',
                    icon: 'warning'
                });
            }else if (data.response == 4) {
                alert.fire({
                    title: 'Avertencia',
                    text: 'El número de teléfono que deseas ingresar ya se encuentra registrado.',
                    icon: 'warning'
                });
            }else if (data.response == 5) {
                alert.fire({
                    title: 'Avertencia',
                    text: 'El email que deseas ingresar ya se encuentra registrado.',
                    icon: 'warning'
                });
            }
        }).catch(err => console.log('Se ha generado un error al intentar actualizar el cliente'))
    }

    const handleShow = () =>{
        setShowLogin(true);
    }

    const handleClose = value => {
        setIsLogged(value);
        setShowLogin(false);
    }

    const handleChangeForm = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
        if(e.target.name === 'country') {
            activateCity(e.target.value);
        }
    }

    const userLogin = useSelector( state => state.user);
    const dispatch = useDispatch();
    const setUser = user => dispatch( setUserActions(user));

    let firsLoad = false;
    const loadCountries = () => {
        clientAxios.get('app/todosPaises').then( ({data}) => {
            setCountries(data);
            firsLoad = true;
        }).catch( err => console.log('Se ha generado un error al intentar cargar los países'));
    }

    const activateCity = value => {
        const send = typeof value === 'undefined' ? form.country : value;
        if ( form.country !== '' || value ) {
            clientAxios.post('app/TodosCiudad', send).then(({data}) => {
                setCities(data);
                /*
                if (firsLoad) {
                    console.log(form);
                }*/
            }).catch(err => console.log('Se ha generado un error al intentar cargar las ciudades'));
        }
    }

    useEffect(() => {
        if (userLogin.idUser) {
            if (!userLogin.phone) {
                clientAxios.post('app/validarUsuarioToken',userLogin).then(res => {
                    if (res.data.response) {
                        const x = res.data;
                        setUser(x);
                        setTimeout(() => {
                            setForm({
                                firstName: x.firstName,
                                lastName: x.lastName,
                                phone: x.phone,
                                identifier: x.identifier,
                                country: x.country,
                                city: x.city,
                                email: x.email,
                                ccTLD: x.ccTLD,
                                franchise: x.franchise
                            });
                        },100)

                        loadCountries();
                        activateCity(x.country);
                        setIsLogged(true);
                    } else {
                        setUser(null);
                    }
                }).catch(err => console.log('error -> ',err));
            } else {
                setTimeout(() => {
                    setForm({
                        firstName: userLogin.firstName,
                        lastName: userLogin.lastName,
                        phone: userLogin.phone,
                        identifier: userLogin.identifier,
                        country: userLogin.country,
                        city: userLogin.city,
                        email: userLogin.email,
                        ccTLD: userLogin.ccTLD,
                        franchise: userLogin.franchise
                    });
                },100)

                loadCountries();
                activateCity(userLogin.country);
                setIsLogged(true);
            }
        }
    },[isLogged]);

    return (
        <div>
            <MenuAppMobile/>
            <div className="space-20"></div>
            <div className="container col-12">
                {/* <div className="row">
                    <div className="col-12">
                        <SelectCity/>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-md-3">
                        <div className="menuSticky" >
                            <MenuApp />
                        </div>
                    </div>
                    <div className="col-md-9 col-sm-12">
                        <div className="row">
                            { isLogged ? 
                            <form onSubmit={handleSubmitForm} className={classes.root} noValidate autoComplete="off">
                                <div className="row mb-3">
                                    <div className="col-12 text-center">
                                        <h2>Datos Personales</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-3">
                                        <TextField value={form.identifier} name="identifier" onChange={handleChangeForm} label="Cédula o Nit" variant="outlined" fullWidth={true} />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <TextField value={form.email} name="email" onChange={handleChangeForm} label="Email" variant="outlined" fullWidth={true} />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <TextField value={form.firstName} name="firstName" onChange={handleChangeForm} label="Nombre" variant="outlined" fullWidth={true} />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <TextField value={form.lastName} name="lastName" onChange={handleChangeForm} label="Apellido" variant="outlined" fullWidth={true} />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <TextField value={form.phone} name="phone" onChange={handleChangeForm} label="Teléfono" variant="outlined" fullWidth={true} />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <TextField value={form.franchise} name="franchise" onChange={handleChangeForm} label="Franquicia (opcional)" variant="outlined" fullWidth={true} />
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                            <InputLabel htmlFor="country">País</InputLabel>
                                            <Select
                                                native
                                                value={form.country}
                                                onChange={handleChangeForm}
                                                label="País"
                                                inputProps={{
                                                    name: 'country',
                                                    id: 'country',
                                                }}
                                            >
                                                {
                                                    countries.map(x => (
                                                        <option key={x.idpais} value={x.idpais} >{x.nombre}</option>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-12 col-md-6 mb-3">
                                        <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                            <InputLabel htmlFor="city">Ciudad</InputLabel>
                                            <Select
                                                native
                                                value={form.city}
                                                onChange={handleChangeForm}
                                                label="Ciudad"
                                                inputProps={{
                                                    name: 'city',
                                                    id: 'city',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                {
                                                    cities.map(x => (
                                                        <option key={x.idciudad} value={x.idciudad} >{x.nombre}</option>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mt-1">
                                        <div className="divider"></div>
                                        <button className="btn btn-primary full-width mt-3 mb-1">Actualizar Datos</button>
                                    </div>
                                </div>
                            </form>
                            : 
                            <div className="col-12 container">
                                <div className="space-20"></div>
                                <div className="card text-white bg-info mt-3 c-pointer" onClick={handleShow}>
                                    <div className="card-body text-center">
                                        <h4>Si deseas ver o editar tus datos debes iniciar sesión dando clic Aquí
                                        </h4>
                                    </div>
                                </div>
                                <FormLogin  show={showLogin} handleClose={handleClose}/>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    );
}

export default Profile;
