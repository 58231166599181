const initialState = {
    id: null,
    duration: null,
    durationTemp: null,
    name: null,
    subtotalPrice: null,
    discount: 0,
    price: null,
    points: null,
    amount: 1
}

// Actualizar cuando se cambie de ciudad o de vehículo
export default function(state = initialState, action) {
    switch (action.type) {
        case 'SET_PRODUCT':
            return {
                ...state,
                ...action.payload
            };
        case 'DELETE_PRODUCT':
            return  {
                ...state,
                id: null,
                duration: null,
                durationTemp: null,
                name: null,
                subtotalPrice: null,
                discount: 0,
                price: null,
                points: null,
                amount: 1
            };
        default:
            return state;
    }
}
