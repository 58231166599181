//FormLogin

import React, { Fragment, useState, useEffect } from 'react';
import clientAxios from '../../config/axios';
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux';
import { setUserActions } from '../../actions/userActions';
import Modal from 'react-bootstrap/Modal'
import './FormLogin.css';


import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { alert } from "../../Helpers/Alert";



const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        width:'100%'
    },
}));

const FormLogin = ({ show, handleClose, register }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const setUserStorage = userStorage => dispatch(setUserActions(userStorage));

    const countries = [
        {
            country: 1,
            code: '+57',
            logo: 'colombia.svg'
        },
        {
            country: 2,
            code: '+593',
            logo: 'ecuador.svg'
        }
        ,
        {
            country: 3,
            code: '+1',
        }];
    const initialFormLogin = {
        ...countries[0],
        mobile: '',
        validation: '',
        terms: false,
        email: '',
        password: '',
        tipoLogin: '',
        idFacebook: ''
    };


    const [dataFormLogin, setDataFormLogin] = useState(initialFormLogin);
    const [validate, setValidate] = useState(false);
    const [typeLogin, setTypeLogin] = useState(false);
    const [userTemp, setUserTemp] = useState({ code: null });
    const onChangeFromLogin = e => {
        if (e.target.name === 'terms' ) {
            setDataFormLogin({
                ...dataFormLogin,
                [e.target.name]: e.target.checked,
            });
        } else {
            let code = null
            if (e.target.name === 'country') {
                const  array = countries.filter(value=> value.country == e.target.value);
                code = array[0].code;
                console.log({code});
            }
            setDataFormLogin({
                ...dataFormLogin,
                [e.target.name]: e.target.value,
                code: code ? code : dataFormLogin.code
            });
            console.log(dataFormLogin);
        }
    };
    const loginSMS = () => {
        if (!dataFormLogin.terms) {
            alert.fire(
                'Advertencia',
                'Debes aceptar los términos y condiciones',
                'warning',
            );
            return
        }
        if (dataFormLogin.mobile === '') {
            alert.fire(
                'Advertencia',
                'Debes ingresar tu número teléfonico',
                'warning',
            );
            return
        }

        clientAxios.post('app/loginSMS', dataFormLogin).then(({ data }) => {
            console.log(data);
            if (data.response === 1) {
                setValidate(true);
                setTypeLogin(true);

            } else {
                clientAxios.post('app/registroSMS', dataFormLogin).then(({ data }) => {
                    console.log("REGISTRO SMS" + data);
                    if (data.response === 3) {
                        Swal.fire({
                            title: 'Advertencia',
                            text: 'El número de teléfono que ingresaste ya se encuentra registrado',
                            icon: 'warning',
                        })
                    } else if (data.idUser) {
                        setUserTemp({ ...data });
                        setValidate(true);
                        setTypeLogin(false);

                    }

                }).catch(err => console.log('err send message', err));

            }

        }).catch(err => console.log('err send message', err));
    };

    const validateCode = () => {
        if (dataFormLogin.validation === '') {
            Swal.fire({
                title: 'Advertencia',
                text: 'Debes ingresar el código de verificación',
                icon: 'warning',
            })
            return
        }

        const send = { ...dataFormLogin, ...userTemp }
        if(typeLogin){
            clientAxios.post('app/validaLoginSMS',send).then(({data})=> {
                if (data.response) {
                    clientAxios.post('app/validarUsuarioToken',data).then(res => {
                        if (res.data.response) {
                            Swal.fire({
                                title: 'Ingreso Exitoso',
                                icon: 'success',
                            })
                            setUserStorage(res.data);
                        } else {
                            setUserStorage(null);
                        }
                        handleClose(res.data.response);
                        //clean();
                    }).catch(err => console.log('error -> ',err));
                } else {
                    Swal.fire({
                        title: 'Advertencia',
                        text: 'El código que ingresaste no es válido',
                        icon: 'warning',
                    })
                }
    
            }).catch(err => console.log('eror al validar el código'));
            
        }else{
            clientAxios.post('app/crearUsuarioSMS', send).then(({ data }) => {
                if (data.response === 1) {
                    Swal.fire({
                        title: 'Registro Exitoso',
                        icon: 'success',
                    })
                    //el send mandarlo normal sin los destructuracion
                    clientAxios.post('app/validarUsuarioToken', { ...send }).then(res => {
                        if (res.data.response) {
                            //meter directamente en setUser 
                            //const x = res.data;
                            setUserStorage(res.data);
                        } else {
                            setUserStorage(null);
                        }
                        handleClose(res.data.response)
                        //clean();
                    }).catch(err => console.log('error -> ', err));
    
                } else {
                    Swal.fire({
                        title: 'Advertencia',
                        text: 'El código que ingresaste no es válido',
                        icon: 'warning',
                    })
                }
    
            }).catch(err => console.log('eror al validar el código'));
        }
        
    }

    useEffect(() => {
        if (dataFormLogin.tipoLogin !== '') {
            loginSMS();
        }
    }, [dataFormLogin]);

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header >
                    <Modal.Title>Validar numero</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card border-secondary mb-3">
                        <div className="card-body">
                           {!validate ? 
                        <Fragment>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <p className="title-register">¿Cúal es tu número teléfonico?</p>
                                        <p>Para continuar, te enviarémos un mensaje de texto para verificar tu número teléfonico.</p>
                                    </div>
                                    <div className="col-6 col-sm-4">
                                        <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                            <Select
                                                native
                                                value={dataFormLogin.country}
                                                onChange={onChangeFromLogin}
                                                inputProps={{
                                                    name: 'country',
                                                    id: 'country-1',
                                                }}
                                            >
                                                {countries.map(obj => (
                                                    <option key={obj.country} value={obj.country} >
                                                        {obj.code}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-6 col-sm-8">
                                        <TextField value={dataFormLogin.mobile} name="mobile" onChange={onChangeFromLogin} label="Teléfono" variant="outlined" fullWidth={true} />
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-4 text-center">
                                        <Checkbox
                                            checked={dataFormLogin.terms}
                                            name="terms"
                                            color="primary"
                                            onChange={onChangeFromLogin}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                        
                                    </div>
                                    <div className="col-8">
                                        Acepto {' '}
                                        <a target="_blank"
                                            href={require('../../assets/pdf/Proteccion-de-datos-Lavelo-Pues.pdf')}>
                                            las politicas, términos y condiciones</a>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <button className="btn btn-primary full-width" onClick={() => loginSMS()}>VALIDAR</button>
                                    </div>
                                </div>

                            </Fragment>
                        :
                        <Fragment>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <p className="title-register">¿Cúal es el código?</p>
                                        <p>
                                            Ingresa el código enviado a {' ' + dataFormLogin.code + ' ' + dataFormLogin.mobile}
                                        </p>
                                    </div>
                                    <div className="col-12">
                                        <TextField value={dataFormLogin.validation} name="validation" onChange={onChangeFromLogin} label="Código" variant="outlined" fullWidth={true} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <button className="btn btn-primary full-width" onClick={() => validateCode()}>Continuar</button>
                                    </div>
                                </div>
                            </Fragment>
                        }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>

    );
}

export default FormLogin;