export const getStateStorage = (item) => {
    const storage = localStorage.getItem(item);
    if (storage === null) {
        return undefined
    }
    return JSON.parse(storage);
}

export const setStateStorage = (state, item) => {
    const storage = JSON.stringify(state);
    localStorage.setItem(item,storage);
}