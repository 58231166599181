import { combineReducers } from 'redux';
import userReducer from './userReducer'
import productReducer from './productReducer'
import addressReducer from './addressReducer';
import reservationReducer from './reservationReducer';
import vehiclesReducer from './vehiclesReducer';

export default combineReducers({
    user: userReducer,
    product: productReducer,
    address: addressReducer,
    reservation: reservationReducer,
    vehicles: vehiclesReducer
});
