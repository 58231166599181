import React from 'react';
import './ItemHome.css'

function ItemHome({title,content,onClick}) {
    return (
        <div className="card item-home" onClick={onClick}>
            <div className="item-header">{title}<i className="icon-item-header fas fa-chevron-right"></i></div>
            <div className="padding-card">
                <p className="card-text text-center">{content}</p>
            </div>
        </div>
    );
}

export default ItemHome;