import React from 'react';
import './Vehicle.css';

const Vehicle = ({data,plaqueSelected, action, selectVehicle,id}) => {
    const check = 
        <label className="container-check">
            <input type="checkbox" checked={data.placa === plaqueSelected} onChange={() => selectVehicle(data)}/>
            <span className="checkmark"></span>
        </label>

    const header = data.placa !== 'V1' && data.placa !== 'V2' && data.placa !== 'V3' ? 
        <div className="card-header text-center">
            {check}
            {data.tipo}
        </div> : '';

    const body = data.placa !== 'V1' && data.placa !== 'V2' && data.placa !== 'V3' ? 
        <div className="text-left container">
            <b>Marca:</b> {data.marca} <br />
           <b>Placa:</b> {data.placa}
        </div> : 
        <div>
            {check}
            <h4 className="text-center">{data.placa !== 'V3'? data.tipo === 1 ? 'Automóvil (carro)' : 'Camioneta': data.tipo === 1 ? 'Automóvil' : 'JEEPETA'}</h4>
        </div>

    const footer = data.placa !== 'V1' && data.placa !== 'V2' && data.placa !== 'V3' ? 
        <div className="card-footer">
            <div className="row">
                <div className="col-6 text-center">
                    <button type="button" className="btn btn-outline-info" onClick={() => action(data,'edit')}>
                        <i className="fas fa-pen mr-1"></i>Editar
                    </button>
                </div>
                <div className="col-6 text-center">
                    <button type="button" className="btn btn-outline-danger ml-1" onClick={() => action(data, 'delete')}>
                        <i className="fas fa-trash-alt mr-1"></i>Eliminar
                    </button>
                </div>
            </div>
        </div> : ''
    return (
        <div className="card border-secondary">
            {header}
            <div className="card-body">
               {body}
            </div>
            {footer}
            
        </div>
    );
}

export default Vehicle;