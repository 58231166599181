export const eventFB = (event, payment) => {
    if (typeof window !== "undefined") {
        if (window.fbq != null) {
            if ( payment ) {
                window.fbq('track', event, payment);
            } else {
                window.fbq('track', event);
            }
        }
    }
}

export const eventG = data => {
    if (typeof window !== "undefined") {
        if (window.fbq != null) {
            window.gtag('config', 'AW-993947064')
            window.gtag('event', 'conversion', data);
        }
    }
}
