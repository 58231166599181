import React, {Fragment, useEffect, useState} from 'react';
import "./MenuAppMobile.css";
import Fab from "@material-ui/core/Fab";
import MenuApp from "../MenuApp/MenuApp";
import {useDispatch, useSelector} from "react-redux";
import {setReservationActions} from "../../actions/reservationActions";

const MenuAppMobile = () => {

    const dispatch = useDispatch();
    const reservation = useSelector( state => state.reservation.reservation );
    const [visible, setVisible] = useState(false);
    const setReservation = res => dispatch( setReservationActions(res));

    const showMenu = () => {
        setVisible(true)
    }

    const hideMenu = () => {
        setVisible(false)
    }
    useEffect(() => {
        if (reservation.openMenuMobile) {
            setVisible(true);
            setReservation({
                ...reservation,
                openMenuMobile: false,
            })
        }
    },[])

    return (
        <Fragment>
            <div className="icon-menu-mobile">
                <Fab color="primary" aria-label="add" onClick={() => showMenu()}>
                    <i className="fas fa-bars color-white"></i>
                </Fab>
            </div>
            <div className={visible ? "menu-mobile" : "menu-mobile-invisible"}>
                <div className="menu-left">
                    <Fab className="close-button-menu" color="primary" aria-label="add" onClick={() => hideMenu()}>
                        <i className="fas fa-times color-white"></i>
                    </Fab>
                    <MenuApp mobile={true}/>
                </div>
                <div className="menu-rigth" onClick={() => hideMenu()}></div>
            </div>
        </Fragment>
    );


}

export default MenuAppMobile;
