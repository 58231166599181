import React, {useState, useEffect, Fragment} from 'react';
import './Vehicles.css';
import MenuApp from '../../components/MenuApp/MenuApp'
import FormLogin from '../../components/FormLogin/FormLogin';
import { useSelector,useDispatch } from 'react-redux';
import { setUserActions } from '../../actions/userActions';
import { setReservationActions } from '../../actions/reservationActions';
import clientAxios from '../../config/axios';
import Vehicle from '../../components/Vehicle/Vehicle';
import FormVehicle from '../../components/FormVehicle/FormVehicle';
import Swal from 'sweetalert2/src/sweetalert2.js'
import {useHistory } from 'react-router-dom';

import Fab from '@material-ui/core/Fab';
import SelectCity from "../../components/SelectCity/SelectCity";
import MenuAppMobile from "../../components/MenuAppMobile/MenuAppMobile";

function Vehicles() {

    // Para actualizar el componente apenas inicie sesión
    const [isLogged, setIsLogged] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showModalVehicle, setShowModalVehicle] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [vehicle, setVehicle] = useState(null);
    const handleClose = value => {
        setIsLogged(value);
        setShowLogin(false);
    }
    let history = useHistory();
    const handleCloseModalVehicle = (response) => {
        setShowModalVehicle(false);
        if(response) {
            swalWithBootstrapButtons.fire(
                'Operación Exitosa',
                'El vehículo ha sido '+(vehicle && vehicle.mode === 'create' ? 'creado' : 'actualizado' )+' con exito.',
                'success'
            );
            loadVehicles();
        } else {
            swalWithBootstrapButtons.fire(
                'Advertencia',
                'No se ha '+(!vehicle  ? 'creado' : 'actualizado' )+' el vehículo, al parecer la placa ya se encuentra registrada.',
                'warning'
            )
        }
    } 
    const handleShow = () => setShowLogin(true);
    const handleShowModalVehicle = () => {
        setShowModalVehicle(true);
    }
    const userLogin = useSelector( state => state.user);
    const reservation = useSelector( state => state.reservation );
    const dispatch = useDispatch();
    const setUser = user => dispatch( setUserActions(user));
    const setReservation = res => dispatch( setReservationActions(res));

    const loadVehicles = async () => {
        if (isLogged) {
            clientAxios.post('app/vehiculosUsuario',userLogin).then(res => {
                if (res.data.response) {
                    setVehicles(res.data.data);
                }
            }).catch(err => {
                console.log("error generado al cargar la lista de vehículos",err);
            });
        }
    }
    const goHome = () => {
        setReservation({
            ...reservation.reservation,
            openMenuMobile: true,
        })
        history.push('/servicios')
    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-primary ml-2',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })
    
    useEffect(() => {
        console.log(reservation.reservation.country);
        if (userLogin.idUser) {
            if (!userLogin.phone) {
                clientAxios.post('app/validarUsuarioToken',userLogin).then(res => {
                    if (res.data.response) {
                        setUser(res.data);
                        setIsLogged(true);
                        loadVehicles();
                    } else {
                        setUser(null);
                    }
                }).catch(err => console.log('error -> ',err));
            } else {
                setIsLogged(true);
                loadVehicles();
            }
        }        
    },[isLogged]);

    const selectVehicle = vehicle => {
        setReservation({
            ...reservation.reservation,
            plaque: vehicle.placa,
            typeVehicle: vehicle.idVehiculo
        });
        if (reservation.reservation.goWashesAvailable) {
            history.push('/lavadosDisponibles');
        } else {
            history.push('/listadoServicios');
        }

    }
    const createVehicle = () => {
        setVehicle(null);
        handleShowModalVehicle();
    }
    const action = (vehicle, type) => {
        if ( type === 'edit' ) {
            handleShowModalVehicle();
            setVehicle(vehicle);
        } else {
            
            swalWithBootstrapButtons.fire({
                title: 'Eliminar Vehículo?',
                text: `Estás seguro que deseas eliminar el vehículo identificado con la placa ${vehicle.placa} ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Eliminar!',
                cancelButtonText: 'No, Cancelar!',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    clientAxios.post('app/EliminarVehiculo',{...userLogin,...vehicle}).then(({data}) => {
                        if (data.response === 1) {
                            swalWithBootstrapButtons.fire(
                                'Operación Exitosa',
                                'El vehículo ha sido eliminado con exito.',
                                'success'
                            )
                            loadVehicles();
                        } else if (data.response === 2) {
                            swalWithBootstrapButtons.fire(
                                'Advertencia',
                                'Para eliminar un vehículo debes tener dos o más vehiculos registrados.',
                                'warning'
                            )
                        }
                        
                    }).catch(err => console.err);
                    
                }
            })

        }
    }

    return (
        <div>
            <MenuAppMobile/>
            <div className="space-20"></div>
            <div className="container col-12">
                {/* <div className="row">
                    <div className="col-12">
                        <SelectCity/>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-md-3">
                        <div className="menuSticky" >
                            <MenuApp />
                        </div>
                    </div>
                    <div className="col-md-9 col-sm-12">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>Vehículos</h2>
                            </div>
                        </div>
                        <div className="row">
                            
                            <div className="col-12 col-md-6 mb-3"   >
                                <Vehicle 
                                    data={{tipo: 1, placa: 'V1',idVehiculo: 1}}
                                    plaqueSelected = {reservation.reservation.plaque}
                                    selectVehicle = {selectVehicle}
                                />
                            </div>

                            <div className="col-12 col-md-6 mb-3">
                                <Vehicle 
                                    data={{tipo: 2, placa:reservation.reservation.country == 3? 'V3': 'V2',idVehiculo: 2}}
                                    plaqueSelected = {reservation.reservation.plaque}
                                    selectVehicle = {selectVehicle}
                                />
                            </div>

                            {vehicles.map( (x, index) => (
                                <div className="col-12 col-md-6 mb-3" key={index}>
                                    <Vehicle 
                                        data={x}
                                        id= {index}
                                        plaqueSelected = {reservation.reservation.plaque}
                                        selectVehicle = {selectVehicle}
                                        action = {action}
                                    />
                                </div>
                            ))}
                        </div>
                        {!isLogged ? 
                            <Fragment>
                                <div className="space-10"></div>
                                <div className="row">
                                    <div className="col-12 container">
                                        <div className="divider"></div>
                                        <div className="card text-white bg-info mt-3 c-pointer" onClick={handleShow}>
                                            <div className="card-body text-center">
                                                <h4>Si ya eres cliente, Carga tu información aquí!
                                                </h4>
                                            </div>
                                        </div>
                                        <FormLogin  show={showLogin} handleClose={handleClose}/>
                                    </div>
                                    
                                </div>
                            </Fragment>
                            : ''}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center mb-3 mt-2">
                        <button className="btn btn-primary invisible-on-desktop" onClick={() => goHome()}>Ir al Inicio</button>
                    </div>
                </div>

                <div className="mucho"></div> 
            </div>
            {showModalVehicle ?
            <FormVehicle user={userLogin} vehicle={vehicle} show={showModalVehicle} handleClose={handleCloseModalVehicle}/>
             : ''}
            {
                isLogged ?
                    <div className="boton-flotante">
                        <Fab color="primary" aria-label="add" onClick={createVehicle}>
                            <i className="fas fa-plus color-white"></i>
                        </Fab>
                    </div> : null
            }

        </div>
    );
}

export default Vehicles;
