import React, { Fragment, useState, useEffect } from 'react';
import clientAxios from '../../config/axios';
import Modal from 'react-bootstrap/Modal'
import {alert} from "../../Helpers/Alert";

//material ui
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

// react bootstrap
import Button from 'react-bootstrap/Button'
import {useDispatch} from "react-redux";
import {setUserActions} from "../../actions/userActions";
import {isCorrectAlphabetical, isCorrectEmail, isCorrectID} from "../../Helpers/ValidationsHelper";

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const FormVitalData = ({ user ,show, handleClose }) => {

    const stateInitialForm = {
        identifier: '',
        name: '',
        lastName: '',
        email: ''
    };
    const [dataForm, setDataForm] = useState(stateInitialForm);
    const classes = useStyles();
    const dispatch = useDispatch();

    const setUser = user => dispatch( setUserActions(user));

    const handleChangeForm = e => {
        setDataForm({
            ...dataForm,
            [e.target.name]: e.target.value
        });
    }
    const handleSubmitForm = e => {
        e.preventDefault();

        if (!isCorrectEmail(dataForm.email.trim())) {
            alert.fire(
                'Advertencia',
                'Debes ingresar un Email válido',
                'warning'
            );
            return
        }
        if (!isCorrectAlphabetical(dataForm.name.trim())) {
            alert.fire(
                'Advertencia',
                'Debes ingresar un nombre válido',
                'warning'
            );
            return
        }
        if (!isCorrectAlphabetical(dataForm.lastName.trim())) {
            alert.fire(
                'Advertencia',
                'Debes ingresar un apellido válido',
                'warning'
            );
            return
        }
        if (!isCorrectID(dataForm.identifier.trim())) {
            alert.fire(
                'Advertencia',
                'Debes ingresar una cédula/Nit válida',
                'warning'
            );
            return
        }

        const send = {
            idUser: user.idUser,
            token: user.token,
            email: dataForm.email.trim(),
            ...dataForm,
        };

        clientAxios.post('app/actualizarDatosVitales', send).then(({ data }) => {
            if (data.response && data.response != 2 && data.response != 3) {
                setUser({
                    ...user,
                    identifier: dataForm.identifier,
                    firstName: dataForm.name,
                    lastName: dataForm.lastName,
                    email: dataForm.email,
                })
                handleClose(true);
            } else if (data.response == 2) {
                alert.fire(
                    'Advertencia',
                    'El email que deseas ingresar ya se encuentra registrado',
                    'warning'
                );
            } else if (data.response == 3) {
                alert.fire(
                    'Advertencia',
                    'La cédula o Nit que deseas ingresar ya se encuentra registrado',
                    'warning'
                );
            }
        }).catch(err => console.log('se ha generado un error'));
    }

    useEffect(() => {

    }, []);


    return (
        <Fragment>
            <Modal show={show} onHide={() => handleClose(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Datos Personales</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmitForm} className={classes.root} noValidate autoComplete="off">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h4>Solo por esta vez, cuéntanos cómo te llamas.</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <TextField value={dataForm.identifier} name="identifier" onChange={handleChangeForm} label="Cédula o Nit" variant="outlined" fullWidth={true} />
                            </div>
                            <div className="col-6">
                                <TextField value={dataForm.email} name="email" onChange={handleChangeForm} label="Email" variant="outlined" fullWidth={true} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <TextField value={dataForm.name} name="name" onChange={handleChangeForm} label="Nombre" variant="outlined" fullWidth={true} />
                            </div>
                            <div className="col-6">
                                <TextField value={dataForm.lastName} name="lastName" onChange={handleChangeForm} label="Apellido" variant="outlined" fullWidth={true} />
                            </div>
                        </div>
                        <div className="row">
                            <Button variant="primary" type="submit" className="full-width mt-2" >Continuar</Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
}

export default FormVitalData;
