import React, {useState, useEffect, Fragment} from 'react';
import './Products.css';
import MenuApp from '../../components/MenuApp/MenuApp'
import { useSelector,useDispatch } from 'react-redux';
import { setUserActions } from '../../actions/userActions';
import { setReservationActions } from '../../actions/reservationActions';
import { setProductAcions } from '../../actions/productsActions';
import clientAxios from '../../config/axios';
import {formatMinutesToHours} from '../../Helpers/HoursHelper';
import {formatPrice} from '../../Helpers/PriceHelper';
import {useHistory } from 'react-router-dom';
import DetailProduct from  '../../components/DetailProduct/DetailProduct';
import MenuAppMobile from "../../components/MenuAppMobile/MenuAppMobile";
import {eventFB, eventG} from "../../Helpers/Events";

function AdditionalProducts() {

    const baseUrlImg = 'https://lavelopues.co/lavelopuesAppImg/productos/';
    // Para actualizar el componente apenas inicie sesión
    const [currency, setCurrency] = useState('');
    const [isLogged, setIsLogged] = useState(false);
    var [listProducts, setListProducts] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [productTemp, setProductTemp] =  useState(null);
    const handleCloseDetail = () => {
        setShowDetail(false);
    }
    let history = useHistory();
    const userLogin = useSelector( state => state.user);
    const reservation = useSelector( state => state.reservation );
    const productStore = useSelector( state => state.product)
    const dispatch = useDispatch();
    const setUser = user => dispatch( setUserActions(user));
    const setReservation = res => dispatch( setReservationActions(res));
    const setProductStore = res => dispatch( setProductAcions(res));
     
     listProducts =  listProducts.filter(product => {
        const value = !product.nombre.includes("Mensual");
        return value;
     }) ;
    const showModalDetail = product => {
        setProductTemp(product);
        setShowDetail(true);
    }

    const loadProducts = () => {
        const send = {
            token: userLogin.token,
            idUsuario: userLogin.idUser,
            vehicle: reservation.reservation.typeVehicle,
            type: 1,
            city: reservation.reservation.city,
        }
        clientAxios.post('shared/ProductosAdicionalesPlan', send).then(({data}) => {
            setListProducts(data.products);
            setCurrency(data.currency);
        }).catch( err => console.log('Se ha generado un error al cargar los productos'));
    }

    const selectProduct = product => {
        setReservation({
            ...reservation.reservation,
            date: null,
            dateTemp: 1,
            currency
        });
        setProductStore({
            id: product.idproducto,
            duration: product.duracion,
            durationTemp: product.duracion,
            name: product.nombre,
            points: product.puntos,
            price: product.precio,
            subtotalPrice: product.precio,
            plan: product.plan,
            discount: 0,
            amount: 1
        })
        eventFB('AddToCart')
        eventG({
            'send_to': 'AW-993947064/Og6VCI-0sbsBELjb-dkD',
            'event_callback': null
        })
        setShowDetail(false);
        history.push('/servicios');
    }
    
    const verifyCheck = product => {
        return productStore.id === product.idproducto;
    }
    
    useEffect(() => {
        if (userLogin.idUser) {
            if (!userLogin.phone) {
                clientAxios.post('app/validarUsuarioToken',userLogin).then(res => {
                    if (res.data.response) {
                        setUser(res.data);
                        setIsLogged(true);
                        loadProducts(); // TODO puede que haya que validar enviandole por param res.data
                    } else {
                        setUser(null);
                    }
                    loadProducts();
                }).catch(err => console.log('error -> ',err));
            } else {
                setIsLogged(true);
                loadProducts();
            }
        } else {
            loadProducts();
        }
    },[isLogged]);
  
    
    return (
        <div>
            <MenuAppMobile/>
            <div className="space-20"></div>
            <div className="container col-12">
                <div className="row">
                    <div className="col-md-3">
                        <div className="menuSticky" >
                            <MenuApp />
                        </div>
                    </div>
                    <div className="col-md-9 col-sm-12">
                        <div className="row">
                        <div className="col-12 text-center">
                              <h2>Servicios Adicioneles</h2> 
                        </div>
                            {
                                listProducts.map((x, index) => (
                                    <div className="col-12 col-md-4 mb-2" key={index}>  
                                        <div className="card border-secondary">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <img className="img-product" src={baseUrlImg + x.imagen} alt={x.nombre} />
                                                    </div>
                                                    <div className="col-6">
                                                        <span className="title-product">{x.nombre}</span> <br/>
                                                        Duración: {formatMinutesToHours(x.duracion) }<br/>
                                                        Precio: {formatPrice(x.precio)}
                                                    </div>
                                                    <div className="check-product full-width">
                                                        <label className="container-check">
                                                            <input type="checkbox" checked={verifyCheck(x)}  
                                                                onChange={() => selectProduct(x)}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer footer-no-background">
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <button 
                                                            type="button" 
                                                            className="btn btn-outline-info"
                                                            onClick={() => showModalDetail(x)}
                                                        >
                                                            <i className="fas fa-clipboard-list mr-2"></i>Ver detalles
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="mucho"></div> 
            </div>
            {
                showDetail && productTemp ? 
                <DetailProduct 
                    data={productTemp}
                    show={showDetail} 
                    selectProduct={selectProduct}
                    baseUrlImg={baseUrlImg} 
                    handleClose={handleCloseDetail}
                />
                : ''
            }
            
        </div>
    );
}

export default AdditionalProducts;
