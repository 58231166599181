import React, {useEffect, useState} from 'react';
import logo from "../../assets/img/logo-inicio-blanco.png";
import '../../global.css';
import Carousel from 'react-bootstrap/Carousel'
import LazyLoad from 'react-lazy-load';
import ImageLoader from "../../components/ImageLoader/ImageLoader";
import Footer from "../../components/Footer/Footer";
import {useHistory} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import {useSelector} from "react-redux";
import SelectCity from "../../components/SelectCity/SelectCity";
import {makeStyles} from "@material-ui/core/styles";
import Testimony from "../../components/Testimony/Testimony";
import clientAxios from "../../config/axios";
import {alert} from "../../Helpers/Alert";
import CarouselClients from "../../components/CarouselClients/CarouselClients";

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        width:'100%'
    },
}));

function Enterprises() {
    let history = useHistory();
    const classes = useStyles();
    const initialState = {
        fullName: '',
        enterprise: '',
        phone: '',
        email: '',
    }
    const testimonials = [
        {
            img: require('../../assets/img/testimonials/novaventa.png'),
            text: 'Excelente aliada para mantener nuestra flota impecable',
            user: 'Novaventa'
        },
        {
            img: require('../../assets/img/testimonials/hyundai.png'),
            text: 'Nuestra operacion ha mejorado desde que contamos con Lavelo Pues',
            user: 'Hiunday Autama'
        },
        {
            img: require('../../assets/img/testimonials/ara.png'),
            text: 'Cumplimiento y responsabilidad en el trabajo realizado',
            user: 'Jeronimo Martins'
        },
    ]
    const [form, setForm] = useState(initialState);
    const [isMobile, setIsMobile] = useState(false);
    const [measurements, setMeasurements] = useState({w: 560, h: 315});
    const reservation = useSelector( state => state.reservation.reservation);
    const [showModalCity, setShowModalCity] = useState(false);
    const go = route => {
        history.push(route);
    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    const handleChangeForm = e => {
        setForm({
            ...form,
            [e.target.name] : e.target.value
        });
    }
    const handleSumbmit = e => {
        e.preventDefault();

        if (form.email === '' || form.phone === '' || form.enterprise === '' || form.fullName === '') {
            return;
        }
        const send = {
            ...form,
            city: reservation.city,
        }
        clientAxios.post('public/emailEmpresa.php',send).then(({data}) => {
            if (data.response) {
                alert.fire('Operación Exitosa','La información ha sido enviada con éxito!','success');
            }
        }).catch(err =>  console.log('Se ha generado un error al intentar enviar la información'))
    }

    const modelCityClose = () => {
        setShowModalCity(false);
    }

    const openModalCity = () => {
        setShowModalCity(true);
    }
    useEffect(() => {
        setIsMobile(window.screen.width <= 768);
        if (window.screen.width < measurements.w ) {
            setMeasurements({
                w: window.screen.width - 30,
                y: (window.screen.width - 30) * 0.578
            });
        }
    },[isMobile]);
    return (
        <div>
            <div className="space-20"></div>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="custom-card custom-card-blue">
                                <div className="row">
                                    <div className="col-6 col-md-8 text-left">
                                        <p>VIVE FELIZ CON TU CARRO LIMPIO</p>
                                        <p>NO ESPERAS</p>
                                        <p>NO TRANCONES</p>
                                        <p>+ TIEMPO PARA TI</p>
                                    </div>
                                    <div className="col-6 col-md-4 text-right">
                                        <LazyLoad
                                            offsetVertical={300}
                                            debounce={false}
                                        >
                                            <ImageLoader src={logo} className="full-height-image logo-home" alt="logo" />
                                        </LazyLoad>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-3">
                    <CarouselClients />
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h5>Solicita información de Lavelo Pues para tu negocio</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <SelectCity
                                 showModal = {showModalCity}
                                 openModal = {openModalCity}
                                 handleClose = {modelCityClose}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="space-10"></div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="custom-card mb-3">
                                <form onSubmit={handleSumbmit} className={classes.root} noValidate autoComplete="off">
                                    <div className="row">
                                        <div className="col-12">
                                            <TextField value={form.fullName} name="fullName" onChange={handleChangeForm} label="Nombre y Apellido" variant="outlined" fullWidth={true} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <TextField value={form.enterprise} name="enterprise" onChange={handleChangeForm} label="Empresa" variant="outlined" fullWidth={true} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <TextField value={form.phone} name="phone" onChange={handleChangeForm} label="Teléfono" variant="outlined" fullWidth={true} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <TextField value={form.email} name="email" onChange={handleChangeForm} label="Correo" variant="outlined" fullWidth={true} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <button className="btn btn-primary" type="submit">Enviar</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="space-20"></div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="row">
                                {
                                    testimonials.map((x,index) => (

                                        <div className="col-4 col-md-12" key={index}>
                                            <Testimony
                                                mode={isMobile ?  'V' : 'H'}
                                                img={x.img}
                                                heightImg={80}
                                                text={x.text}
                                                user={x.user}
                                                isMobile={isMobile}
                                            />
                                        </div>

                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container text-center mt-3 mb-2">
                    <h3>¿Por qué somos tu mejor aliado?</h3>
                </div>
                <div className="container text-center">
                    <iframe width={measurements.w} height={measurements.h} src="https://www.youtube.com/embed/RztACPxuXYI"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>
            </section>
            <Footer/>

        </div>
    );
}

export default Enterprises;
