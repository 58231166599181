import React, { Fragment, useState, useEffect } from 'react';
import clientAxios from '../../config/axios';
import { useSelector,useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import {eventFB, eventG} from '../../Helpers/Events'
import {getUrlCoordinates, getUrlUbication} from '../../Helpers/UbicationHelper'
import  {alert} from "../../Helpers/Alert";
//material ui
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import OutlinedInput from "@material-ui/core/OutlinedInput";

//SweetAlert2
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// react bootstrap
import Button from 'react-bootstrap/Button'
import {isEmpty} from "../../Helpers/ValidationsHelper";

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const FormAddress = ({ 
    address, 
    userLogin, 
    show, 
    handleClose,
    reservationStore,
    addressStore,
    setAddressStore}) => {
    
    var reservation = useSelector( state => state.reservation.reservation );  
    const MySwal = withReactContent(Swal);
    const stateInitialForm = {
        name: '',
        nameTemp: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        address5: '',
        idAddress: '',
        mode: 'create'
    };
    const [dataForm, setDataForm] = useState(stateInitialForm);
    const [typeForm, setTypeForm] = useState(1);
    const [showModalName, setShowModalName] = useState(false);
    const [isbuilding, setIsbuilding]=useState(false);
    var array = [
        {
          value: 'Calle',
          name: 'Calle'
        }, {
          value: 'Carrera',
          name: 'Carrera'
        }, {
          value: 'Avenida',
          name: 'Avenida'
        }, {
          value: 'Avenida Carrera',
          name: 'Avenida Carrera'
        }, {
          value: 'Avenida Calle',
          name: 'Avenida Calle'
        }, {
          value: 'Circular',
          name: 'Circular'
        }, {
          value: 'Circunvalar',
          name: 'Circunvalar'
        }, {
          value: 'Diagonal',
          name: 'Diagonal'
        }, {
          value: 'Manzana',
          name: 'Manzana'
        }, {
          value: 'Transversal',
          name: 'Transversal'
        },
        {
            value: 'Vía',
            name: 'Vía'
        }, {
            value: 'Vereda',
            name: 'Vereda'
        }
      ];
      var sectores = [
        { value: "Arroyo Hondo", name: "Arroyo Hondo" },
        { value: "Altos de Arroyo Hondo", name: "Altos de Arroyo Hondo" },
        { value: "Cerros de Arroyo Hondo", name: "Cerros de Arroyo Hondo" },
        { value: "Viejo Arroyo Hondo", name: "Viejo Arroyo Hondo" },
        { value: "Nuevo Arroyo Hondo", name: "Nuevo Arroyo Hondo" },
        { value: "Bella Vista", name: "Bella Vista" },
        { value: "Evaristo Morales", name: "Evaristo Morales" },
        { value: "El Millón", name: "El Millón" },
        { value: "Naco", name: "Naco" },
        { value: "Quisqueya", name: "Quisqueya" },
        { value: "Gazcue", name: "Gazcue" },
        { value: "Jardín Botánico", name: "Jardín Botánico" },
        { value: "Jardines del Sur", name: "Jardines del Sur" },
        { value: "Julieta", name: "Julieta" },
        { value: "La Castellana", name: "La Castellana" },
        { value: "La Esperilla", name: "La Esperilla" },
        { value: "La Julia", name: "La Julia" },
        { value: "Las Praderas", name: "Las Praderas" },
        { value: "Los Cacicazgos", name: "Los Cacicazgos" },
        { value: "Los Jardines", name: "Los Jardines" },
        { value: "Los Prados", name: "Los Prados" },
        { value: "Los Restauradores", name: "Los Restauradores" },
        { value: "Los Ríos", name: "Los Ríos" },
        { value: "Mirador Norte", name: "Mirador Norte" },
        { value: "Mirador Sur", name: "Mirador Sur" },
        { value: "Miraflores", name: "Miraflores" },
        { value: "Paraíso", name: "Paraíso" },
        { value: "Piantini", name: "Piantini" },
        { value: "Los Próceres", name: "Los Próceres" },
        { value: "Renacimiento", name: "Renacimiento" },
        { value: "San Geronimo", name: "San Geronimo" },
      ];
    const [addresses, setAddresses] = useState(array);
    const classes = useStyles();

    const handleCloseModalName = () => {
        setShowModalName(false);
    }
    const handleChangeForm = e => {
        setDataForm({
            ...dataForm,
            [e.target.name]: e.target.value
        });
    }
    const handleSubmitForm = e => {
        e.preventDefault();
        if(isbuilding){
            if (isEmpty(dataForm,['name','address1','address2','address3','address4','address5'])) {
                alert.fire(
                    'Advertencia',
                        'Debes completar todos los campos obligatorios',
                        'warning'
                    );
                    return;
             }  
        }else{
            if (isEmpty(dataForm,['name','address1','address2','address3','address4'])) {
                alert.fire(
                    'Advertencia',
                        'Debes completar todos los campos obligatorios',
                        'warning'
                    );
                    return;
             }
        }
         
         
        let dataUbication = {
           ...dataForm,
            cityName: reservationStore.cityName,
            ccTLD: reservationStore.ccTLD,
            city: reservationStore.city,
        };
        clientAxios.get(getUrlCoordinates(dataUbication)).then(({data}) => {

            if (data.status==='OK') {

                dataUbication.lat = data.results[0].geometry.location.lat;
                dataUbication.lng = data.results[0].geometry.location.lng;
                // Login ? Crear o actualizar : "guardar" en la reserva 
                if (userLogin.phone) {
                    dataUbication = {
                        ...dataUbication,
                        idUsuario: userLogin.idUser,
                        token: userLogin.token
                    }
                    if (dataForm.mode === 'create') {
                        clientAxios.post('app/CrearDireccion',dataUbication).then(({data}) => {
                            if (data.response == 1) {
                                alert.fire(
                                    'Operación Exitosa',
                                    'Dirección creada con exito',
                                    'success'
                                );
                                eventFB('FindLocation')
                                eventG({
                                    'send_to': 'AW-993947064/0N5fCMj9y7sBELjb-dkD',
                                    'event_callback': null
                                })
                                handleClose(true);
                            } else {
                                alert.fire(
                                    'Advertencia',
                                    'No se ha creado la dirección',
                                    'warning'
                                );
                                handleClose(false);
                            }
                        }).catch(err => console.log('Se ha generado un error al crear una direccion'));
                     } else if (dataForm.mode === 'update') {
                        clientAxios.post('app/ActualizarDireccion',dataUbication).then(({data}) => {
                            if (data.response == 1) {
                                alert.fire(
                                    'Operación Exitosa',
                                    'Dirección actualizada con exito',
                                    'success'
                                );
                                handleClose(true);
                            } else {
                                alert.fire(
                                    'Advertencia',
                                    'No se ha actualizado la dirección',
                                    'warning'
                                );
                                handleClose(false);
                            }
                        }).catch(err => console.log('Se ha generado un error al actualizar una direccion'));
                     }
                } else {

                    setAddressStore({
                        ...addressStore,
                        addressTemp: dataUbication
                    });
                    handleClose(true);
                }
                
            } else {
                alert.fire(
                    'Dirección Inválida',
                    'Ingresa una dirección válida para continuar',
                    'error'
                );
            }
        });
    }

    const changeNameTemp = () => {
        if (dataForm.nameTemp !== '') {
            setDataForm({
                ...dataForm,
                name: dataForm.nameTemp
            });
        }
        handleCloseModalName();
    }

    const selectNameAddress = name => {
        let nameTemp = dataForm.name;
        //SI ES EDIFICIO?
        if(name==='Edificio'){
            setIsbuilding(true);
        }else{
            setIsbuilding(false);
        }

        if (name) {
            setDataForm({
                ...dataForm,
                name
            });
        } else {
            if (nameTemp === 'Casa' || nameTemp === 'Oficina'|| nameTemp==='Edificio') {
                nameTemp = '';
            }
            setDataForm({
                ...dataForm,
                nameTemp
            });
            setShowModalName(true);
              
        } 
    }

    const getColor = name => {
        let color = 'default';
        if (name) {
            if (dataForm.name === name)  {
                color =  'primary'
            }
        } else if ( dataForm.name != '' && dataForm.name != 'Casa' && dataForm.name != 'Oficina'&& dataForm.name !='Edificio' ) {
            color =  'primary' 
        }
        return color;
    } 

    const getNameTag = () => {
        let name
        if ( dataForm.name != '' && dataForm.name != 'Casa' && dataForm.name != 'Oficina'&& dataForm.name !='Edificio') {
            name = dataForm.name.length > 10 ? (dataForm.name.substr(0,10) + ' . . .') : dataForm.name;
        } else {
            name = 'Otro'
        }
        return name
    }

    useEffect(() => {
        if (address) {
            setDataForm({
                name: address.nombre,
                nameTemp: '',
                address1: address.direccion,
                address2: address.calle,
                address3: address.carrera1,
                address4: address.carrera2,
                address5: address.observacion,
                idAddress: address.iddireccion,
                mode: 'update'
            });
        }
    }, []);


    return (
        <Fragment>
            <Modal show={show} onHide={() => handleClose(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Dirección</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmitForm} className={classes.root} noValidate autoComplete="off">
                        <div className="row">
                            <div className={typeForm === 1 ?  'col-6' : 'col-12'}>
                                <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Dirección</InputLabel>
                                    <Select
                                        native
                                        value={dataForm.address1}
                                        onChange={handleChangeForm}
                                        label="Dirección"
                                        inputProps={{
                                            name: 'address1',
                                            id: 'outlined-age-native-simple',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {
                                            addresses.map(x => (
                                                <option key={x.value} value={x.value} >{x.name}</option>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            {
                                dataForm.address1 !== '' ?
                                <div className="col-6">
                                    <TextField value={dataForm.address2} name="address2" onChange={handleChangeForm}  variant="outlined" fullWidth={true} />
                                </div>
                                : null 
                            }
                        </div> 
                        {
                            dataForm.address1 !== '' ?
                           <Fragment>
                                <div className="row">
                                    <div className="col-6">
                                        <TextField 
                                            value={dataForm.address3} 
                                            name="address3" 
                                            onChange={handleChangeForm}  
                                            variant="outlined" 
                                            fullWidth={true} 
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">#</InputAdornment>,
                                            }}
                                        />
                                    </div>
                                    {reservation.country == 3?
                                    <div className='col-6'>
                                    <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-native-simple">Sector</InputLabel>
                                        <Select
                                            native
                                            value={dataForm.address4}
                                            onChange={handleChangeForm}
                                            label="Sector"
                                            inputProps={{
                                                name: 'address4',
                                                id: 'outlined-age-native-simple',
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            {
                                                sectores.map(x => (
                                                    <option key={x.value} value={x.value} >{x.name}</option>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                    :
                                    <div className="col-6">
                                        <TextField 
                                            value={dataForm.address4} 
                                            name="address4" 
                                            onChange={handleChangeForm}  
                                            variant="outlined" 
                                            fullWidth={true} 
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">-</InputAdornment>,
                                            }}
                                        />
                                    </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {
                                            isbuilding?
                                            <TextField value={dataForm.address5} name="address5" onChange={handleChangeForm} label="Edificio y apartamento " required variant="outlined" fullWidth={true} /> 
                                            :
                                            <TextField value={dataForm.address5} name="address5" onChange={handleChangeForm} label="Observaciones(Opcionales)"  variant="outlined" fullWidth={true} />
                                        }
                                        
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h4>Residencia: </h4>
                                    </div>
                                    <div className="col-12">
                                        <Chip variant="outlined" 
                                            onClick={() => selectNameAddress('Casa')} 
                                            color={getColor('Casa')} 
                                            label="Casa"
                                            avatar={<Avatar>
                                                <i className="fas fa-home color-white"></i>
                                            </Avatar>} 
                                        />
                                        <Chip variant="outlined" 
                                            onClick={() => selectNameAddress('Oficina')} 
                                            color={getColor('Oficina')} 
                                            label="Oficina"
                                            avatar={<Avatar>
                                                <i className="fas fa-briefcase color-white"></i>
                                            </Avatar>} 
                                        />
                                         <Chip variant="outlined" 
                                            onClick={() => selectNameAddress('Edificio')} 
                                            color={getColor('Edificio')} 
                                            label="Edificio"
                                            avatar={<Avatar>
                                                <i className="fas fa-building color-white"></i>
                                            </Avatar>} 
                                        />
                                        <Chip variant="outlined" 
                                            onClick={() => selectNameAddress()} 
                                            color={getColor()} 
                                            label={getNameTag()}
                                            avatar={<Avatar>
                                                <i className="fas fa-map-marker-alt color-white"></i>
                                            </Avatar>} 
                                        />
                                    </div>
                                </div>
                                
                            </Fragment>
                            :null
                        }
                        <div className="row">
                                    <Button variant="primary" type="submit" className="full-width mt-2">{dataForm.mode === 'create' ? 'Crear' : 'Actualizar'}</Button>
                                </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal 
                show={showModalName} 
                onHide={() => handleCloseModalName(false)}
                size="sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Nombre del tag</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 text-center">
                            <p>Ingresa el nombre del tag de esta dirección</p>
                        </div>
                        <div className="col-12">
                            <TextField 
                                value={dataForm.nameTemp} 
                                name="nameTemp" 
                                onChange={handleChangeForm}  
                                variant="outlined" 
                                fullWidth={true} 
                            />
                        </div>
                        <div className="col-12">
                            <Button 
                                variant="primary" 
                                type="submit" 
                                className="full-width mt-2"
                                onClick={() => changeNameTemp()}
                            >Aceptar</Button>
                        </div>
                    </div>
                   
                   
                </Modal.Body>
            </Modal>
        </Fragment>
    );
}

export default FormAddress;
