import React, { Fragment, useState, useEffect } from 'react';
import clientAxios from '../../config/axios';
import Modal from 'react-bootstrap/Modal'

//material ui
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import OutlinedInput from "@material-ui/core/OutlinedInput";

// react bootstrap
import Button from 'react-bootstrap/Button'
import {isEmpty} from "../../Helpers/ValidationsHelper";
import {alert} from "../../Helpers/Alert";

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const FormVehicle = ({ vehicle, user, show, handleClose }) => {

    const stateInitialForm = {
        placaOriginal: '',
        placa: '',
        idMarca: '',
        idVehiculo: '',
        mode: 'create'
    };
    const [dataForm, setDataForm] = useState(stateInitialForm);
    const [brands, setBrands] = useState([]);
    const [types, setTypes] = useState([]);
    const classes = useStyles();

    const handleChangeForm = e => {
        setDataForm({
            ...dataForm,
            [e.target.name]: e.target.value
        });
    }
    const handleSubmitForm = e => {
        e.preventDefault();

        if (isEmpty(dataForm,['placa','idMarca','idVehiculo'])) {
            alert.fire(
                'Advertencia',
                'Debes completar todos los campos obligatorios',
                'warning'
            );
            return;
        }

        const send = {
            idUsuario: user.idUser,
            token: user.token,
            plate: dataForm.placa,
            plateOld: dataForm.placaOriginal,
            brand: dataForm.idMarca,
            typeVehicle: dataForm.idVehiculo,
        };
        if (dataForm.mode === 'create') {
            clientAxios.post('shared/CrearVehiculo', send).then(({ data }) => {
                if (data.response===1) {
                    handleClose(true);
                } else {
                    handleClose(false);
                }
            }).catch(err => console.log('se ha generado un error'));
        } else {
            clientAxios.post('shared/ActualizarVehiculo', send).then(({ data }) => {
                if (data.response===1) {
                    handleClose(true);
                } else {
                    handleClose(false);
                }
            }).catch(err => console.log('se ha generado un error'));
        }
    }

    useEffect(() => {
        if (vehicle) {
            setTimeout(() => {
                setDataForm({
                    ...vehicle,
                    placaOriginal: vehicle.placa
                });
            })
        }
        clientAxios.get('app/TodosVehiculos').then(({ data }) => {
            setTypes(data);
        }).catch(err => console.log('error al cargar tipos de vehículos', err));
        clientAxios.get('app/TodosMarcas').then(({ data }) => {
            setBrands(data);
        }).catch(err => console.log('error al cargar las marcas'));
    }, []);


    return (
        <Fragment>
            <Modal show={show} onHide={() => handleClose(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Vehículo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmitForm} className={classes.root} noValidate autoComplete="off">
                        <div className="row">
                            <TextField value={dataForm.placa} name="placa" onChange={handleChangeForm} label="Placa" variant="outlined" fullWidth={true} />
                        </div>
                        <div className="row">
                            <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                <InputLabel htmlFor="idVehiculo">Tipo</InputLabel>
                                <Select
                                    native
                                    value={dataForm.idVehiculo}
                                    onChange={handleChangeForm}
                                    label="Tipo"
                                    inputProps={{
                                        name: 'idVehiculo',
                                        id: 'idVehiculo',
                                    }}
                                >
                                    {
                                        types.map(x => (
                                            <option key={x.idvehiculo} value={x.idvehiculo} >{x.nombre}</option>
                                        ))
                                    }
                                </Select>
                            </FormControl>

                        </div>
                        <div className="row">
                            <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                <InputLabel htmlFor="idMarca">Marca</InputLabel>
                                <Select
                                    native
                                    value={dataForm.idMarca}
                                    onChange={handleChangeForm}
                                    label="Marca"
                                    inputProps={{
                                        name: 'idMarca',
                                        id: 'idMarca',
                                    }}
                                >
                                    {
                                        brands.map(x => (
                                            <option key={x.idmarca} value={x.idmarca} >{x.nombre}</option>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="row">
                            <Button variant="primary" type="submit" className="full-width mt-2">{dataForm.mode === 'create' ? 'Crear' : 'Actualizar'}</Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
}

export default FormVehicle;
