import React, {useEffect, useState} from 'react';
import './Home.css';
import logo from "../../assets/img/logo-inicio-blanco.png";
import imgStep1 from "../../assets/img/slider/step-1.png"
import imgStep2 from "../../assets/img/slider/step-2.png"
import imgStep3 from "../../assets/img/slider/step-3.png"
import '../../global.css';
import Carousel from 'react-bootstrap/Carousel'
import LazyLoad from 'react-lazy-load';
import ImageLoader from "../../components/ImageLoader/ImageLoader";
import Footer from "../../components/Footer/Footer";
import {useHistory} from "react-router-dom";

function Home() {
    let history = useHistory();
    const go = route => {
        history.push(route);
    }
    const cities = {
        bogota: {
            img: require("../../assets/img/cities/bogota.png"),
            name: 'BOGOTÁ'
        },
        medellin: {
            img: require("../../assets/img/cities/medellin.png"),
            name: 'MEDELLÍN'
        },
        cali: {
            img: require("../../assets/img/cities/cali.png"),
            name: 'CALI'
        },
        quito: {
            img: require("../../assets/img/cities/quito.jpg"),
            name: 'QUITO'
        },
        funzaMM: {
            img: require("../../assets/img/cities/mosquera.png"),
            name: 'MOSQUERA <br/> FUNZA <br/> MADRID',
        },
        manizales:{
            img: require("../../assets/img/cities/manizales.jpg"),
            name: 'MANIALES'
        },
        repDominicana: {
            img: require("../../assets/img/cities/republica.jpg"),
            name: 'REPUBLICA DOMINICANA <br/> SANTO DOMINGO'
        },
        armenia: {
            img: require("../../assets/img/cities/armenia.jpg"),
            name: 'ARMENIA'
        },
    };
    const clients = [
        {
            className: 'col-4',
            logo: require("../../assets/img/clients/ara.png"),
        },
        {
            className: 'col-4',
            logo: require("../../assets/img/clients/Ramo.png"),
        },
        {
            className: 'col-4',
            logo: require("../../assets/img/clients/novaventa.png"),
        },
        {
            className: 'col-4',
            logo: require("../../assets/img/clients/Renault.png"),
        },
        {
            className: 'col-4',
            logo: require("../../assets/img/clients/Toyota.png"),
        },
        {
            className: 'col-4',
            logo: require("../../assets/img/clients/didi.png"),
        },
        {
            className: 'col-6',
            logo: require("../../assets/img/clients/Cabify.png"),
        },
        {
            className: 'col-6',
            logo: require("../../assets/img/clients/RAPPI.png"),
        },
    ];
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(window.screen.width <= 768);
    },[isMobile]);
    return (
        <div>
            <div className="space-20"></div>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="custom-card custom-card-blue">
                                <div className="row">
                                    <div className="col-6 col-md-8 text-left">
                                        <p>VIVE FELIZ CON TU CARRO LIMPIO</p>
                                        <p>NO ESPERAS</p>
                                        <p>NO TRANCONES</p>
                                        <p>+ TIEMPO PARA TI</p>
                                    </div>
                                    <div className="col-6 col-md-4 text-right">
                                        <LazyLoad
                                            offsetVertical={300}
                                            debounce={false}
                                        >
                                            <ImageLoader src={logo} className="full-height-image logo-home" alt="logo" />
                                        </LazyLoad>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <Carousel>
                                <Carousel.Item>
                                    <div className="slides-text custom-box-shadow">
                                        <p>8 Ciudades</p>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="slides-text custom-box-shadow">
                                        <p>+ de 25.000 servicios prestados</p>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="slides-text custom-box-shadow">
                                        <p>90% Clientes felices</p>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                    <div className="row mt-2 mb-3">
                        <div className="col-12 text-center">
                            <button className="btn btn-success btn-lg" onClick={() => go('/servicios')}
                            >
                                PIDE TU SERVICIO AQUÍ!
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-2-home">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-12 mt-3 text-center">
                            <h2>¿CÓMO FUNCIONA?</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Carousel>
                                <Carousel.Item>
                                    <div className="slide-how-it-works ">
                                        <div className="row">
                                            <div className="col-6 text-right">
                                                <div className="content-slide-how-it-works">
                                                    <p>
                                                        1) Selecciona tu vehículo <br/>
                                                        y tipo de lavado
                                                    </p>
                                                    <button className="btn btn-primary" onClick={() => go('/servicios')}>
                                                        PIDE TU SERVICIO
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-6 text-left">
                                                <img src={imgStep1} className="phone-slide-how-it-works" alt="descripcion-lavelopues"/>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="slide-how-it-works ">
                                        <div className="row">
                                            <div className="col-6 text-right">
                                                <div className="content-slide-how-it-works">
                                                    <p>
                                                        2) Ingresa tus datos<br/>
                                                        de servicio
                                                    </p>
                                                    <button className="btn btn-primary" onClick={() => go('/servicios')}>
                                                        PIDE TU SERVICIO
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-6 text-left">
                                                <img src={imgStep2} className="phone-slide-how-it-works" alt="descripcion-lavelopues"/>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="slide-how-it-works ">
                                        <div className="row">
                                            <div className="col-6 text-right">
                                                <div className="content-slide-how-it-works">
                                                    <p>
                                                        3) Confirma tu reserva<br/>
                                                        y listo!
                                                    </p>
                                                    <button className="btn btn-primary" onClick={() => go('/servicios')}>
                                                        PIDE TU SERVICIO
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-6 text-left">
                                                <img src={imgStep3} className="phone-slide-how-it-works" alt="descripcion-lavelopues"/>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mt-3">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-12 mt-3 text-center">
                            <h2>¿DÓNDE ESTAMOS?</h2>
                        </div>
                    </div>
                    {
                        isMobile ?
                            <Carousel>
                                <Carousel.Item>
                                    <div className="row text-center">
                                        <div className="col-6">
                                            <div className="custom-card no-padding">

                                                    <img src={cities.bogota.img} alt={cities.bogota.name} className="full-width-image img-city"/>

                                                <p dangerouslySetInnerHTML={{__html: cities.bogota.name}} className="text-city"></p>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="custom-card no-padding">

                                                    <img src={cities.medellin.img} alt={cities.medellin.name} className="full-width-image img-city"/>

                                                <p dangerouslySetInnerHTML={{__html: cities.medellin.name}} className="text-city"></p>
                                            </div>
                                            <div className="space-20"></div>
                                            <div className="space-20"></div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="row text-center">
                                        <div className="col-6">
                                            <div className="custom-card no-padding">

                                                    <img src={cities.cali.img} alt={cities.cali.name} className="full-width-image img-city"/>

                                                <p dangerouslySetInnerHTML={{__html: cities.cali.name}} className="text-city"></p>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="custom-card no-padding">

                                                    <img src={cities.quito.img} alt={cities.quito.name} className="full-width-image img-city"/>

                                                <p dangerouslySetInnerHTML={{__html: cities.quito.name}} className="text-city"></p>
                                            </div>
                                            <div className="space-20"></div>
                                            <div className="space-20"></div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="row text-center">
                                        <div className="col-6">
                                            <div className="custom-card no-padding">

                                                    <img src={cities.funzaMM.img} alt={cities.funzaMM.name} className="full-width-image img-city"/>

                                                <p dangerouslySetInnerHTML={{__html: cities.funzaMM.name}} className="text-city"></p>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                            :
                            <div className="row text-center">
                                <div className="col-3">
                                    <div className="custom-card no-padding">

                                            <img src={cities.bogota.img} alt={cities.bogota.name} className="full-width-image img-city"/>

                                        <p dangerouslySetInnerHTML={{__html: cities.bogota.name}} className="text-city"></p>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="custom-card no-padding">
                                            <img src={cities.medellin.img} alt={cities.medellin.name} className="full-width-image img-city"/>

                                        <p dangerouslySetInnerHTML={{__html: cities.medellin.name}} className="text-city"></p>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="custom-card no-padding">

                                            <img src={cities.cali.img} alt={cities.cali.name} className="full-width-image img-city"/>

                                        <p dangerouslySetInnerHTML={{__html: cities.cali.name}} className="text-city"></p>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="custom-card no-padding">

                                            <img src={cities.quito.img} alt={cities.quito.name} className="full-width-image img-city"/>

                                        <p dangerouslySetInnerHTML={{__html: cities.quito.name}} className="text-city"></p>
                                    </div>
                                </div>
                                <br/>
                                <div className="col-3">
                                    <div className="custom-card no-padding mt-2">

                                            <img src={cities.funzaMM.img} alt={cities.funzaMM.name} className="full-width-image img-city"/>

                                        <p dangerouslySetInnerHTML={{__html: cities.funzaMM.name}} className="text-city"></p>
                                    </div>
                                </div> 
                                <div className="col-3">
                                    <div className="custom-card no-padding mt-2">

                                            <img src={cities.repDominicana.img} alt={cities.repDominicana.name} className="full-width-image img-city"/>
                                        <p dangerouslySetInnerHTML={{__html: cities.repDominicana.name}} className="text-city"></p>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="custom-card no-padding mt-2">
                                
                                            <img src={cities.manizales.img} alt={cities.manizales.name} className="full-width-image img-city"/>

                                        <p dangerouslySetInnerHTML={{__html: cities.manizales.name}} className="text-city"></p>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="custom-card no-padding mt-2">
                                
                                            <img src={cities.armenia.img} alt={cities.armenia.name} className="full-width-image img-city"/>

                                        <p dangerouslySetInnerHTML={{__html: cities.armenia.name}} className="text-city"></p>
                                    </div>
                                </div>
                            </div>
                    }

                </div>
            </section>
            <section className="section-2-home">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mt-3 text-center">
                            <h2>CLIENTES</h2>
                        </div>
                    </div>
                    <div className="row">
                        {
                            clients.map((x,index) => (
                                <div className={x.className} key={index}>
                                    <LazyLoad
                                        offsetVertical={300}
                                        debounce={false}
                                    >
                                        <ImageLoader src={x.logo} alt="logo-cliente" className="full-width-image"/>
                                    </LazyLoad>
                                </div>
                            ))
                        }
                    </div>
                    <div className="space-10"></div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-12 mt-3 text-center">
                            <h2>NOS APOYAN</h2>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-6">
                            <LazyLoad
                                offsetVertical={300}
                                debounce={false}
                            >
                                <ImageLoader src={require("../../assets/img/support/hubbog.png")} alt="logo-nos-apoyan" className="full-width-image"/>
                            </LazyLoad>
                        </div>
                        <div className="col-6">
                            <LazyLoad
                                offsetVertical={300}
                                debounce={false}
                            >
                                <ImageLoader src={require("../../assets/img/support/parquesoft.png")} alt="logo-nos-apoyan" className="full-width-image"/>
                            </LazyLoad>
                        </div>
                        <div className="col-8 offset-2">
                            <LazyLoad
                                offsetVertical={300}
                                debounce={false}
                            >
                                <ImageLoader src={require("../../assets/img/support/mintic.png")} alt="logo-nos-apoyan" className="full-width-image"/>
                            </LazyLoad>
                        </div>

                    </div>
                </div>
            </section>
            <section className="section-2-home">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mt-3 text-center">
                            <h2>Formas de Pago</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 col-sm-2 offset-sm-1">
                            <LazyLoad
                                offsetVertical={300}
                                debounce={false}
                            >
                                <ImageLoader src={require("../../assets/img/payment/visa.png")} alt="logo-TC" className="full-width-image"/>
                            </LazyLoad>
                        </div>
                        <div className="col-4 col-sm-2">
                            <LazyLoad
                                offsetVertical={300}
                                debounce={false}
                            >
                                <ImageLoader src={require("../../assets/img/payment/mastercard.png")} alt="logo-TC" className="full-width-image"/>
                            </LazyLoad>
                        </div>
                        <div className="col-4 col-sm-2">
                            <LazyLoad
                                offsetVertical={300}
                                debounce={false}
                            >
                                <ImageLoader src={require("../../assets/img/payment/dinners.png")} alt="logo-TC" className="full-width-image"/>
                            </LazyLoad>
                        </div>
                        <div className="col-4 col-sm-2">
                            <LazyLoad
                                offsetVertical={300}
                                debounce={false}
                            >
                                <ImageLoader src={require("../../assets/img/payment/american.png")} alt="logo-TC" className="full-width-image"/>
                            </LazyLoad>
                        </div>
                        <div className="col-4 col-sm-2">
                            <LazyLoad
                                offsetVertical={300}
                                debounce={false}
                            >
                                <ImageLoader src={require("../../assets/img/payment/occidente.png")} alt="logo-TC" className="full-width-image"/>
                            </LazyLoad>
                        </div>
                    </div>
                </div>
                <div className="space-20"></div>
            </section>
            <Footer/>

        </div>
    );
}

export default Home;
