import React, { useEffect,useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import clientAxios from '../../config/axios'; 
import imgLoading from '../../assets/img/loading.gif';
import './ModalDate.css';

const ModalDate = ({showModalDate,handleCloseDate,reservation,selectHour}) => {

    const [days,setDays] = useState([]);
    const [hours,setHours] = useState([]);
    const [loading, setLoading] = useState(false);
    const loadDates = () => {
        const weekDaysList = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']
        const monthList = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec']
    
        let daysTemp = []
    
        for (let i = 0; i < 8; i++) {
          const dateT = new Date()
          dateT.setDate(dateT.getDate() + i)
    
          daysTemp.push({
              dayWeek: weekDaysList[dateT.getDay()],
              day: dateT.getDate(),
              month: monthList[dateT.getMonth()],
              dateT,
              dateTemp: i
          })
        }
        setDays(daysTemp);
        selectDate(reservation.date, reservation.dateTemp);
    
    }

    const selectDate = ( day, dateTemps ) => {
        if (!day) {
            day = new Date();
            dateTemps = 0;
        }
        if (day) {
            setLoading(true);
            reservation.dateTemp = dateTemps;
            const send = {
                city: reservation.city,
                date: day,
                duration: reservation.product.duration
            }
            clientAxios.post('app/traerDisponibilidad',send).then(({data}) => {
                if (data.response === 1) {
                    let actual = new Date();
                    let hr = actual.getHours();
                    const plusH = new Date(actual).setHours(hr + 1);
                    actual = new Date(plusH);

                    const dispo = data.data.filter(ob => {
                        const fecha = new Date(ob.fecha);
                        if (actual.getDate() == fecha.getDate() ) {
                            console.log(fecha>=actual, fecha,actual);
                            return (fecha>=actual);
                        }else{
                            return true;
                        }
                    });
                    console.log({dispo});
                    setHours(dispo);
                } else {
                    setHours([]);
                }
                setLoading(false);
            }).catch( err => console.log('Se ha generado un error al intentar cargar las horas',err));
        }
    }
    const formatAMPM =  data => {
       const time  = reservation.country == 3?'-04:00':'-05:00';
        const start = new Date(data.fecha.replace(/\s/, 'T') + time);
        const end = new Date(start.getTime() + 60 * 1000 * data.duracion);
        let hours = start.getHours();
        let minutes = start.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
    
        // Hora de finalizacion
        let hours2 = end.getHours();
        let minutes2 = end.getMinutes();
        let ampm2 = hours2 >= 12 ? 'PM' : 'AM';
        hours2 = hours2 % 12;
        hours2 = hours2 ? hours2 : 12; // the hour '0' should be '12'
        minutes2 = minutes2 < 10 ? '0' + minutes2 : minutes2;
    
        return hours + ':' + minutes + ' ' + ampm + ' - ' + hours2 + ':' + minutes2 + ' ' + ampm2;
      }

    useEffect(() => {
        loadDates();
    },[]);
    return (
        <Modal show={showModalDate} onHide={handleCloseDate}>
            <Modal.Header closeButton>
                <Modal.Title>Fecha</Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <div className="row m-1">
                        <div className="container-date" id="scroll-style">
                            {
                                days.map((day,index) => (
                                   
                                    <div key={index} 
                                        className={reservation.dateTemp === day.dateTemp ? 'date-selected' : 'date-unselected'}
                                        onClick={() => selectDate(day.dateT,day.dateTemp)}
                                    >
                                        {day.dayWeek}
                                        <br/>
                                        {day.day}
                                        <br/>
                                        {day.month}
                                    </div>
                                ))
                            }
                        </div>
                        <div className="hours" >
                            
                            {
                                hours.length === 0 && !loading ?
                                <p className="text-center full-width">
                                    No hay horarios disponibles para el día seleccionado
                                </p> : null
                            }
                            { loading ? 
                                <div className="div-loading">
                                    <img src={imgLoading} title="Loading" alt="Loading"/>
                                    <h4>CARGANDO...</h4>
                                </div> 
                                :
                                <div className="form-group">
                                    {
                                        hours.map((x,index) => (
                                            <div className="custom-control custom-radio hour-div" 
                                                key={index}
                                                onClick={() => selectHour(x.fecha, reservation.dateTemp)}
                                            >
                                                <input type="radio" id={"customRadio" + index } 
                                                        name="customRadio" 
                                                        className="custom-control-input" 
                                                        onChange={() => selectHour(x.fecha, reservation.dateTemp)}
                                                        checked={x.fecha === reservation.date}
                                                />
                                                <label className="custom-control-label" htmlFor={"customRadio" + index }>
                                                    {formatAMPM(x)}
                                                </label>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                                    
                        </div>
                    </div>
                </Modal.Body>
        </Modal>
    );
}

export default ModalDate;
