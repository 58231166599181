import React, {Fragment, useEffect, useState} from "react";
import "./SelectCity.css";

import { useSelector,useDispatch } from 'react-redux';
import { setUserActions } from '../../actions/userActions';
import { setReservationActions } from '../../actions/reservationActions';
import { setAddressAction } from '../../actions/addressActions';
import {getUrlUbication} from "../../Helpers/UbicationHelper";
import clientAxios from '../../config/axios';
import Modal from "react-bootstrap/Modal";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {makeStyles} from "@material-ui/core/styles";
import {alert} from '../../Helpers/Alert';

import Location from "../Location/Location";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        width:'100%'
    },
}));

const SelectCity = ({showModal,openModal,handleClose}) => {
    const [cities, setCities]  = useState([]);
    const [city, setCity] = useState('1');
    const [errorCity, setErrorCity] = useState('1');
    const [country, setCountry] = useState(1);
    const [countries, setCountries] = useState([]);
    let cityTemp = '';
    let countryTemp = '';
    const reservation = useSelector( state => state.reservation.reservation );
    const dispatch = useDispatch();
    const setUser = user => dispatch( setUserActions(user));
    const setReservation = res => dispatch( setReservationActions(res));
    const setAddressStore = res => dispatch( setAddressAction(res));
    const classes = useStyles();

    // const handleClose = value => {
    //     setShowModal(false);
    // }
    const getCity = ( data ) => {
        let city= ''
        if ( data.search('Quito') != -1 ) {
            city = 5
        } else if ( data.search('Armenia') != -1 ) {
            city = 2
        } else if ( data.search('Bogotá') != -1 ) {
            city = 1
        } else if ( data.search('Cali') != -1 ) {
            city = 4
        } else if ( data.search('Medellín') != -1 ) {
            city = 3
        } else if ( data.search('Mosquera') != -1 ) {
            city = 6
        } else if ( data.search('Madrid') != -1 ) {
            city = 7
        } else if ( data.search('Funza') != -1 ) {
            city = 8
        } else if ( data.search('Chía') != -1 ) {
            city = 11
        } else if ( data.search('Cajicá') != -1 ) {
            city = 12
        } else if ( data.search('Cota') != -1 ) {
            city = 13
        } else if ( data.search('Pereira') != -1 ) {
            city = 9
        } else if ( data.search('Santo Domingo') != -1 ) {
            city = 14
        } else if ( data.search('Manizales') != -1 ) {
            city = 15
        } else if ( data.search('Barranquilla') != -1 ) {
            city = 16
        }
        return city
    }

    const getCityName = (cityInput) => {
        let cityN= ''
        if ( cityInput === 5 ) {
            cityN = 'Quito'
        } else if ( cityInput == 2 ) {
            cityN = 'Armenia'
        } else if ( cityInput == 1 ) {
            cityN = 'Bogotá'
        } else if ( cityInput == 4 ) {
            cityN = 'Cali'
        } else if ( cityInput == 3 ) {
            cityN = 'Medellín'
        } else if ( cityInput == 6 ) {
            cityN = 'Funza'
        } else if ( cityInput == 7 ) {
            cityN = 'Madrid'
        } else if ( cityInput == 8 ) {
            cityN = 'Mosquera'
        } else if ( cityInput == 9 ) {
            cityN = 'Pereira'
        } else if ( cityInput == 5 ) {
            cityN = 'Quito'
        } else if ( cityInput == 11 ) {
            cityN = 'Chía'
        } else if ( cityInput == 12 ) {
            cityN = 'Cajicá'
        } else if ( cityInput == 13 ) {
            cityN = 'Cota'
        } else if ( cityInput == 14 ) {
            cityN = 'Santo Do.'
        } else if ( cityInput == 15 ) {
            cityN = 'Manizales'
        } else if ( cityInput == 16 ) {
            cityN = 'Barranquilla'
        }

        return cityN
    }

    const getCountry = ( data ) => {
        let country= 0
        if ( data.search('Colombia') != -1 ) {
            country = 1
        } else if ( data.search('Ecuador') != -1 ) {
            country = 2
        } else if ( data.search('República Dominicana') != -1 ) {
            country = 3
        }
        return country
    }

    const selectCountry = (country) => {
        setCountry(country);
        setCities([]);
        setCity('');
        clientAxios.post('app/TodosCiudad',country).then(({data})=> {
            if(data.length > 0) {
                setCities(data);
                console.log(data, cityTemp);
                if (cityTemp !== '') {
                    setCity(cityTemp);
                    cityTemp = '';
                }
            }
        }).catch(err => console.log('error al cargar lista de ciudades', err))
    }
    const getCurrentPosition = () => {
        if (navigator.geolocation) {
            if (reservation.firstCitySelect) {
                navigator.geolocation.getCurrentPosition((res) => {
                    const data = {
                        lat: res.coords.latitude,
                        long: res.coords.longitude,
                    }
                    console.log(data.lat+"datos localizacion"+data.long)
                    clientAxios.get(getUrlUbication(data)).then(({data})=>{
                        const ubication = data.results[0].formatted_address;
                        countryTemp = getCountry(ubication);
                        selectCountry(countryTemp);
                        console.log(ubication)
                        cityTemp = getCity(ubication);
                        console.log(cityTemp,countryTemp);
                        console.log({localicion:true});
                     
                        if (cityTemp != '') {
                            saveCity(true);
                        }
                    }).catch(err => console.log('error al obtener la ubicación'));
                },(err)=>{
                    console.log({localicion:false});
                   // setShowModal(true);
                    // openModal();
                    setErrorCity(true);

                })

       //         if(errorCity==true){
         //           openModal()
       //         }
            }
        } else {
            console.log('Geolocation is not supported by this browser.')
        }
    }
    const selectCity = e => {
        setErrorCity(false);
        setCity(e.target.value);
    }
    const selectOneCountry = e => {
        selectCountry(e.target.value);
        reservation.country = e.target.value;
        console.log("si");
    }
    const saveCity = (time) => {
        setErrorCity(false);
        reservation.errorCity = false;
        reservation.country = countryTemp != ''?countryTemp:country;
        if ((city && city !== '') || cityTemp != '') {
            let finalCity= cityTemp !== '' ? cityTemp : city
            if (time) {
                setTimeout(() => {
                    if (finalCity !== reservation.city) {
                        setAddressStore({
                            nameAddress: '',
                            address: '',
                            idAddress: 0,
                            addressTemp: null,
                        })
                    }
                    setReservation({
                        ...reservation,
                        city: finalCity,
                        cityName: getCityName(finalCity),
                        firstCitySelect: false,
                    })
                },2100)
            } else {
                if (finalCity !== reservation.city) {
                    setAddressStore({
                        nameAddress: '',
                        address: '',
                        idAddress: 0,
                        addressTemp: null,
                    })
                }
                setReservation({
                    ...reservation,
                    city: finalCity,
                    cityName: getCityName(finalCity),
                    firstCitySelect: false,
                })
            }
            cityTemp = ''
            handleClose()
        } else {
            alert.fire(
                'Advertencia',
                'Debes seleccionar una ciudad',
                'warning'
            );
        }

    }

    // const openModal = () => {
    //     selectCountry(reservation.country);
    //     setShowModal(true);
    //     console.log(showModal);
    // }
    const getCountrys = () => {
        clientAxios.get('app/todosPaises').then( ({data}) => {
            setCountries(data);
        }).catch( err => console.log('Se ha generado un error al intentar cargar los países'));
    };

    useEffect(() => {
        getCurrentPosition();
        getCountrys();
        selectCountry(reservation.country);
    }, []);
    return (
        <Fragment>
            <Location onClick = {openModal}/>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Selecciona tu Ubicación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {/* <div className="col-12">
                            <h4>País:</h4>
                        </div> */}
                    </div>
                    <div className="row">
                    <div className="col-12 text-center">
                    <div className="padding-city-modal-city">
                    <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                    <InputLabel htmlFor="outlined-age-native-simple">Pais</InputLabel>
                    <Select
                        native
                        value={country}
                        onChange={selectOneCountry}
                        label="plan"
                        inputProps={{
                            name: 'country',
                            id: 'outlined-age-native-simple',
                        }}
                    >
                        <option aria-label="None" value="" />
                        {
                            countries.map(country => (
                                <option key={country.idpais} value={country.idpais}>{country.nombre}  </option>
                            ))
                        }
                    </Select>
                    </FormControl>
                    </div>
                    </div>

                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="padding-city-modal-city">
                                <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Ciudad</InputLabel>
                                    <Select
                                        native
                                        value={city}
                                        onChange={selectCity}
                                        label="Ciudad"
                                        inputProps={{
                                            name: 'city',
                                            id: 'outlined-age-native-simple',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {
                                            cities.map(x => (
                                                <option key={x.idciudad} value={x.idciudad} >{x.nombre}</option>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="full-width btn btn-primary" onClick={() => saveCity()}>CONTINUAR</button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}
export default SelectCity;
