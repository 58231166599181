import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home/Home';
import Services from './pages/Services/Services';
import Profile from './pages/Profile/Profile';
import Addresses from './pages/Addresses/Addresses';
import Vehicles from './pages/Vehicles/Vehicles';
import Empresas from './pages/Enterprises/Enterprises';
import Washers from './pages/Washers/Washers';
import ActiveReservations from './pages/ActiveReservations/ActiveReservations';
import WashesAvailable from './pages/WashesAvailable/WashesAvailable';
import Products from './pages/Products/Products';
import AdditionalProducts from './pages/AdditionalProducts/AdditionalProducts';
import Faq from "./pages/Faq/Faq";

import Header from './components/Header/Header';

//Redux
import store from './store';
import { Provider }  from 'react-redux';

// Estilos
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
        main: '#19a3df'
      }
    }
  },
)

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Header />
          <div className="custom-padding">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/servicios" component={Services} />
              <Route exact path="/listadoServicios" component={Products} />
              <Route exact path="/perfil" component={Profile} />
              <Route exact path="/direcciones" component={Addresses} />
              <Route exact path="/vehiculos" component={Vehicles} />
              <Route exact path="/empresas" component={Empresas} />
              <Route exact path="/seunwasher" component={Washers} />
              <Route exact path="/reservasActivas" component={ActiveReservations} />
              <Route exact path="/lavadosDisponibles" component={WashesAvailable} />
              <Route exact path="/productosAdicionales" component={AdditionalProducts} />
              <Route exact path="/faq" component={Faq} />
            </Switch>
          </div>
        </Router>
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;
