import React, {useEffect, useState} from 'react';
import logo from "../../assets/img/logo-inicio-blanco.png";
import '../../global.css';
import './Washers.css';
import Carousel from 'react-bootstrap/Carousel'
import LazyLoad from 'react-lazy-load';
import ImageLoader from "../../components/ImageLoader/ImageLoader";
import Footer from "../../components/Footer/Footer";
import {useHistory} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import {useSelector} from "react-redux";
import SelectCity from "../../components/SelectCity/SelectCity";
import {makeStyles} from "@material-ui/core/styles";
import Testimony from "../../components/Testimony/Testimony";
import clientAxios from "../../config/axios";
import {alert} from "../../Helpers/Alert";
import Modal from "react-bootstrap/Modal";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        width:'100%'
    },
}));

function Washers() {
    let history = useHistory();
    const classes = useStyles();
    const [show,setShow] = useState(false);
    const [cities, setCities] = useState([]);
    const handleClose = () => {
        setShow(false)
    }
    const initialState = {
        fullName: '',
        phone: '',
        email: '',
        city: '1',
    }
    const testimonials = [
        {
            img: require('../../assets/img/testimonials/w1.png'),
            text: 'Gracias a Lavelo Pues mis ingresos se han incrementado el doble!',
            user: 'Danly Mendez'
        },
        {
            img: require('../../assets/img/testimonials/w2.png'),
            text: 'Estoy feliz de pertenecer a este grupo de personas que quiere hacer las cosas bien!',
            user: 'Leonel Gutierrez'
        },
        {
            img: require('../../assets/img/testimonials/w3.png'),
            text: 'Buscamos impactar positivamente la calidad de vida de las personas por medio de nuestro servicio.',
            user: 'Eliana Tovar'
        },
    ]
    const [form, setForm] = useState(initialState);
    const [isMobile, setIsMobile] = useState(false);
    const [measurements, setMeasurements] = useState({w: 560, h: 315});
    const reservation = useSelector( state => state.reservation.reservation);
    const photos = [
        [
            {
                className: 'col-6',
                logo: require("../../assets/img/services/1.jpg"),
            },
            {
                className: 'col-6',
                logo: require("../../assets/img/services/2.jpg"),
            },
        ],
        [
            {
                className: 'col-6',
                logo: require("../../assets/img/services/3.jpg"),
            },
            {
                className: 'col-6',
                logo: require("../../assets/img/services/4.jpg"),
            },
        ],
        [
            {
                className: 'col-6',
                logo: require("../../assets/img/services/5.jpg"),
            },
            {
                className: 'col-6',
                logo: require("../../assets/img/services/6.jpg"),
            },
        ],
        [
            {
                className: 'col-6',
                logo: require("../../assets/img/services/7.jpg"),
            },
            {
                className: 'col-6',
                logo: require("../../assets/img/services/8.jpg"),
            },
        ],
    ];
    const go = route => {
        history.push(route);
    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    const handleChangeForm = e => {
        setForm({
            ...form,
            [e.target.name] : e.target.value
        });
    }
    const handleSumbmit = e => {
        e.preventDefault();

        if (form.email === '' || form.phone === '' || form.fullName === '') {
            return;
        }
        const send = {
            ...form,
            city: reservation.city,
        }
        clientAxios.post('public/emailWasher',send).then(({data}) => {
            if (data.response) {
                alert.fire('Operación Exitosa','La información ha sido enviada con éxito!','success');
                setShow(false);
            } else {
                alert.fire(
                    'Se ha generado un error',
                    'Se ha generado un error al intentar enviar la información',
                    'error'
                );
            }
        }).catch(err =>  console.log('Se ha generado un error al intentar enviar la información'))
    }
    useEffect(() => {
        setIsMobile(window.screen.width <= 768);
        if (window.screen.width < measurements.w ) {
            setMeasurements({
                w: window.screen.width - 30,
                y: (window.screen.width - 30) * 0.578
            });
        }
        clientAxios.get('public/todosCiudades').then(({data})=> {
            setCities(data);
        })
    },[isMobile]);
    return (
        <div>
            <div className="space-20"></div>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="custom-card custom-card-blue">
                                <div className="row">
                                    <div className="col-6 col-md-8 text-left">
                                        <p>VIVE FELIZ CON TU CARRO LIMPIO</p>
                                        <p>NO ESPERAS</p>
                                        <p>NO TRANCONES</p>
                                        <p>+ TIEMPO PARA TI</p>
                                    </div>
                                    <div className="col-6 col-md-4 text-right">
                                        <LazyLoad
                                            offsetVertical={300}
                                            debounce={false}
                                        >
                                            <ImageLoader src={logo} className="full-height-image logo-home" alt="logo" />
                                        </LazyLoad>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-3 text-center">
                    <h2>Se parte de nuestro equipo!</h2>
                    <Carousel>
                        {
                            photos.map((x,index1) => (
                                <Carousel.Item key={index1}>
                                    <div className="row">
                                        {
                                            photos[index1].map((y,index2) => (
                                                <div className={y.className} key={index2}>
                                                    <img src={y.logo} className="full-width-image" alt="servicio-lavelopues"/>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="space-20"></div>
                                    <div className="space-20"></div>
                                </Carousel.Item>
                            ))
                        }
                    </Carousel>
                </div>
                <div className="container">
                    <div className="row">
                        {
                            testimonials.map((x,index) => (

                                <div className={isMobile ? "col-12" : "col-4"} key={index}>
                                    <Testimony
                                        mode='H'
                                        img={x.img}
                                        heightImg={80}
                                        text={x.text}
                                        user={x.user}
                                        isMobile={isMobile}
                                    />
                                </div>

                            ))
                        }
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="container text-center">
                                <iframe width={measurements.w} height={measurements.h} src="https://www.youtube.com/embed/RztACPxuXYI"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                            </div>
                            <div className="space-20"></div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="row mt-3 mb-3">
                                <div className="space-20"></div>
                                <div className="col-12 text-center">
                                    <button className="btn btn-success btn-lg" onClick={() => setShow(true)}>
                                        QUIERO APLICAR
                                    </button>
                                </div>
                                <div className="space-20"></div>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-6 text-center">
                                    <i className="fas fa-plus-circle icon-info-washer"></i>
                                    <p>Mejores ingresos</p>
                                </div>

                                <div className="col-6 text-center">
                                    <i className="fas fa-plus-circle icon-info-washer"></i>
                                    <p>Maneja tu horario</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                show ?
                    <Modal show={show} onHide={() => handleClose(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Sé un washer!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-12">
                                    <form onSubmit={handleSumbmit} className={classes.root} noValidate autoComplete="off">
                                        <div className="row">
                                            <div className="col-12">
                                                <TextField value={form.fullName} name="fullName" onChange={handleChangeForm} label="Nombre y Apellido" variant="outlined" fullWidth={true} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <TextField value={form.phone} name="phone" onChange={handleChangeForm} label="Teléfono" variant="outlined" fullWidth={true} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <TextField value={form.email} name="email" onChange={handleChangeForm} label="Correo" variant="outlined" fullWidth={true} />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                                    <InputLabel htmlFor="city">Ciudad</InputLabel>
                                                    <Select
                                                        native
                                                        value={form.city}
                                                        onChange={handleChangeForm}
                                                        label="Ciudad"
                                                        inputProps={{
                                                            name: 'city',
                                                            id: 'city',
                                                        }}
                                                    >
                                                        {
                                                            cities.map(x => (
                                                                <option key={x.idciudad} value={x.idciudad} >{x.nombre}</option>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <button className="btn btn-primary" type="submit">Enviar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="space-20"></div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    : null
            }
            <Footer/>

        </div>
    );
}

export default Washers;
