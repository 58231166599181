import React, {Fragment, useState, Component} from "react";
import Modal from "react-bootstrap/Modal";
import Select from "@material-ui/core/Select";
import "./ModalPlanUser.css";

const ModalPlanUser =  ({plans,handleClose}) => {
    const [plan,setPlan] =  useState('1');
    const [showPlans] = useState('1');
    const selectPlan = plan=>{
       //const idplan =  plan.target.key
       console.log({planSelect:plan.target});
       setPlan(plan.target.key);
    }  
    console.log({plans});
    return(
        <Modal show={showPlans} onHide={() => handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Tus planes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <div className="col-12 text-center">
                        ¿que plane desea cancelar?
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 text-center">
                    <Select
                        native
                        value={plan}
                        onChange={selectPlan}
                        label="plan"
                        inputProps={{
                            name: 'country',
                            id: 'outlined-age-native-simple',
                        }}
                    >
                        <option aria-label="None" value="" />
                        {
                            plans.map(plan => (
                                <option key={plan.idplan} value={plan.idplan} >{plan.nombre_plan}</option>
                            ))
                        }
                    </Select>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-12 text-center">
                        <button className="btn btn-primary" onClick={() => handleClose()}>Aceptar</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalPlanUser;
