import React from "react";
import "./Footer.css";
import LazyLoad from 'react-lazy-load';
import ImageLoader from "../../components/ImageLoader/ImageLoader";

const Footer = () => {
    const date = new Date();
    const year = date.getFullYear();
    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <h4><b>Contacto en Colombia</b></h4>
                        Domicilio: Armenia, Quindío <br/>
                        Dirección: Carrera 14a número 10a norte -36  <br/>
                        Correo electrónico: <a href="mailto:comercial@lavelopues.com">comercial@lavelopues.com</a>  <br/>
                        Teléfono: <a href="tel:3113103519">3113103519</a>   <br/>
                    </div>
                    <div className="col-12 col-sm-6">
                        <h4><b>Contacto en Republica Dominicana</b></h4>
                        Domicilio: Santo Domingo <br/>
                        Dirección: Av. Gustavo Mejía Ricart #81.  Torre Profesional Biltmore II, Suite 706 <br/>
                        Correo electrónico: <a href="mailto:info@lavelopuesrd.com">info@lavelopuesrd.com</a>  <br/>
                        Teléfono: <a href="tel:3113103519">8298697755</a>   <br/>
                    </div>
                   
                    <div className="col-12">
                        <center>
                        <br />
                        <br />
                        <a href="https://lavelopues.com/static/media/Proteccion-de-datos-Lavelo-Pues.428da0ca.pdf">Política de devoluciones, política de privacidad y terminamos y condiciones</a>
                        </center>
                    </div>
                </div>
            </div>
            <div className="container text-center">
                <LazyLoad
                    offsetVertical={300}
                    debounce={false}
                >
                    <ImageLoader src={require("../../assets/img/logo-white.png")} alt="logon" className="full-width-image"/>
                </LazyLoad>
                <p>Lávelo pues | © {year} - Todos los derechos reservados</p>
                <p>by <a href="https://www.safesociety.co/">SafeSociety</a></p>
            </div>
        </div>
    );
}
export default Footer;
