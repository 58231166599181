export const setAddressAction = (address) => {
    return {
        type: 'SET_ADDRESS',
        payload: address
    };
}

export const deleteAddressAction = () => {
    return {
        type: 'DELETE_ADDRESS'
    };
}

